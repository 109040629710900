import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import { setActiveSchool } from 'actions/user';

import { Icon, ActionSheet, TooltipMenu, translate } from '@haldor/ui';
import * as microsoftTeams from "@microsoft/teams-js";

import './_device.scss';
import { languages, getLanguage, setLanguage } from '../Menu/LanguageManager';

class DeviceHeader extends Component {
	constructor(props) {
		super(props);

		this.state = {
			languageMenuOpen: false,
			confirmLogOutOpen: false,
		};
	}

	isIndex = () => {
		return window.location.pathname == '/';
	}

	goBack = (event) => {
		event.preventDefault();
		window.history.go(-1);
	}

	getBadgeForSchool = (school) => {
		const { activeSchool } = this.props;

		if (this.props.userUnread == null || school.id == activeSchool) {
			return null;
		}

		return this.props.userUnread.reduce((value, item) => {
			if ((item.schoolId == 0 || item.schoolId == school.id) && item.type == 'CONVERSATION') {
				if (value == null)
					value = 0;

				return value += item.unread;
			}

			return value;
		}, null);
	};

	getBadgeForItem = () => {
		if (this.props.userUnread == null) {
			return null;
		}

		const { activeSchool } = this.props;

		return this.props.userUnread.reduce((value, item) => {
			if (item.schoolId == activeSchool && item.type != 'CONVERSATION') {
				return value;
			}

			if (value == null)
				value = 0;

			return value += item.unread;
		}, null);
	};

	selectSchool = () => {
		const { user } = this.props;

		const schools = user.schools.map((school) => ({
			label: (
				<div>
					{this.getBadgeForSchool(school) != null ?
						<div
							className="badge"
							style={{
								display: 'inline-flex',
								justifyContent: 'center',
								transform: 'none',
								borderRadius: '100%',
								position: 'static',
								marginRight: '.4rem'
							}}>
							{this.getBadgeForSchool(school)}
						</div>
						: null}

					{school.title}
				</div>
			),
			value: school.id,
		}));

		ActionSheet.prompt({
			items: schools,
		}).then((school) => {
			localStorage.setItem("hal.school" + user.id, school);
			this.props.setActiveSchool(school);

			this.props.history.push("/switch");
		}).catch(() => null);
	};

	renderAction = (action) => {
		if (action.type == 'dropDown')
			return (
				<div>
					{action.subButtons ?
						action.subButtons.map((subAction, index) => (
							<TooltipMenu.Item
								onClick={() => subAction.onClick(true)}
								key={'subAction-' + index}
							>
								{subAction.value}
							</TooltipMenu.Item>
						))
						: null}
				</div>
			);

		return (
			<TooltipMenu.Item onClick={action.onClick}>
				{action.icon != null ?
					<Icon name={action.icon} />
					: null}

				{action.value}
			</TooltipMenu.Item>
		);
	}

	confirmLogOut = () => {
		let menuItems = [];
		const { translate } = this.props;
		this.setState({confirmLogOutOpen: true})

		const items = [
			{
				label: translate('Yes'),
				link: '/logout',
				background: "#73d266",
			},
			{
				label: translate('No'),
				link: '#',
				background: "#E6E6E6",
			},
		];

		items.forEach(item => {
			menuItems.push({
				label: item.label == translate("Yes") ?
				(<div>
					<h3 style={{ marginBottom: "25px" }}>{translate('verify_logout')}</h3>
					<a style={{ 
							textTransform: "uppercase", 
							textDecoration: "none", 
							color: "white", 
							display: "inline-flex", 
							justifyContent: "center", 
							backgroundColor: (item.background), 
							width: "100%", 
							padding: "10px", 
							borderRadius: "10px", 
							fontWeight: "bold" }} 
						href={item.link} >
						{item.label}
					</a>
				</div>)
				: (<div>
					<a style={{ 
							textTransform: "uppercase", 
							textDecoration: "none", 
							color: "black", 
							display: "inline-flex", 
							justifyContent: "center", 
							backgroundColor: (item.background), 
							width: "100%", 
							padding: "10px", 
							borderRadius: "10px" }} 
						href={item.link} 
						onClick={() => {this.openDeviceMenu, this.scrollToItem('logout-confirm')}}>
						{item.label}
					</a>
				</div>),
			});
		});

		ActionSheet.prompt({
			items: menuItems,
		}).then(selection => {
		}, () => {
			// This method only runs if user closes the prompt without pressing an option
			if (this.state.confirmLogOutOpen) {
				this.openDeviceMenu();
				this.scrollToItem('logout-confirm');;
			}
		});
	}
	
	selectLanguage = () => {
		let languageMenu = [];
		const user = this.props.user;
		this.setState({languageMenuOpen: true});

		languages().forEach((language) => {
			languageMenu.push({
				label: user.lcid.toLowerCase() == language.lcid.toLowerCase() ?
					(<div style={{ fontWeight: "bold", display: "flex", justifyContent: "space-between" }}>
						<span style={{ display: "flex", alignItems: "center" }}><img style={{ height: "16px", width: "22px", objectFit: "cover", marginRight: "10px", borderRadius: "2px" }} alt={language.label} src={language.svg_src} /> {language.label}</span>
						<Icon name="check" />
					</div>)
					: (<span onClick={() => setLanguage(language.lcid, user)} style={{ display: "flex", alignItems: "center" }}><img style={{ height: "16px", width: "22px", objectFit: "cover", marginRight: "10px", borderRadius: "2px" }} alt={language.label} src={language.svg_src} /> {language.label}</span>),
				});
			});
			
			ActionSheet.prompt({
			items: languageMenu,
		}).then(selection => {
			if (this.state.languageMenuOpen) {
				this.openDeviceMenu();
				this.scrollToItem('languages-dropdown');
			}
		}, () => {
			// This method only runs if user closes the prompt without pressing an option
			if (this.state.languageMenuOpen) {
				this.openDeviceMenu();
				this.scrollToItem('languages-dropdown');
			}
		});
	}

	inIframe() {
		try {
			return window.self !== window.top || window.navigator.userAgent.indexOf('TeamsMobile') > -1;
		} catch (e) {
			return true;
		}
	}

	scrollToItem = (itemId) => {
		const item = document.getElementById(itemId);
		if (item) {
		  item.scrollIntoView({ block: 'start', behavior: 'instant'});
		}
	};

	handleClick = (url, e) => {
		e.preventDefault(); // Prevent default link behavior

		if (this.inIframe()) {
			microsoftTeams.getContext((context) => {
				if (context.hostClientType === "ios" || context.hostClientType === "android") {
					// Use Teams SDK to open the link within Teams mobile app
					microsoftTeams.executeDeepLink(url);
				} else {
					// Fallback for web and desktop, open in new tab
					window.open(url, '_blank');
				}
			});
		} else {
			// Fallback for web and desktop, open in new tab
			window.open(url, '_blank');
		}
	}

	openDeviceMenu = () => {
		let renderedCategories = new Set();
		let menuItems = [];
		let displayActiveLanguage = getLanguage();
		this.setState({languageMenuOpen: false, confirmLogOutOpen: false});

		if (this.props.items.length > 0) {
			this.props.items.map((item, index) => {
				let categoryDiv = null;

				if (item.type == 'EXTERNAL') {
					return null;
				}

				// Check if the category has not been rendered yet
				if (item.parentId != null && !renderedCategories.has(item.parentId)) {
					renderedCategories.add(item.parentId); // Mark this category as rendered
					categoryDiv = (
						<div className="divider-header">
							<span className='label'>{this.props.translate(item.parentId.toLowerCase())}</span>
						</div>
					);
				}

				let elementDiv = (<div className='menu-divider'>
					{categoryDiv}

					<div className="menu-item-container" key={'item-' + index}>
						<div className='menu-item-device'>
							<div className="menu-item-icon">
								<Icon name={item.iconId} />
							</div>

							<span>{this.props.translate(item.text.toLowerCase())}</span>
						</div>
					</div>
				</div>)

				menuItems.push({
					label: elementDiv,
					value: item
				});
			});


			this.props.items.map((item, index) => {
				if (item.parentId != null && item.parentId != '') {
					return null;
				}

				if (item.type != 'EXTERNAL') {
					return null;
				}

				let label = item.text;
				if (label.indexOf('nav_') > -1) {
					label = this.props.translate(item.text.toLowerCase());
				}

				let elementDiv = (<div className='menu-divider'>
					<div className="menu-item-container" key={'item-' + index}>
						<a className='menu-item-device' href={item.url} onClick={(e) => this.handleClick(item.url, e)} target="_blank">
							<div className="menu-item-icon">
								<Icon name={item.iconId} />
							</div>

							<span>{label}</span>
						</a>
					</div>
				</div>)

				menuItems.push({
					label: elementDiv,
				});
			})

			let languagesDiv = (<div className='menu-divider'>
				<div className="divider-header">
					<span className="label">{this.props.translate("Settings")}</span>
				</div>
				<div className="menu-item-container">
					<a href="#" onClick={() => this.selectLanguage()} className="menu-item-device" id="languages-dropdown">
									<div className="menu-item-icon" style={{ display: "flex", alignItems: "center" }}>
							<img className='flag flag-icon' alt={displayActiveLanguage.label} src={displayActiveLanguage.svg_src} />
						</div>
						<span>{displayActiveLanguage.label} <Icon name="Chevron_Down" style={{ marginLeft: "5px" }} /></span>
					
					</a>
				</div>
			</div>)

			{displayActiveLanguage != null ?
			menuItems.push({
				label: languagesDiv,
			}) : null}

			let logoutDiv = (<div className='menu-divider'>
				{displayActiveLanguage == null ?
					<div className="divider-header">
						<span className="label">{this.props.translate("Settings")}</span>
					</div>
				: null}
				<div className="menu-item-container">
					<a href="#" onClick={() => this.confirmLogOut()} className="menu-item-device" id="logout-confirm">
							<div className="menu-item-icon">
								<img className="person" src='/dist/svg/person.svg' alt='person' />
							</div>
							{this.props.user != null ?
								<span>{this.props.translate('Sign out')} {this.props.user.firstName + ' ' + this.props.user.lastName}</span>
								: <span>{this.props.translate('Sign out')}</span>
							}
					
					</a>
				</div>
			</div>)

			{this.props.user != null ?
				menuItems.push({
				label: logoutDiv,
			}) : null}
		}

		ActionSheet.prompt({
			items: menuItems,
		}).then(item => {
			if (this.inIframe() && item.type === 'EXTERNAL') {
				microsoftTeams.getContext((context) => {
					if (context.hostClientType === "ios" || context.hostClientType === "android") {
						// Use Teams SDK to open the link within Teams mobile app
						microsoftTeams.executeDeepLink(item.url);
					} else {
						// Fallback for web and desktop, open in new tab
						this.props.history.push(item.url);
					}
				});
			} else {
				// Fallback for web and desktop, open in new tab
				this.props.history.push(item.url);
			}
		}, () => {
			// This method only runs if user closes the prompt without pressing an option
			// This method is also optional
		});
	}

	render() {
		const school = this.props.user.schools.find(school => {
			return school.id === this.props.activeSchool;
		});

		const actionTrigger = (
			<div>
				<Icon name="Bullets" />
			</div>
		);

		return (
		<div className='device-menu-container'>
			<div className="device-menu">
				<div className="left">
					{this.props.settings.backButton ?
						<NavLink to="#" onClick={this.goBack} className="c--button back">
							<i className="cl-container">
								<svg xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(90deg)' }} className="a-90">
									<path
										id="Path_50"
										data-name="Path 50"
										className="cls-1"
										d="M11.361,1.4,6.38,5.9,1.4,1.4"
									/>
								</svg>
							</i>
						</NavLink>
						: <NavLink to="#" onClick={this.openDeviceMenu} activeClassName="is-active" className="c--button hamburger-menu">
							<div className="menu-item-icon">
								<Icon name="Burger" />
							</div>
						</NavLink>}
				</div>

				<div className="content">
					{this.isIndex() ?
						<div onClick={this.selectSchool}>
							<div style={{ position: 'relative' }}>
								{this.getBadgeForItem() != null ?
									<span className="badge" style={{ justifyContent: 'center' }}>
										{this.getBadgeForItem()}
									</span>
									: null}

								{school.title}
							</div>

							<Icon name="Chevron" />
						</div>
						: this.props.title}
				</div>

				<div className="right">
					{this.props.actions != null && this.props.actions.length > 0 ?
						<TooltipMenu trigger={actionTrigger}>
							{this.props.actions.map(this.renderAction)}
						</TooltipMenu>
						:
						<NavLink to="/search">
							<Icon name="Magnify" />
						</NavLink>
					}
				</div>
			</div>
		</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		actions: state.Header.actions,
		title: state.Header.title,
		settings: state.Header,
		activeSchool: state.user.activeSchool,
		user: state.user.currentUser,
		items: state.Menu.items,
		userUnread: state.Notifications.userUnread,
	}
}

export default withRouter(connect(mapStateToProps, {
	setActiveSchool,
})(DeviceHeader));
