import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { translate } from '@haldor/ui';
import Moment from 'moment';
import User from '_class/User';
import swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { setPageTitle, setPageActions } from 'actions/header';
import { getUserInfo } from 'actions/user';

import { debounce } from 'helpers/content';
import { getWithExpiration, saveWithExpiration } from 'helpers/localstorage';

import Modal from 'containers/Modals/Modal';
import CalendarEventForm from 'containers/Forms/CalendarEvent';
import SelectUserForm from './Partials/SelectUserForm';

import ScheduleView from './ScheduleView';

import { Spinner } from 'UI';
import { Icon, Tabs, Tab, onLanguageChange } from '@haldor/ui';
import CalendarHeader from './CalendarHeader';
import AssignmentForm from 'containers/Forms/AssignmentForm/AssignmentForm';
import MultipleAssignmentForm from 'containers/Forms/AssignmentForm/MultipleAssignmentForm';
import AgendaModal from './AgendaModal';
import ConflictingEventForm from 'containers/Forms/ConflictingEventForm';

class Schedule extends Component {
	constructor(props) {
		super(props);

		this.breakpoint = 730;

		let type = 'work_week';
		let date = Moment();

		if (localStorage.getItem('haldor-schedule-type') != null) {
			type = localStorage.getItem('haldor-schedule-type');
		}

		if (getWithExpiration('haldor-schedule-date') != null) {
			date = Moment(getWithExpiration('haldor-schedule-date'));
		}


		this.state = {
			loading: true,
			scheduleLoading: false,
			createEvent: false,
			showAgendaModal: false,
			agendaModalDate: undefined,
			formName: '',
			type,
			date,
			mobile: document.body.offsetWidth <= this.breakpoint,
			tabs: [],
			modalTitle: '',
			groupSelected: '',
			dateEvents: null,
			windowWidth: 0,
		};
	}

	isAllowed = () => {
		const user = new User(this.props.user);

		return (
			user.isTeacher() ||
			user.isUserCurriculumAdmin() ||
			user.isSchoolLeader() ||
			user.isSMSAdmin() ||
			user.isPedagogue() ||
			user.isOperationManager() ||
			user.isMentor() ||
			user.isCourseAdministrator() ||
			user.isAdministrator()
		);
	};

	componentDidMount = () => {
		window.addEventListener('resize', this.resize);
		window.addEventListener('hashchange', this.tabChangeEvent);

		onLanguageChange(() => {
			saveWithExpiration('haldor-schedule-date', Moment().format());
		});

		this.resize();

		const { user } = this.props;
		const tabsData = localStorage.getItem('haldor-schedule-tabs');
		const activeTab = localStorage.getItem('haldor-schedule-active-tab');

		if (tabsData != null) {
			if (JSON.parse(tabsData).tabs == null || JSON.parse(tabsData).userId != user.id) {
				localStorage.removeItem('haldor-schedule-tabs');
				localStorage.removeItem('haldor-schedule-active-tab');
				this.setState({ loading: false });
				return false;
			}

			this.setState({ tabs: JSON.parse(tabsData).tabs }, () => {
				let promises = [];

				this.state.tabs.forEach((tab) => {
					if (tab.type == 'teacher') {
						let userPromise = this.props.getUserInfo(tab.id);
						promises.push(userPromise);
					}
				});

				Promise.allSettled(promises).then(() => {
					if (activeTab != null) {
						const foundTab = this.state.tabs.find((tab) => tab.id == activeTab);

						if (foundTab != null) {
							window.location.hash = activeTab;
						} else {
							localStorage.removeItem('haldor-schedule-active-tab');
						}
					}

					this.setState({ loading: false });
				});
			});
		} else {
			this.setState({ loading: false });
		}

		if (this.props.overview) {
			return false;
		}

		this.props.setPageTitle(this.props.translate('schedule-header-overview'));
		this.tabChangeEvent();
	};

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.resize);
		window.removeEventListener('hashchange', this.tabChangeEvent);
	};

	setActions = (disabled = false) => {
		if (this.props.overview) {
			return true;
		}

		let actions = [];
		const user = new User(this.props.user);
		if (!user.isTeacher() && !user.isMentor()) {
			return false;
		}
		//Submenu buttons for header
		let subMenu = [];
		if (this.props.services.advancedSchedule) {
			subMenu = [
				{
					value: this.props.translate('create-lesson'),
					onClick: this.toggleForm,
				},
				{
					value: this.props.translate('Create assignment'),
					onClick: this.toggleAssignment,
				},
				{
					value: this.props.translate('Create group assignment'),
					onClick: this.toggleGroupAssignment,
				},
				{
					value: this.props.translate('Create calendar event'),
					onClick: this.toggleConflictingEvent,
				},
			];
		}

		if (disabled) {
			actions.push({
				type: 'dropDown',
				value: this.props.translate('Create'),
				icon: 'plus',
				onClick: this.toggleForm,
				disabled: true,
				subButtons: subMenu,
			});
		} else {
			if (!this.props.services.advancedSchedule) {
				actions.push({
					type: 'button',
					value: this.props.translate('Create'),
					icon: 'plus',
					onClick: this.toggleForm,
				});
			} else {
				actions.push({
					type: 'dropDown',
					value: this.props.translate('Create'),
					icon: 'plus',
					onClick: this.toggleForm,
					subButtons: subMenu,
				});
			}
		}

		this.props.setPageActions(actions);
	};

	scheduleButtons = (action, date) => {
		if (action === 'create-lesson') {
			this.toggleForm(false, date);
			return;
		} else if (action === 'Create assignment') {
			this.toggleAssignment(false, date);
			return;
		} else if (action === 'Create conflicting event') {
			this.toggleConflictingEvent(false, date);
			return;
		}

		this.toggleGroupAssignment(false, date);
	};

	isLandscape = () => {
		return window.innerHeight < window.innerWidth;
	};

	resize = debounce(() => {
		const width = document.body.offsetWidth;

		// Skip setting mobile if in landscape mode
		if (this.isLandscape()) {
			this.setState({ mobile: false });
			return;
		}

		if (width <= this.breakpoint) {
			if (!this.state.mobile) {
				this.setState({ mobile: true });
			}
		} else {
			if (this.state.mobile) {
				this.setState({ mobile: false });
			}
		}

		this.setState({ windowWidth: width });
	}, 125, false);

	handleGroupDisplay = (groupName) => {
		this.setState({
			groupSelected: groupName,
		});
	};

	tabChangeEvent = () => {
		const hash = window.location.hash.replace('#', '');
		localStorage.setItem('haldor-schedule-active-tab', hash);

		if (hash == '' || hash == 'me') {
			this.setActions(false);
		} else {
			this.setActions(true);
		}
	};

	setScheduleLoading = (value) => {
		this.setState({ scheduleLoading: value });
	}

	goBack = async () => {
		let type = this.state.type;
		if (this.state.mobile) {
			type = 'day';
		}
		if (type == 'month') {
			await this.setState({ date: Moment(this.state.date).subtract(1, 'month') });
		} else if (type == 'week' || type == 'work_week') {
			await this.setState({ date: Moment(this.state.date).subtract(1, 'week') });
		} else {
			await this.setState({ date: Moment(this.state.date).subtract(1, 'day') });
		}

		saveWithExpiration('haldor-schedule-date', this.state.date.format());
	};

	setModalTitle = (title) => {
		this.setState({ modalTitle: title });
	};

	goForward = async () => {
		let type = this.state.type;
		if (this.state.mobile) {
			type = 'day';
		}
		if (type == 'month') {
			await this.setState({ date: Moment(this.state.date).add(1, 'month') });
		} else if (type == 'week' || type == 'work_week') {
			await this.setState({ date: Moment(this.state.date).add(1, 'week') });
		} else {
			await this.setState({ date: Moment(this.state.date).add(1, 'day') });
		}

		saveWithExpiration('haldor-schedule-date', this.state.date.format());
	};

	goToday = async () => {
		await this.setState({ date: Moment() });
		saveWithExpiration('haldor-schedule-date', this.state.date.format());
	};

	setType = (event) => {
		if (this.state.type != event.target.value) {
			localStorage.setItem('haldor-schedule-type', event.target.value);
			this.setState({ type: event.target.value });
		}
	};

	toggleButtons = () => {
		if (this.state.mobile) {
			return null;
		}

		const { translate } = this.props;
		const { type } = this.state;

		return (
			<div className='select'>
				<select
					value={type}
					onChange={this.setType}
					disabled={this.state.scheduleLoading}
				>
					<option value='day'>{translate('day')}</option>

					<option value='work_week'>{translate('Work week')}</option>

					<option value='week'>{translate('week')}</option>

					{this.props.services.advancedSchedule ?
						<option value='month'>{translate('month')}</option>
						: null}

					<option value='agenda'>{translate('agenda')}</option>
				</select>
			</div>
		);
	};

	notReportedSchedulePositions = () => {
		if (this.props.schedulePositions != null && this.props.schedulePositions.length > 0) {
			return (
				<Link to='/report/attendance' className='see-more'>
					{this.props.schedulePositions.length + ' ' + this.props.translate('not-reported')}
				</Link>
			);
		}
	};

	toggleForm = (useStateDate, date) => {
		this.setState({
			createEvent: !this.state.createEvent,
			formName: 'create-lesson',
			dateEvents: useStateDate ? this.getStateDateWithCurrentTime() : date,
		});
	};

	toggleAssignment = (useStateDate, date) => {
		this.setState({
			createEvent: !this.state.createEvent,
			formName: 'Create assignment',
			dateEvents: useStateDate ? this.getStateDateWithCurrentTime() : date,
		});
	};

	toggleGroupAssignment = (useStateDate, date) => {
		this.setState({
			createEvent: !this.state.createEvent,
			formName: 'Create group assignment',
			dateEvents: useStateDate ? this.getStateDateWithCurrentTime() : date,
		});
	};

	toggleConflictingEvent = (useStateDate, date) => {
		this.setState({
			createEvent: !this.state.createEvent,
			formName: 'conflicting event',
			dateEvents: useStateDate ? this.getStateDateWithCurrentTime() : date,
		});
	};

	getStateDateWithCurrentTime = () => {
		return Moment().set({ second: 0, date: this.state.date.date(), month: this.state.date.month(), year: this.state.date.year() });
	};

	closeNewModal = () => {
		window.location.hash = 'me';
	};

	closeModal = (skip = true) => {
		if (skip == true) {
			this.setState({ createEvent: !this.state.createEvent, modalTitle: '' });
		} else {
			swal.fire({
				title: this.props.translate('are-you-sure'),
				text: this.props.translate('close-form-prompt'),
				showCancelButton: true,
				cancelButtonText: this.props.translate('No'),
				confirmButtonText: this.props.translate('Yes'),
			}).then((result) => {
				if (result.value != null) {
					this.setState({
						createEvent: !this.state.createEvent,
						modalTitle: '',
						groupSelected: '',
						dateEvents: null,
					});
				}
			});
		}
	};

	closeAgendaModal = () => {
		this.setState({ showAgendaModal: false });
	};
	enableAgendaModal = (date) => {
		let modalDate = Moment(date);

		let daysFromPreviousMonthVisible = Moment(Moment(this.state.date).startOf('month')).isoWeekday() - 1;
		let start = Moment(this.state.date)
			.startOf('month')
			.subtract(daysFromPreviousMonthVisible, 'd')
			.set({ hour: 0, minute: 0, second: 0 });

		let daysFromNextMonthVisible = 7 - Moment(Moment(this.state.date).endOf('month')).isoWeekday();
		let end = Moment(this.state.date)
			.endOf('month')
			.add(daysFromNextMonthVisible, 'd')
			.set({ hour: 23, minute: 59, second: 59 });

		if (modalDate.isBefore(start) || modalDate.isAfter(end)) {
			let date = Moment(modalDate);
			this.setState({ date: date, agendaModalDate: modalDate, showAgendaModal: true });
			saveWithExpiration('haldor-schedule-date', date.format());
			return;
		}
		this.setState({ agendaModalDate: modalDate, showAgendaModal: true });
	}
	goToDate = (date) => {
		if (this.state.showAgendaModal)
			this.closeAgendaModal();
		this.state.date = Moment(date);
		saveWithExpiration('haldor-schedule-date', this.state.date.format());
		this.setType({ target: { value: 'day' } });
	}

	onSubmit = (date) => {
		const currentHash = window.location.hash.replace('#', '');
		if (currentHash != '' && currentHash != 'me') {
			window.location.hash = 'me';
		}

		this.setState({ date: Moment(date) });
	};

	onTeacherSubmit = (values) => new Promise(async (resolve) => {
		const { user } = this.props;

		if (values == null) {
			return false;
		}

		await this.setState({
			tabs: [
				...this.state.tabs,
				{
					id: values.userId,
					type: 'teacher',
				},
			],
		});

		localStorage.setItem('haldor-schedule-tabs', JSON.stringify({
			userId: user.id,
			tabs: this.state.tabs,
		}));

		await this.props.getUserInfo(values.userId);
		window.location.hash = values.userId;
		resolve(1);
	});

	removeTab = (tab, clickEvent) => {
		let foundIndex = this.state.tabs.findIndex((t) => t.id == tab.id);
		const currentHash = window.location.hash.replace('#', '');
		const { user } = this.props;

		this.setState({ tabs: this.state.tabs.filter((t) => t.id != tab.id) }, () => {
			localStorage.setItem('haldor-schedule-tabs', JSON.stringify({
				userId: user.id,
				tabs: this.state.tabs,
			}));

			if (tab.id == currentHash) {
				if (this.state.tabs.length > 0 && foundIndex > 0) {
					window.location.hash = this.state.tabs[foundIndex - 1].id;
				} else {
					window.location.hash = 'me';
				}
			} else {
				window.location.hash = currentHash;
			}
		});

		if (clickEvent != null) {
			clickEvent.preventDefault();
			clickEvent.stopPropagation();
		}
	};

	toggleIcon = (flip) => (
		<i className='cl-container'>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				xmlSpace='preserve'
				className={flip ? 'i-90' : 'a-90'}
				style={{ height: '12px', width: '15px', margin: 0 }}
			>
				<path
					id='Path_50'
					data-name='Path 50'
					className='cls-1'
					d='M11.361,1.4,6.38,5.9,1.4,1.4'
				/>
			</svg>
		</i>
	);

	renderTabTitle = (tab) => {
		if (tab.type == 'teacher') {
			const user = this.props.users.find((u) => u.userId == tab.id);
			if (user == null) {
				return null;
			}

			const teacher = new User(user);
			return (
				<div className='df aic remove'>
					{teacher.getName()}

					<Icon onClick={(event) => this.removeTab(tab, event)} name='Close' />
				</div>
			);
		}

		return null;
	};

	renderTabs = () => {
		if (this.state.loading) {
			return <Spinner center />;
		}
		const isToday = Moment().isSame(this.state.date, this.state.type == 'week' ? 'week' : 'day');
		const user = new User(this.props.user);
		const { translate } = this.props;
		let studentId = null;
		let type = this.state.type;

		const school = this.props.user.schools.find((school) => {
			return school.id == this.props.activeSchool;
		});

		if (this.state.mobile) {
			type = 'day';
		}

		if (user.isStudent()) {
			studentId = this.props.user.id;
		}

		return (
			<Fragment>
				<Tabs>
					<Tab title={user.getName()} route='me'>
						<CalendarHeader
							notReportedSchedulePositions={this.notReportedSchedulePositions}
							toggleForm={this.toggleForm}
							toggleAssignment={this.toggleAssignment}
							toggleGroupAssignment={this.toggleGroupAssignment}
							toggleConflictingEvent={this.toggleConflictingEvent}
							translate={this.props.translate}
							goToday={this.goToday}
							isToday={isToday}
							toggleIcon={this.toggleIcon}
							goBack={this.goBack}
							displayAdvanced={true}
							goForward={this.goForward}
							tranSlate={this.props.translate}
							isStudentCard={this.props.studentCard}
							overview={this.props.overview}
							toggleButtons={this.toggleButtons}
							actions={this.setActions}
							scheduleLoading={this.state.scheduleLoading}
						/>
						<ScheduleView
							view={type}
							scheduleButtons={this.scheduleButtons}
							date={this.state.date}
							advancedCalendar={this.props.advancedCalendar}
							userId={studentId}
							isStudentCard={this.props.studentCard}
							overview={this.props.overview}
							windowWidth={this.state.windowWidth}
							enableAgendaModal={this.enableAgendaModal}
							goToDate={this.goToDate}
							setScheduleLoading={this.setScheduleLoading}
							{...this.props}
						/>
					</Tab>

					{this.state.tabs.length > 0 ?
						this.state.tabs.map((tab) => {
							const user = this.props.users.find((u) => u.userId == tab.id);
							if (user == null) {
								return null;
							}

							if (tab.type == 'teacher') {
								return (
									<Tab
										title={this.renderTabTitle(tab)}
										route={tab.id}
										key={tab.id}
									>
										<CalendarHeader
											notReportedSchedulePositions={
												this.notReportedSchedulePositions
											}
											toggleForm={this.toggleForm}
											translate={this.props.translate}
											goToday={this.goToday}
											isToday={isToday}
											toggleIcon={this.toggleIcon}
											goBack={this.goBack}
											tranSlate={this}
											goForward={this.goForward}
											overview={this.props.overview}
											isStudentCard={this.props.studentCard}
											toggleButtons={this.toggleButtons}
											displayAdvanced={false}
											noFilter={true}
											noCreate={true}
											scheduleLoading={this.state.scheduleLoading}
										/>
										<ScheduleView
											view={type}
											isStudentCard={this.props.studentCard}
											date={this.state.date}
											noAdvanced={true}
											userId={user.userId}
											notOwner={true}
											enableAgendaModal={this.enableAgendaModal}
											goToDate={this.goToDate}
											setScheduleLoading={this.setScheduleLoading}
											{...this.props}
										/>
									</Tab>
								);
							}

							return null;
						})
							.filter((tab) => tab != null)
						: null}

					{this.isAllowed() ? (
						<Tab
							title={
								<div className='df aic'>
									<Icon name='Plus' />{' '}
									<span className='icon-label'>{translate('Add schedule')}</span>
								</div>
							}
							route='new'
						>
							<div></div>
						</Tab>
					) : null}
				</Tabs>
			</Fragment>
		);
	};

	renderSingleSchedule = () => {
		const user = new User(this.props.user);

		let type = this.state.type;
		let userId = this.props.studentId;

		if (this.state.mobile) {
			type = 'day';
		}
		const isToday = Moment().isSame(this.state.date, this.state.type == 'week' ? 'week' : 'day');
		if (user.isStudent()) {
			userId = this.props.user.id;
		}

		return (
			<Fragment>
				<CalendarHeader
					notReportedSchedulePositions={this.notReportedSchedulePositions}
					toggleForm={this.toggleForm}
					goToday={this.goToday}
					isToday={isToday}
					toggleIcon={this.toggleIcon}
					hasGroup={true}
					overview={this.props.overview}
					goBack={this.goBack}
					isStudentCard={this.props.studentCard}
					goForward={this.goForward}
					toggleButtons={this.toggleButtons}
					scheduleLoading={this.state.scheduleLoading}
				/>

				<ScheduleView
					view={type}
					isStudentCard={this.props.studentCard}
					date={this.state.date}
					noAdvanced={true}
					userId={this.props.studentId}
					enableAgendaModal={this.enableAgendaModal}
					goToDate={this.goToDate}
					setScheduleLoading={this.setScheduleLoading}
					{...this.props}
				/>
			</Fragment>
		);
	};

	handleTitle = () => {
		if (this.state.groupSelected) {
			return `${this.props.translate(this.state.formName)} ${this.props.translate('for')} ${this.state.groupSelected}`;
		} else if (this.state.modalTitle.length > 0) {
			return `${this.props.translate(this.state.formName)} ${this.props.translate('for')} ${this.state.modalTitle}`;
		}

		if (this.state.formName.includes("conflicting")) {
			return this.props.translate('Create calendar event');
		}
		return this.props.translate(this.state.formName);
	};

	render() {
		const user = new User(this.props.user);

		return (
			<div className='overview'>
				{this.props.overview && !this.props.studentCard ?
					<div className='calenderToolBar'>
						<Fragment>
							<Icon name='Calendar' />
							<span
								className='schedule-title'
							>
								{this.props.translate('schedule-header-overview')}
							</span>
						</Fragment>
					</div>
					: null}
				<div
					className={this.state.mobile ? 'schedule section mobile' : 'schedule section'}
					style={{ margin: 0, padding: 0, border: 0 }}
				>
					<Modal
						isOpen={this.state.createEvent}
						onClose={this.closeModal}
						/* title={this.props.translate('create-lesson')} */
						title={this.handleTitle()}
					>
						{this.state.formName.includes('lesson') ? (
							<CalendarEventForm
								onAbort={this.closeModal}
								sectionId={this.props.sectionId}
								onSubmit={this.onSubmit}
								setModalTitle={this.setModalTitle}
								hasDate={this.state.dateEvents}
							/>
						) : this.state.formName.includes('group') ? (
							<AssignmentForm
								onAbort={this.closeModal}
								editView={false}
								cloneView={false}
								disablePlanSelector={true}
								displayTitle={this.handleGroupDisplay}
								hasDate={this.state.dateEvents}
								onSubmit={this.onSubmit}
								dontRedirect={true}
							/>
						) : this.state.formName.includes('conflicting') ? (
							<ConflictingEventForm
								onAbort={this.closeModal}
								onSubmit={this.onSubmit}
								hasDate={this.state.dateEvents}
							/>
						) : (
							<MultipleAssignmentForm
								onAbort={this.closeModal}
								editView={false}
								cloneView={false}
								onSubmit={this.onSubmit}
								disablePlanSelector={true}
								displayTitle={this.handleGroupDisplay}
								hasDate={this.state.dateEvents}
							/>
						)}
					</Modal>

					<AgendaModal
						date={this.state.agendaModalDate}
						advancedCalendar={this.props.advancedCalendar}
						showAgendaModal={this.state.showAgendaModal}
						closeAgendaModal={this.closeAgendaModal}
						setAgendaModalDate={this.enableAgendaModal}
						toggleIcon={this.toggleIcon}
						goToDate={this.goToDate}
					/>

					<Modal
						type='small news'
						overridePrompt
						isOpen={window.location.hash == '#new'}
						onClose={this.closeNewModal}
					>
						<SelectUserForm
							onSubmit={this.onTeacherSubmit}
							forbiddenUsers={this.state.tabs.map((t) => t.id)}
						/>
					</Modal>

					{this.props.studentId != null || user.isStudent()
						? this.renderSingleSchedule()
						: this.renderTabs()}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.user.currentUser,
		users: state.user.info,
		services: state.Services.availableServices,
		translate: translate(state.Languages.translations),
		schedulePositions: state.schedule.schedulePositions,
		section: state.sections.activeSection,
		activeSchool: state.user.activeSchool,
		advancedCalendar: state.ScheduleFilter.advancedCalendarActive,
	};
}

export default connect(mapStateToProps, {
	setPageTitle,
	setPageActions,
	getUserInfo,
})(Schedule);
