import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addError } from 'actions';

import api from 'lib/api';
import getEnvironment from 'lib/env';
import { getOnBehalfOfAccessToken } from 'actions/user';

import Select from './select';

import { Expandable, Spinner } from 'UI';
import { MS_OneDrive } from 'UI/Icons';
import { Button, Icon, translate } from '@haldor/ui';

class Upload extends Component {
	constructor(props) {
		super(props);

		this.state = {
			modalIsOpen: false,
			fileTypes: [
				{ value: 1, text: props.translate('instructions') },
				{ value: 2, text: props.translate('template-document') },
			],
			forceUpdate: false,
			newPicker: true,
			graphPickerUrl: 'https://graph.microsoft.com/beta/me',
			activeSelection: 'me',
			pickedItems: [],
			loadingPicker: false,
		};
	}

	componentDidMount = () => { }

	launchOneDrivePicker = () => {
		const that = this;

		const odOptions = {
			clientId: getEnvironment().clientId,
			action: 'query',
			multiSelect: true,
			openInNewWindow: true,
			advanced: {
				queryParameters: 'select=id,name,size,file,folder,photo,webUrl,webDavUrl,location,parentReference',
				redirectUri: window.location.origin + `/assignment/callback`
			},
			success(files) {
				let pickedItems = that.state.pickedItems;

				files.value.forEach((file) => {
					const oneDriveFile = { id: file.id, key: file.name, mimeType: file.file ? file.file.mimeType : 'unknown/mime-type', parentReference: file.parentReference, value: file.webDavUrl, fileType: 0 };
					pickedItems.push(oneDriveFile);
				});

				that.setState({ forceUpdate: true, pickedItems }, () => {
					that.props.onUpdate(pickedItems);
				});
			},
			cancel() { },
			error(e) {
				console.log(e);
			},
		};

		OneDrive.open(odOptions);
	}

	selectFileType = (event, file) => {
		file.fileType = event.target.value;

		this.setState({}, () => {
			this.props.onUpdate(this.state.pickedItems);
		});
	}

	onRemove = (file) => {
		let pickedItems = [...this.state.pickedItems].filter(doc =>
			doc.value !== file.value
		);

		this.setState({ pickedItems: pickedItems }, () => {
			this.props.onUpdate(pickedItems);
		});
	}

	filesList = () => {
		const { pickedItems } = this.state;

		let foundDocument = pickedItems.find((file) => {
			if (file.fileType != null) {
				return file.fileType == 0 && file.mimeType !== 'unknown/mime-type';
			}

			return false;
		});

		let documentsPassing = true;
		if (foundDocument != null) {
			documentsPassing = false;
		}

		if (!pickedItems) {
			return <div />;
		}

		return (
			<div>
				{!documentsPassing && this.props.form == 'assignment' ?
					<div style={{ paddingTop: '1rem' }}>
						{this.props.translate('need-to-select-file-types')}!
					</div>
					: null}

				{pickedItems.map((file, i) => {
					if (file.key != "" && file.key != null && file.mimeType !== 'unknown/mime-type') {
						return <div key={file.value} className="attached-file">

							{this.props.lockedStatus && file.fileType === '2' ?
								<div style={{ transform: 'scale(1.1)' }}>
									<Icon name='Lock' />
								</div>

								:
								<Icon name="File" />
							}
							<span className="file-name">{file.key}</span>

							{this.props.form == 'assignment' ?
								<Select name="filetype"
									options={this.state.fileTypes}
									selectedValue={file.fileType}
									onChange={event => this.selectFileType(event, file)}
									placeholder={this.props.translate('choose-filetype')}
								/>
								: null}

							<Button type="secondary" onClick={() => { this.onRemove(file); }}>
								{this.props.translate('Delete')}
							</Button>
						</div>
					}
					else {
						return <div key={file.value} className="attached-file">
							{this.props.lockedStatus && file.fileType === '2' ?
								<div style={{ transform: 'scale(1.1)' }}>
									<Icon name='Lock' />
								</div>

								:
								<Icon name="File" />
							}
							<span className="file-name">{file.key}</span>
							<Icon name="Alert_Red" />
							<span style={{ marginLeft: '0.5rem', marginRight: '0.85rem' }}> {this.props.translate('File format not supported.')}</span>
							<Button type="secondary" onClick={() => { this.onRemove(file); }}>
								{this.props.translate('Delete')}
							</Button>
						</div>
					}
				})}
			</div>
		);
	}



	render() {
		let meClassname = 'section-item';
		if (this.state.activeSelection == 'me') {
			meClassname = meClassname + ' active';
		}

		return (
			<div className="upload-wrapper" key="upload-wrapper-picker">
				<Expandable title={this.props.translate('attach-files')}>
					{this.state.newPicker == null ?
						<Spinner small center />
						:
						<div>
							{this.props.isLoading ?
								<div className="is_sending"><p><span className="loading-spinner" /></p></div>
								: null}
							<div id="upload" className="upload-container">
								<div className="upload-buttons">
									<Button type="secondary" onClick={(e) => { e.preventDefault(); this.launchOneDrivePicker(); }}>
										<MS_OneDrive /> {this.props.translate('open-sharepoint-onedrive')}
									</Button>
								</div>

								<div className="selected-files">
									{this.filesList()}
								</div>
							</div>
						</div>
					}
				</Expandable>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		tenant: state.user.tenant,
		translate: translate(state.Languages.translations),
		groups: state.sections.educationGroups,
		user: state.user.currentUser,
		access_token: state.user.accessToken
	};
}

export default connect(mapStateToProps, {
	addError,
	getOnBehalfOfAccessToken
})(Upload);
