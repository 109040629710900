import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { getUserSubjects } from 'actions/user';
import { updateStudentAdjustment } from 'actions/additional_adjustments';

import { Spinner } from 'UI';
import { Checkbox, Button } from '@haldor/ui';
import swal from 'sweetalert2';

class EditAdditionalAdjustmentSubjects extends Component {

	constructor(props) {
		super(props);

		this.state = {
			subjects: null,
			loading: true,
			selectedSubjects: [],
		};
	}

	componentDidMount = () => {
		if (this.props.user != null) {
			this.props.getUserSubjects(this.props.adjustment.studentID).then(() => {
				if (this.props.userSubjects.length > 0) {
					let subjects = [...this.props.userSubjects];

					subjects.forEach(subject => {
						let found = this.props.adjustment.subjects.find(selectedSubject => {
							return selectedSubject.id == subject.id;
						});

						subject.selected = found != null;
						subject.active = true;
						return subject;
					});

					this.props.adjustment.subjects.forEach((subject) => {
						let found = subjects.find(selectedSubject => {
							return selectedSubject.id == subject.id;
						});

						if (found == null) {
							let newSubject = { ...subject };
							newSubject.selected = true;
							newSubject.active = false;
							subjects.push(newSubject);
						}
					});

					this.setState({ subjects, loading: false });
				} else {
					this.setState({ loading: false });
				}
			});
		}
	}

	submit = (values) => {
		return new Promise(async (resolve) => {
			let values = JSON.parse(JSON.stringify(this.props.adjustment));
			let subjects = values.subjects;
			let removedSubjects = [];

			if (this.state.subjects != null) {
				this.state.subjects.forEach((subject) => {
					if (subject.selected) {
						// Add non selected subject
						let found = this.props.adjustment.subjects.find(selectedSubject => {
							return selectedSubject.id == subject.id;
						});

						if (found == null) {
							subjects.push(subject);
						}
					} else {
						// Not selected remove from values if needed
						let foundIndex = subjects.findIndex(selectedSubject => {
							return selectedSubject.id == subject.id;
						});

						if (foundIndex > -1) {
							removedSubjects.push(subjects[foundIndex].id);
							subjects.splice(foundIndex, 1);
						}
					}
				});
			}

			if (removedSubjects.length > 0) {
				let response = await swal.fire({
					title: this.props.translate('Are you sure?'),
					text: this.props.translate('If you delete a subject, the adjustment will no longer be used for those subjects.'),
					showCancelButton: true,
					focusConfirm: false,
					cancelButtonText: this.props.translate('No'),
					confirmButtonText: this.props.translate('Yes'),
				})

				if (response.value == null) {
					resolve(0);
					return false;
				}
			}

			values.subjects = subjects;

			// Clean up properties that doesnt need to be sent to the api
			delete values.comments;
			delete values.createdBy;
			delete values.editedBy;
			delete values.versions;
			delete values.student;

			this.props.updateStudentAdjustment(values).then(() => {
				resolve(1);
				this.props.onClose();
			});
		});
	}

	selectAllSubjects = (checked) => {
		let { subjects } = this.state;

		if (subjects != null) {
			subjects.forEach(subject => {
				subject.selected = checked;
				return subject;
			});
		}

		this.setState({ subjects });
	}

	onSubjectChange = (_subject) => {
		let { subjects } = this.state;

		if (subjects != null) {
			subjects.forEach(subject => {
				if (subject.id == _subject.id) {
					if (_subject.selected == true) {
						subject.selected = false;
					} else {
						subject.selected = true;
					}

					return subject;
				}
			});
		}

		this.setState({ subjects });
	}

	render() {
		const { submitting, handleSubmit, valid } = this.props;
		let hasInactive = false;

		if (this.state.subjects != null) {
			hasInactive = this.state.subjects.find(subject => subject.active == false) != null;
		}

		return (
			<div className="form-container">
				{submitting ?
					<div className="is_sending"><p><span className="loading-spinner" /></p></div>
					: null}

				<form onSubmit={handleSubmit(this.submit)} className="form form-component">
					<div className="form-row">
						{!this.state.loading ?
							<div>
								<label>{this.props.translate('choose-subjects')}</label>

								<div style={{ marginBottom: '.35rem' }}>
									<Checkbox onChange={this.selectAllSubjects} label={this.props.translate('select-all')} />
								</div>

								{this.state.subjects.map(subject => {
									if (subject.active == false) {
										return null;
									}

									let title = subject.title;
									if (subject.typeOfSchooling != null) {
										title += ` (${this.props.translate(subject.typeOfSchooling)})`;
									}

									return <div style={{ marginBottom: '.35rem' }} key={subject.id}>
										<Checkbox
											value={subject.selected}
											onChange={() => this.onSubjectChange(subject)}
											label={title}
										/>
									</div>
								})}

								{hasInactive ?
									<div>
										<label>{this.props.translate('Inactive subjects')}</label>

										{this.state.subjects.map(subject => {
											if (subject.active) {
												return null;
											}

											let title = subject.title;
											if (subject.typeOfSchooling != null) {
												title += ` (${this.props.translate(subject.typeOfSchooling)})`;
											}

											return <div style={{ marginBottom: '.35rem' }} key={subject.id}>
												<Checkbox
													value={subject.selected}
													onChange={() => this.onSubjectChange(subject)}
													label={title}
												/>
											</div>
										})}
									</div>
									: null}

							</div>
							: <Spinner center />}
					</div>

					<div className="form-divider" />

					<div className="form-row submit spacing">
						<div className="pull-right">
							<Button disabled={submitting || !valid || this.state.category == ''}>
								{this.props.translate('save')}
							</Button>
						</div>

						<div className="clearfix" />
					</div>
				</form>

			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		additionalAdjustments: state.AdditionalAdjustments.adjustments,
		userSubjects: state.user.userSubjects,
		user: state.user.currentUser,
	}
}

export default connect(mapStateToProps, {
	getUserSubjects,
	updateStudentAdjustment,
})(reduxForm({
	form: 'EditAdditionalAdjustmentSubjects',
	destroyOnUnmount: true,
})(EditAdditionalAdjustmentSubjects));
