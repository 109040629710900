import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { setPageTitle } from 'actions/header';
import { Icon, Select, translate as translateConstructor, Button as HaldorButton } from '@haldor/ui';
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, svSE, useGridApiRef, gridClasses } from '@mui/x-data-grid';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import SelectMultiple from '../Overview/TableFilterComponents/SelectMultiple';
import { Button, Stack, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { AssignmentTaskStatusCanDevelop, AssignmentTaskStatusCompleted, AssignmentTaskStatusNotComplete, AssignmentTaskStatusNotStarted, AssignmentTaskStatusStarted, AssignmentTaskStatusSubmitted } from 'UI/Icons';
import NoItemsFoundLayout from './NoItemsFoundLayout';

import { getHaldorAssignments, getMicrosoftEducationAssignments, clearAssignmentsFromStore } from 'actions/assignments'

import assignmentTaskStatusIcons from '../../constants/assignmentTaskStatusIcons';

import './_MyAssignmentsV2.scss';
import CustomColumnSelector from 'components/DataGrid/CustomColumnSelector';
import DatePickerFromTo from 'containers/Forms/Partials/DatePickerFromTo';
import LoadingCard from 'components/Cards/LoadingCard';


const customLocaleText = {
	...svSE.components.MuiDataGrid.defaultProps.localeText,
	toolbarColumns: 'Välj kolumner',
};

const CustomToolbar = React.memo(({ filterValues, onChangeFilter, resetTableFilter, groupId, mySections, subjects, isLoading, assignmentTypes }) => {
	const groupOptions = useMemo(() => mySections?.filter(item => item?.id > 0)?.map(item => ({ id: item.id, title: item.title })) || [], [mySections]);
	const subjectsOptions = useMemo(() => {
		if (subjects == null) return [];

		const uniqueSubjectObjects = subjects.reduce((uniqueSubjects, subject) => {
			if (!uniqueSubjects.some(item => item.title === subject.subjectTitle)) {
				uniqueSubjects.push({ id: subject.subjectId, title: subject.subjectTitle });
			}
			return uniqueSubjects;
		}, []);

		return uniqueSubjectObjects;
	}, [subjects]);

	const initialSelectedGroups = useMemo(() => filterValues?.groups ?? [], [filterValues?.groups]);
	const initialSelectedAssignmentTypes = useMemo(() => filterValues?.assignmentTypes ?? [], [filterValues?.assignmentTypes]);
	const initialSelectedSubjects = useMemo(() => filterValues?.subjects ?? [], [filterValues?.subjects]);

	const noFilterApplied = useMemo(() => {
		return !filterValues || Object.values(filterValues).every(arr => arr.length === 0);
	}, [filterValues]);

	if (isLoading) return null;

	return (
		<div>
			<GridToolbarContainer sx={{
				paddingLeft: '1.5rem',
				paddingTop: '1rem',
				marginBottom: '1rem',
			}}>
				<SelectMultiple onChange={(selectedOptions) => onChangeFilter('subjects', selectedOptions)} initialSelectedItems={initialSelectedSubjects} options={subjectsOptions} label='Ämnen' sx={{ minWidth: 300, maxWidth: 350 }} />
				{groupId == null ? <SelectMultiple onChange={(selectedOptions) => onChangeFilter('groups', selectedOptions)} initialSelectedItems={initialSelectedGroups} options={groupOptions} label='Grupper' sx={{ minWidth: 300, maxWidth: 350 }} /> : null}
				<SelectMultiple onChange={(selectedOptions) => onChangeFilter('assignmentTypes', selectedOptions)} initialSelectedItems={initialSelectedAssignmentTypes} options={assignmentTypes} label='Uppgiftstyper' sx={{ minWidth: 300, maxWidth: 350 }} />
				{noFilterApplied ? null : <Button onClick={resetTableFilter} sx={{ padding: '7.5px 14px;', color: 'rgba(0, 0, 0, 0.6)' }} variant="outlined" startIcon={<DeleteIcon />}>Rensa Filter</Button>}
				<CustomColumnSelector></CustomColumnSelector>
			</GridToolbarContainer >
		</div>
	);
});

function MyAssignmentsTableAsStudent({ pageSize, section }) {
	const assignments = useSelector(state => state.assignments.assignments);
	const teamsAssignments = useSelector(state => state.assignments.teamsAssignments);
	const translations = useSelector(state => state.Languages.translations);
	const mySections = useSelector(state => state.sections.educationGroups);
	const assignmentTypes = useSelector(state => state.status.status.assignmentTypes);
	const assessmentStatus = useSelector(state => state.status.status.assessmentStatus);
	const assignmentStatus = useSelector(state => state.status.status.assignmentStatus);
	const assignmentTaskStatus = useSelector(state => state.status.status.assignmentTaskStatus);
	const services = useSelector(state => state.Services.availableServices);
	const haldorAssignmentsFetchedUTC = useSelector(state => state.assignments.haldorAssignmentsFetchedUTC);
	const teamsAssignmentsFetchedUTC = useSelector(state => state.assignments.teamsAssignmentsFetchedUTC);

	const history = useHistory();
	let translate = translateConstructor(translations);

	const assignmentTypesOptions = assignmentTypes != null ? assignmentTypes.map(item => { return { id: item.id, title: item.value } }) : [];

	const [currentAssignmentList, setCurrentAssignmentList] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [allSubjects, setAllSubjects] = useState(null);
	const [startDate, setStartDate] = useState(moment().month() < 7 ? moment().month(0).date(1) : moment().month(6).date(1));
	const [endDate, setEndDate] = useState(moment().month() < 7 ? moment().month(6).date(31) : moment().month(11).date(31));

	const dispatch = useDispatch();

	const apiRef = useGridApiRef();
	const currentTableFilter = useRef(null);

	useEffect(() => {
		/**
		 * Set the default term to 'semester' when the component is mounted.
		 */
		onTermChange('semester');

		if ((assignments == null && teamsAssignments == null) || (section != null && section.archived)) {
			setIsLoading(true);
			updateData(false);
		}

		if (section == null) {
			currentTableFilter.current = JSON.parse(localStorage.getItem("assignments_filter_objects"));
		}

		dispatch(setPageTitle(translate('tasks-header-overview')));

		return () => {
			if (section != null && section.archived) {
				dispatch(clearAssignmentsFromStore());
			}
		};
	}, []);


	/**
	 * This useEffect will be triggered when the assignments are fetched from the API
	 * or when loading is false and datagrid is rendered.
	 */
	useEffect(() => {
		if (assignments != null && assignments.length > -1) {
			setCurrentAssignmentList(assignments);
		} else if (teamsAssignments != null && teamsAssignments.length > -1) {
			setCurrentAssignmentList(teamsAssignments.filter(item => item.reference != null).map(item => item.reference));
		}
	}, [assignments]);

	/**
	 * Once currentAssignmentList is set, we will fetch the subjects from the assignments and set isLoading to false. When isLoading is false, the the apiRef will be initiated
	 */
	useEffect(() => {
		if (currentAssignmentList != null && currentAssignmentList.length > -1) {
			fetchSubjects();
			setIsLoading(false);
		}
	}, [currentAssignmentList]);

	/**
	 * When isLoading is false, we can start to filter the assignments. This will make sure the table will be updated correctly
	 */
	useEffect(() => {
		if (!isLoading && allSubjects != null) {
			filterAssignments();
		}
	}, [isLoading, allSubjects, section]);


	/**
	 * Once DataGrid is loaded, we will set the sortModel from the localstorage. (This can happen whenever the component is mounted)
	 */
	useEffect(() => {
		if (apiRef != null && apiRef.current != null && apiRef.current.setSortModel != null) {
			let sortModel = JSON.parse(localStorage.getItem("assignments_sort_objects"));
			if (sortModel != null)
				apiRef.current.setSortModel(sortModel);
		}
	}, [apiRef, isLoading]);

	/**
	 * This method will be retrieving the assignments from the API.
	 * @param {*} forceReload 
	 */
	const updateData = (forceReload = true) => {


		if (services.haldor) {
			// fetch from haldor if section is archived
			if (section != null && section.archived == true) {
				setIsLoading(true);
				dispatch(getHaldorAssignments(section.graphId, moment(startDate).utc().format('YYYY-MM-DD hh:mm'), moment(endDate).utc().format('YYYY-MM-DD hh:mm')));
				return;
			}

			// fetch assignments if they are not already fetched or if haldorAssignmentsFetchedUTC is older than 10 minutes
			if ((assignments == null || forceReload || moment().diff(moment(haldorAssignmentsFetchedUTC), 'minutes') > 10)) {
				setIsLoading(true);
				dispatch(getHaldorAssignments(null, moment(startDate).utc().format('YYYY-MM-DD hh:mm'), moment(endDate).utc().format('YYYY-MM-DD hh:mm')));
			}
		} else if (!services.haldor && services.microsoft) {
			if ((teamsAssignments == null || forceReload || moment().diff(moment(teamsAssignmentsFetchedUTC), 'minutes') > 10)) {
				setIsLoading(true);
				dispatch(getMicrosoftEducationAssignments());
			}
		}
	}

	/**
	 * Filter the assignments based on the current filter objects.
	 */
	const filterAssignments = () => {
		let filteredAssignments = currentAssignmentList;
		/**
		 * This function uses the groupId prop from the component to filter the assignments. 
		 * Mainly used when the component is used in a group context.
		 */
		if (section != null) {
			filteredAssignments = filteredAssignments.filter((assignment) => {
				return assignment.sectionId == section.id;
			});
		}


		if (currentTableFilter.current) {
			const { groups, assignmentTypes, assessmentStatus, assignmentStatus, subjects } = currentTableFilter.current;

			if (groups && groups.length > 0) {
				filteredAssignments = filteredAssignments.filter((assignment) => {
					return groups.some((group) => group.id == assignment.sectionId);
				});
			}

			if (assignmentTypes && assignmentTypes.length > 0) {
				filteredAssignments = filteredAssignments.filter((assignment) => {
					return assignmentTypes.some((type) => type.id == assignment.assignmentType);
				});
			}

			if (assessmentStatus && assessmentStatus.length > 0) {
				filteredAssignments = filteredAssignments.filter((assignment) => {
					return assessmentStatus.some((status) => status.id == assignment.assessmentStatus);
				});
			}

			if (assignmentStatus && assignmentStatus.length > 0) {
				filteredAssignments = filteredAssignments.filter((assignment) => {
					return assignmentStatus.some((status) => status.id == assignment.assignmentStatus);
				});
			}

			if (subjects && subjects.length > 0) {
				let filteredSubjectIds = allSubjects
					.filter((subject) => subjects.some((selectedSubject) => selectedSubject.title === subject.subjectTitle))
					.map((subject) => subject.subjectId);

				filteredAssignments = filteredAssignments.filter((assignment) => {
					return assignment.courses.some((course) =>
						filteredSubjectIds.includes(course.subjectId)
					);
				});
			}
		}

		if (apiRef && apiRef.current && apiRef.current.setRows) {
			apiRef.current.setRows(filteredAssignments);
			let sortModel = JSON.parse(localStorage.getItem("assignments_sort_objects"));
			apiRef.current.setSortModel(sortModel ?? []);
		}
	};

	/**
	 * This function is called when the user changes the filter in the table filter component.
	 * @param {*} filterType 
	 * @param {*} selectedOptions 
	 */
	const onChangeFilter = (filterType, selectedOptions) => {
		const filterObjects = {
			...currentTableFilter.current,
			[filterType]: selectedOptions
		};

		localStorage.setItem("assignments_filter_objects", JSON.stringify(filterObjects));
		currentTableFilter.current = filterObjects;

		filterAssignments();
	};

	/**
	 * This function is called when the user changes the sort model in the data grid.
	 * @param {*} details 
	 */
	const onSortModelChange = (details) => {
		localStorage.setItem("assignments_sort_objects", JSON.stringify(details));
		if (apiRef != null && apiRef.current != null)
			apiRef.current.setSortModel(details);
	}

	/**
	 * This function is called when the user clicks the 'Reset Filter' button in the table filter component.
	 */
	const resetTableFilter = () => {
		localStorage.removeItem("assignments_filter_objects");
		currentTableFilter.current = null;
		filterAssignments();
	}

	/**
	 * Fetch all subjects from the current assignment list.
	 */
	const fetchSubjects = async () => {
		let subjects = [];
		currentAssignmentList.forEach(assignment => {

			if (assignment.courses == null || assignment.courses.length == 0) return null;

			assignment.courses.forEach(course => {
				const subjectId = course.subjectId;
				const subjectTitle = course.subjectTitle;
				const existingSubject = subjects.find(subject => subject.subjectId === subjectId);
				if (!existingSubject) {
					subjects.push({
						subjectId,
						subjectTitle
					});
				}
			});
		});

		setAllSubjects(subjects);
	}

	const handleRowClick = (params) => {
		let assignmentId = params?.row?.externalID ?? params?.row?.id;
		history.push('/assignment/' + assignmentId);
	};

	const renderAssignmentTitle = (assignment) => {
		return (
			<Stack direction="row" spacing={1}>
				<div style={{ width: '20px' }}>
					{assignment != null && assignment.externalID != null ?
						<div className='teams-meeting-indicator'>
							<Icon name='Teams' />
						</div> : null}
				</div>
				<div>{assignment.title}</div>
			</Stack>
		)
	}

	const renderAssignmentTaskStatus = (currentStatus) => {
		if (currentStatus == null) return null;
		let icon = assignmentTaskStatusIcons.find(item => item.id == currentStatus.id)?.icon;
		return (<Stack direction="row" spacing={1}>
			<div style={{ width: '20px' }}>{icon}</div>
			<div>{currentStatus.value}</div>
		</Stack>)
	}

	const renderInformationColumn = (params) => {
		let assignmentType = params?.value;
		let status = assignmentStatus.find(item => item.id === params?.row?.status);
		return (<Stack spacing={1} direction="row" useFlexGap flexWrap="wrap" spacing={1}>

			{assignmentType != null ? <div className='card-meta'>{assignmentType}</div> : null}
			<div className='card-meta'>{status?.value}</div>

		</Stack>)
	}

	const RenderDataGrid = () => {
		const columns = [
			{
				field: 'title',
				headerName: translate('Title'),
				headerClassName: 'table-header',
				renderHeader: (params) => <div style={{ marginLeft: '25px' }}>{translate('Title')}</div>,
				renderCell: (params) => renderAssignmentTitle(params?.row),
				valueGetter: (params) => { return params?.row?.title; },
				width: 250,
			},
			{
				field: 'sectionName',
				headerName: translate('Group'),
				headerClassName: 'table-header',
				width: 250,
				valueGetter: (params) => `${params?.row?.section?.title || ''}`,
			},
			{
				field: 'dueDate',
				headerName: translate('Due Date'),
				headerClassName: 'table-header',
				description: 'This column has a value getter and is not sortable.',
				width: 150,
				renderCell: (params) => RenderDueDate(moment(params?.row?.dueDate).format('YYYY-MM-DD')),
			},
			{
				field: 'assignmentTaskStatus',
				headerClassName: 'table-header',
				headerName: translate('Work status'),
				minWidth: 150,
				flex: 1,
				renderCell: (params) => {
					const assignmentTask = params?.row?.tasks != null && params.row.tasks.length > 0 ? params.row.tasks[0] : null;
					const currentStatus = assignmentTaskStatus.find(task => task.id == assignmentTask.status);
					return renderAssignmentTaskStatus(currentStatus);
				},
				valueGetter: (params) => {
					const assignmentTask = params?.row?.tasks != null && params.row.tasks.length > 0 ? params.row.tasks[0] : null;
					const status = assignmentTaskStatus.find(task => task.id == assignmentTask.status);
					return status != null ? status.value : '';
				}
			},
			{
				field: 'assessmentStatus',
				headerClassName: 'table-header',
				headerName: 'Bedömningsstatus',
				minWidth: 150,
				flex: 1,
				renderCell: (params) => {
					var status = params?.value;
					return status;
				},
				valueGetter: (params) => {
					const assignmentTask = params?.row?.tasks != null && params.row.tasks.length > 0 ? params.row.tasks[0] : null;
					const assessmentStatus = assignmentTask.assessments == null || assignmentTask.assessments.length == 0 ? null : assignmentTask.assessments[0].status;
					return assessmentStatus == "ASSESSMENT_PUBLISHED" ? translate('assessed') : translate('Not assessed');
				}
			},
			{
				field: 'information',
				headerClassName: 'table-header',
				headerName: translate('Information'),
				minWidth: 150,
				flex: 1,
				renderCell: (params) => renderInformationColumn(params),
				valueGetter: (params) => {
					const assignmentType = assignmentTypesOptions.find(option => option.id === params?.row?.assignmentType);
					return assignmentType ? assignmentType.title : null;
				}
			},
		];

		return (<div>
			<div style={{ backgroundColor: '#FFFFFF' }}>
				<DataGrid
					sx={{
						width: '100%', '--DataGrid-overlayHeight': '300px', [`& .${gridClasses.cell}`]: {
							py: 2.5,
						},
					}}
					autoHeight
					localeText={customLocaleText}
					columns={columns}
					rows={currentAssignmentList == null ? [] : currentAssignmentList}
					density='standard'
					loading={isLoading}
					getRowClassName={(params) => { return 'table-row' }}
					onRowClick={handleRowClick}
					onSortModelChange={onSortModelChange}
					disableColumnFilter={true}
					disableColumnSelector={true}
					getRowHeight={() => 'auto'}
					apiRef={apiRef}
					slots={{
						noRowsOverlay: () => (<NoItemsFoundLayout title={'No assignments found'} />),
						toolbar: () => (
							<CustomToolbar
								filterValues={currentTableFilter.current}
								onChangeFilter={onChangeFilter}
								resetTableFilter={resetTableFilter}
								groupId={section != null ? section.id : null}
								mySections={mySections}
								assessmentStatus={assessmentStatus}
								assignmentStatus={assignmentStatus}
								subjects={allSubjects}
								isLoading={isLoading}
								assignmentTypes={assignmentTypesOptions}
							/>
						)
					}}
				/>
			</div>
		</div>);
	};

	const RenderDueDate = (date) => {
		return (
			<div>
				{moment(date).format('YYYY-MM-DD')}
			</div>
		);
	}

	const onTermChange = (value) => {
		if (value != '') {
			let dStartDate = moment();
			let dDueDate = moment();

			switch (value) {
				case 'year':
					if (moment().month() < 7) {
						dStartDate = moment().subtract(1, 'year').month(7).date(1);
						dDueDate = moment().month(6).date(31);
					} else {
						dStartDate = moment().month(7).date(1);
						dDueDate = moment().add(1, 'year').month(6).date(31);
					}

					break;
				case 'previous-year':
					if (moment().month() < 7) {
						dStartDate = moment().subtract(2, 'year').month(7).date(1);
						dDueDate = moment().subtract(1, 'year').month(6).date(31);
					}
					else {
						dStartDate = moment().subtract(1, 'year').month(7).date(1);
						dDueDate = moment().subtract(0, 'year').month(6).date(31);
					}
					break;
				case 'semester':
					if (moment().month() < 7) {
						dStartDate = moment().month(0).date(1);
						dDueDate = moment().month(6).date(31);
					} else {
						dStartDate = moment().month(6).date(1);
						dDueDate = moment().month(11).date(31);
					}

					break;
			}


			setStartDate(dStartDate);
			setEndDate(dDueDate);
		}
	}

	const onDateChange = (dates) => {
		setStartDate(dates.start);
		setEndDate(dates.end);
	};


	if (isLoading) {
		return (<LoadingCard count={3}></LoadingCard>)
	}

	return (
		<div>
			<Grid className='mb-3' container justifyContent="flex-start" alignItems="center" spacing={2}>
				<Grid item xs={12} sm={12} md={9}>
					<div className="form">
						<div className="form-row">
							<DatePickerFromTo
								values={{
									start: moment(startDate),
									end: moment(endDate),
								}}
								onChange={onDateChange}
							/>
						</div>
					</div>
				</Grid>
				<Grid item xs={12} sm={12} md={3}>
					<div className='term-change' style={{ paddingBottom: '1.2rem' }}>
						<Select
							onChange={onTermChange}
							options={[
								{
									label: translate('current-schoolyear'),
									value: 'year',
								},
								{
									label: translate('previous-schoolyear'),
									value: 'previous-year',
								},
								{
									label: translate('current-semester'),
									value: 'semester',
								},
							]}
						>
							<strong style={{ marginBottom: '0.2rem' }}>{translate('select-period')}</strong>
						</Select>
					</div>
					<HaldorButton type='secondary' onClick={updateData}>{translate('Update')}</HaldorButton>
				</Grid>
			</Grid>
			<div>{RenderDataGrid()}</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAssignmentsTableAsStudent);