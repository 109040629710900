import { getMentorGroups, getSection, getSectionGuardians, toggleSelectedSection } from 'actions/sections';
import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Expandable, Spinner } from 'UI';
import { Checkbox } from '@haldor/ui';
import { BlockContent } from 'UI/Elements/Block';
import './MultipleSectionSelector.scss';
import DisplayName from 'components/Presentation/DisplayName';

class StudentGuardianSelector extends Component {
	constructor(props) {
		super(props);

		var sections = JSON.parse(JSON.stringify(props.educationSections));
		if (props.mentorGroups) {
			sections = JSON.parse(JSON.stringify(props.mentorSections));
		}
		if (sections != null) {
			sections = sections.sort(function (a, b) {
				return (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0);
			});
		} else {
			sections = [];
		}

		this.state = {
			selectedUsers: [],
			loadingSection: null,
			selectedSection: null,
			loading: true,
			sections
		};
	}

	componentDidMount = () => {
		if (this.props.mentorGroups && ((this.props.mentorSections != null && this.props.mentorSections.length == 0) || this.props.mentorSections == null)) {
			this.props.getMentorGroups().then(() => {
				this.setState({ loading: false, sections: JSON.parse(JSON.stringify(this.props.mentorSections)) });
			});
		} else {
			if (this.state.sections != null && this.state.sections.length > 0) {
				this.setState({ loading: false });
			}
		}
	}

	getSection = (section, recursive) => {
		this.setState({ loadingSection: section.id });
		this.props.getSectionGuardians(section.id).then(() => {
			this.setState({ loadingSection: null });
			if (recursive) {
				this.onSectionSelect(section);
			}
		});
	}

	componentDidUpdate() {
		this.props.onUpdate(this.state.selectedUsers);
	}

	onSectionSelect = (section) => {
		let mSection = this.props.guardians.find(t => t.id == section.id);

		if (mSection == null) {
			this.getSection(section, true);
		} else {
			if (section.selected) {
				section.selected = false;
				let selectedUsers = this.state.selectedUsers.filter(t => t.sectionId != section.id);
				this.setState({ selectedUsers: selectedUsers, selectedSection: null });
			} else {
				section.open = true;
				section.selected = true;
				let students = [];
				mSection.students.forEach(student => {
					if (this.props.currentSelected != null) {
						// If student id is in currentSelected, dont set this student as active
						if (this.props.currentSelected.find(t => t == student.userId) != null) {
							return null;
						}
					}

					let obj = {
						studentId: student.id,
						sectionId: section.id,
						guardians: student.guardians,
						selected: true
					}

					students.push(obj);
				});

				this.setState({
					selectedUsers: [...this.state.selectedUsers.filter(t => t.sectionId != section.id), ...students],
					selectedSection: section.id,
				});
			}
		}
	}

	onSectionToggle = (section) => {
		section.open = !section.open;

		let mSection = this.props.guardians.find(t => t.id == section.id);

		if (mSection == null) {
			this.getSection(section);
		}

		this.setState({});
	}

	onStudentSelect = (student, section) => {
		let findStudent = this.state.selectedUsers.find(t => t.studentId == student.id && t.sectionId == section.id);
		if (findStudent == null) {
			let obj = {
				studentId: student.id,
				sectionId: section.id,
				guardians: student.guardians,
				selected: true
			}

			this.setState({ selectedUsers: [...this.state.selectedUsers, obj] });
		} else {
			findStudent.selected = !findStudent.selected;
			if (findStudent.selected == false && (findStudent.guardians == null || findStudent.guardians.length == 0)) {
				this.setState({
					selectedUsers: [...this.state.selectedUsers.filter((t) => {
						return (t.studentId == student.id && t.sectionId == section.id) == true ? false : true;
					})]
				});
			} else {
				this.setState({
					selectedUsers: [...this.state.selectedUsers.filter((t) => {
						return (t.studentId == student.id && t.sectionId == section.id) == true ? false : true;
					}), findStudent]
				});
			}
		}
	}

	onGuardianSelect = (student, guardian, section) => {
		let findStudent = this.state.selectedUsers.find(t => t.studentId == student.id && t.sectionId == section.id);
		if (findStudent == null) {
			let obj = {
				studentId: student.id,
				sectionId: section.id,
				guardians: [...guardian],
				selected: false
			}

			this.setState({ selectedUsers: [...this.state.selectedUsers, obj] });
		} else {
			let findGuardian = null;
			if (findStudent != null) {
				findGuardian = findStudent.guardians.find(t => t.id == guardian.id);
			}

			if (findGuardian != null) {
				findStudent.guardians = [...findStudent.guardians.filter((t) => {
					return (t.id == guardian.id) == true ? false : true;
				})];

				if (findStudent.selected == false && findStudent.guardians.length == 0) {
					this.setState({
						selectedUsers: [...this.state.selectedUsers.filter((t) => {
							return (t.studentId == student.id && t.sectionId == section.id) == true ? false : true;
						})]
					});
				} else {
					this.setState({
						selectedUsers: [...this.state.selectedUsers.filter((t) => {
							return (t.studentId == student.id && t.sectionId == section.id) == true ? false : true;
						}), findStudent]
					});
				}
			} else {
				if (findStudent.guardians != null) {
					findStudent.guardians = [...findStudent.guardians, guardian];
				}

				this.setState({
					selectedUsers: [...this.state.selectedUsers.filter((t) => {
						return (t.studentId == student.id && t.sectionId == section.id) == true ? false : true;
					}), findStudent]
				});
			}
		}
	}

	renderStudents = (section) => {
		let mSection = this.props.guardians.find(t => t.id == section.id);
		let rendered = 0;

		let students = mSection.students
		.sort((a, b) => (a.lastName || "").localeCompare(b.lastName || ""))
		.map((student) => {
			if (this.props.currentSelected != null) {
				if (this.props.currentSelected.find(t => t == student.userId) != null) {
					return null;
				}
			}

			rendered++;
			var findStudent = this.state.selectedUsers.find(t => t.studentId == student.id && t.sectionId == section.id);
			return (
				<div key={student.id}>
					<Checkbox
						onChange={() => this.onStudentSelect(student, section)}
						value={findStudent != null ? findStudent.selected : false}
						label={
							<DisplayName
							firstName={student.firstName}
							lastName={student.lastName}
							email={student.email}
							data={this.props.guardians.flatMap(x=> x.students)}
					/>
						}
					/>

					<div style={{ marginTop: '0.45rem', marginLeft: '1rem' }}>
						{student.guardians != null ? 
						student.guardians.sort((a, b) => (a.lastName || "").localeCompare(b.lastName || "")).map(guardian => {
							var findGuardian = null;
							if (findStudent != null) {
								if (findStudent.guardians != null) {
									findGuardian = findStudent.guardians.find(t => t.id == guardian.id);
								}
							}

							return <Checkbox
								key={guardian.id}
								disabled={this.state.selectedSection != null && this.state.selectedSection != section.id}
								onChange={() => this.onGuardianSelect(student, guardian, section)}
								value={findGuardian != null}
								label={guardian.firstName + " " + (guardian.lastName != null ? guardian.lastName : '')}
							/>
						}) : null}
					</div>
				</div>
			)
		});

		if (rendered == 0) {
			return <div>All students are selected or no students found</div>
		} else {
			return students;
		}
	}

	renderSection = (section) => {
		if (this.props.sectionId != null) {
			if (section.id != this.props.sectionId) {
				return null;
			}
		}
		const title = (
			<div>
				<span onClick={e => e.stopPropagation()}>
					<Checkbox
						value={section.selected}
						onChange={() => { this.onSectionSelect(section) }}
						label={section.title}
						disabled={this.state.selectedSection != null && this.state.selectedSection != section.id}
					/>
				</span>
			</div>
		);

		return (
			<Expandable
				disabled={this.state.selectedSection != null}
				open={section.open}
				title={title}
				key={section.id}
				onClick={() => this.onSectionToggle(section)}
				onPlus={() => {
					this.onSectionToggle(section)
				}}
			>
				{this.state.loadingSection == section.id ?
					<Spinner small center />
					: null}

				{section.open && this.state.loadingSection != section.id ?
					this.renderStudents(section)
					: null}
			</Expandable>
		);
	}

	renderSections = () => {
		return this.state.sections.map((section) => {
			return this.renderSection(section);
		});
	}

	render() {
		if (this.state.loading) {
			return <Spinner center />
		}
		return (
			<div className="user-select studentGuardian">
				<BlockContent style={{ position: 'relative', paddingTop: 0, paddingBottom: 0, }}>
					{this.renderSections()}
				</BlockContent>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		educationSections: state.sections.educationGroups,
		mentorSections: state.sections.mentorGroups,
		activeSection: state.sections.activeSection,
		guardians: state.sections.guardians,
	};
}

export default connect(mapStateToProps, {
	getSection,
	getSectionGuardians,
	getMentorGroups
})(StudentGuardianSelector);
