import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Spinner } from 'UI';
import { Icon, Button } from '@haldor/ui';
import './Styles.scss';

class WopiFrame extends Component {

	constructor(props) {
		super(props);

		this.state = {
			fullscreen: false,
		};

		this.container = null;
		this.trigger = null;
		this.iframe = null;
	}

	_setContainer = (element) => this.container = element;
	_setTrigger = (element) => this.trigger = element;
	_setIframe = (element) => this.iframe = element;

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.calculateIframeHeight);
	}

	componentDidMount = () => {
		this.calculateIframeHeight();

		window.addEventListener('resize', this.calculateIframeHeight);
	}

	calculateIframeHeight = () => {
		if (this.state.fullscreen) {
			if (this.iframe != null) {
				const triggerHeight = this.trigger.scrollHeight;
				this.iframe.style.height = `calc(${window.innerHeight}px - ${triggerHeight}px)`;
			}

			return false;
		}

		if (this.iframe != null) {
			let height = 0;
			const body = document.querySelector('body');
			const footer = body.querySelector('.site-footer');
			const header = body.querySelector('.teams-header');
			const desktopHeader = body.querySelector('.desktop-header-spacing');

			height = window.innerHeight;

			if (footer != null) {
				height = height - footer.scrollHeight
			}

			if (header != null) {
				height = height - header.scrollHeight;
			}

			if (desktopHeader != null) {
				height = height - desktopHeader.scrollHeight;
			}

			if (window.innerHeight < 800) {
				height = window.innerHeight;
			}

			if (window.innerHeight < 450) {
				height = 450;
			}

			this.iframe.style.height = height + 'px';
		}
	}

	onFullscreenTrigger = async (event) => {
		event.preventDefault();

		await this.setState({ fullscreen: !this.state.fullscreen });

		this.toggleFullscreen();
	}

	toggleFullscreen = () => {
		const body = document.body;

		if (this.state.fullscreen) {
			this.container.style.position = 'fixed';
			this.container.style.top = '0';
			this.container.style.left = '0';
			this.container.style.height = '100%';
			this.container.style.width = '100%';
			this.container.style.zIndex = '9999999999999999999';
			this.container.style.background = 'white';

			if (this.iframe != null) {
				const triggerHeight = this.trigger.scrollHeight;
				this.iframe.style.height = `calc(${window.innerHeight}px - ${triggerHeight}px)`;
			}

			let menu = body.querySelector('.c--sider');
			if (menu != null) {
				menu.style.display = 'none';
			}

			let header = body.querySelector('.desktop-header');
			if (header != null) {
				header.style.display = 'none';
			}

			body.style.overflow = 'hidden';
		} else {
			let menu = body.querySelector('.c--sider');
			if (menu != null) {
				menu.style.display = 'block';
			}

			let header = body.querySelector('.desktop-header');
			if (header != null) {
				header.style.display = 'flex';
			}

			this.container.style = {};
			this.calculateIframeHeight();
			body.style.overflow = 'initial';
		}
	}

	render() {
		var selectedFiles = this.props.folderFiles.filter(ss => { return ss.webUrlEditMode == this.props.activeFile || ss.webUrlViewMode === this.props.activeFile });

		if (selectedFiles && selectedFiles.length > 0) {
			var webURL = selectedFiles[0].webUrl;
		}

		const iframe = `
			<iframe
				src="${this.props.activeFile + '&wdorigin=TEAMS-ASSIGN-WEB.ASSIGN-STUD-VIEW.STUD'}"
				title="File"
				class="iframe-element"
				allow="camera;microphone"
				style="width: 100%;height:100%;"
			/>
		`;

		return (
			<div className="wopi-frame">
				<div className="iframe-container" ref={this._setContainer}>
					<div className="fullscreen-trigger" ref={this._setTrigger}>
						<span style={{ cursor: 'pointer' }} onClick={this.onFullscreenTrigger}>
							{this.state.fullscreen ?
								<Icon name="Collapse" />
								:
								<Icon name="Expand" />
							}

							{this.props.translate('fullscreen')}
						</span>
					</div>

					{this.props.activeFile && this.props.activeFile.length > 2001 ?
						<div>
							<div className="warning-box">
								<div style={{ alignSelf: 'flex-start' }}>
									<Icon name="Info_Blue" />
								</div>

								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<p>
										{this.props.translate("We’re sorry but we can’t display the file in Haldor at the moment. But you can open it by clicking “Open file in workspace”.")}
									</p>

									<br />

									<p>
										{this.props.translate("When you’re ready to submit the file, just click “Submit” as usual.")}
									</p>
								</div>
							</div>

							<div className="center-container" style={{ marginTop: 20, position: 'relative' }}>
								<a target="_blank" style={{ display: 'inline' }} href={webURL}>
									<Button type="secondary">
										<Icon name="External" />
										{this.props.translate("open-file-in-workspace")}
									</Button>
								</a>
							</div>
						</div>
						:
						<div>
							<Spinner center />
							<div
								ref={this._setIframe}
								dangerouslySetInnerHTML={{ __html: iframe }}
							/>
						</div>
					}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		folderFiles: state.assignments.folderFiles,
	};
}

export default connect(mapStateToProps)(WopiFrame);
