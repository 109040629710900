import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { addError } from 'actions';
import { getUserCourses } from 'actions/user';
import {
	clearVerdicts,
	getAssignmentAssessments,
	getPlanAssessments,
	getStudentLatestVerdict,

	resetPlanAssessments,
	resetAssignmentAssessments,
	getVerdictsForUser,
	getStudentVerdicts,
	getVerdictScale,
} from 'actions/verdicts';

import DateTime from '_class/DateTime';

import api from 'lib/api';
import Modal from 'containers/Modals/Modal';
import UserAssessments from './UserAssessments';
import VerdictDisplay from 'containers/Verdict/Display/VerdictDisplay';
import VerdictWidget from 'containers/Verdict/Display/VerdictWidget';

import RowItem, { RowCell } from 'UI/Elements/List/RowItem';
import { Collapsible, ColorLabel, ColorLabelContainer, DataList, Skeleton, Spinner, Person } from 'UI';
import { Icon, TooltipMenu, Checkbox, Button, translate } from '@haldor/ui';

import './_mentorVerdicts.scss';
import User from '_class/User';

class MentorVerdicts extends Component {

	/* Class setup */
	constructor(props) {
		super(props);

		this.state = {
			verdictModal: false,
			verdictModalLoading: false,
			verdictLoading: false,
			loading: false,
			selectedCourses: [],
			course: null,
			open: [],
			start: this.props.start,
			end: this.props.end,
		};
	}

	/* Lifecycle methods */
	componentDidMount = () => {
		this.setState({ loading: true });

		let promises = [];


		if (this.props.verdictScale == null) {
			promises.push(this.props.getVerdictScale());
		}

		const start = moment(moment(this.props.start).startOf('day'), 'YYYY-MM-DD HH:mm:ss').utc().format();
		const end = moment(moment(this.props.end).endOf('day'), 'YYYY-MM-DD HH:mm:ss').utc().format();

		promises.push(this.props.getStudentVerdicts(this.props.userId, start, end));

		Promise.all(promises).then(() => {
			this.props.userCourses.forEach(course => {
				if (course.verdicts == null) {
					return false;
				}
			});

			this.setState({ loading: false, start: this.props.start, end: this.props.end });
		});
	}

	componentDidUpdate = () => {
		if (this.props.start != this.state.start || this.props.end != this.state.end) {
			this.setState({ loading: true, start: this.props.start, end: this.props.end });

			this.props.getStudentVerdicts(this.props.userId, this.props.start, this.props.end)
				.then(() => {
					this.props.userCourses.forEach(course => {
						if (course.verdicts == null) {
							return false;
						}
					});

					this.setState({ loading: false });
				});
		}
	}

	componentWillUnmount = () => {
		this.props.clearVerdicts();
	}

	/* Events */
	toggleVerdictModal = () => {
		this.setState({ verdictModal: !this.state.verdictModal });
	}

	toggleOpen = (course, active) => {
		let { open } = this.state;
		let found = this.state.open.indexOf(course.id);

		if (active && found == -1) {
			open.push(course.id);
		}

		if (!active && found > -1) {
			open.splice(found, 1);
		}

		this.setState({ open });
	}

	toggleOpenAll = () => {
		if (this.state.open.length > 0) {
			this.setState({ open: [] });
		} else {
			const { group } = this.props;
			this.setState({ open: [...this.props.userCourses].map(course => course.id) })
		}
	}


	onCourseClick = (course) => {
		this.setState({ verdictLoading: true, course });

		var promises = [];

		this.props.resetAssignmentAssessments();
		this.props.resetPlanAssessments();

		promises.push(this.props.getPlanAssessments(this.props.userId, course.id));
		promises.push(this.props.getVerdictsForUser(this.props.userId, course.id, this.props.start, this.props.end));
		promises.push(this.props.getAssignmentAssessments(this.props.userId, course.id));

		Promise.all(promises)
			.then(() => {
				this.setState({ verdictLoading: false });
			});


		this.toggleVerdictModal();
	}

	onRequestVerdict = (course) => {
		const { translate } = this.props;

		api.post(`verdict/notify?studentId=${this.props.userId}&courseId=${course.id}`, {})
			.then((response) => {
				this.props.addError(translate('request-verdict-sucess'), 'info');
			})
			.catch((error) => {
				var message = translate('something-went-wrong');
				if (error.response.data != null && error.response.data.errorCode == 'H-003') {
					message = translate('request-verdict-teacher-error');
				}
				this.props.addError(message, 'error');
			});
	}

	requestVerdictForSelectedCourses = () => {
		this.state.selectedCourses.forEach(course => {
			this.onRequestVerdict(course);
		});
	}

	toggleAllCourses = (checked, courses) => {
		if (checked) {
			this.setState({ selectedCourses: courses });
		} else {
			this.setState({ selectedCourses: [] });
		}
	}

	onCourseSelect = (checked, course) => {
		let { selectedCourses } = this.state;

		if (checked) {
			selectedCourses.push(course);
		} else {
			let found = selectedCourses.findIndex(selectedCourse => {
				return selectedCourse.id == course.id;
			});

			if (found > -1) {
				selectedCourses.splice(found, 1);
			}
		}

		this.setState({ selectedCourses });
	}

	onVerdictSubmit = (verdict, course) => {
		var promises = [];
		promises.push(this.props.getVerdictsForUser(this.props.userId, course.id, this.props.start, this.props.end));
		promises.push(this.props.getStudentVerdicts(this.props.userId, this.props.start, this.props.end));

		Promise.all(promises).then(() => {
			this.setVerdictModalLoading(false);
		})
	}

	setVerdictModalLoading = (loading) => {
		this.setState({ verdictModalLoading: loading });
	}

	/* Render methods */
	renderVerdict = (verdict, index) => {
		if (verdict == null) {
			return null;
		}

		let date = this.props.tenant.currentSchoolYear != null ? this.props.tenant.currentSchoolYear : '2017-01-01';
		let start = new DateTime(date).getDateStamp();
		let end = new DateTime(verdict.published).getDateStamp();

		let verdictBefore = this.props.userVerdicts[index + 1];
		if (verdictBefore != null) {
			start = new DateTime(verdictBefore.published).getDateStamp();
		}

		const trigger = (
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<div style={{ flexGrow: 1 }}>
					{new DateTime(verdict.published).getLongDateWithTime()}
				</div>

				<div style={{ flexGrow: 0 }}>
					<VerdictWidget verdict={verdict} />
				</div>
			</div>
		);

		return <div className="verdict verdict-entry form-row" key={verdict.id}>
			<Collapsible trigger={trigger}>
				<VerdictDisplay
					verdict={verdict}
					course={this.state.course}
					isStudent={this.props.isStudent}
					reload={() => this.onVerdictSubmit(verdict, this.state.course)}
					setLoading={this.setVerdictModalLoading}
				/>
			</Collapsible>
		</div>
	}

	renderCurrentVerdict = (verdict) => {
		if (verdict == null) {
			return null;
		}

		return (
			<div className="verdict verdict-entry form-row">
				<div style={{ marginBottom: '1rem' }}>
					<h3>{this.props.translate('current-verdict')}</h3>
				</div>

				<VerdictDisplay
					verdict={verdict}
					isStudent={this.props.isStudent}
					course={this.state.course}
					reload={() => this.onVerdictSubmit(verdict, this.state.course)}
					setLoading={this.setVerdictModalLoading}
				/>
			</div>
		);
	}

	renderVerdictsModal = () => {
		let currentVerdict = null;
		let verdicts = null;
		if (this.props.userVerdicts != null && this.props.userVerdicts.length > 0) {
			let publishedVerdicts = this.props.userVerdicts.filter(v => v.status == "VERDICT_PUBLISHED");
			currentVerdict = publishedVerdicts[0];
			verdicts = [...publishedVerdicts];
			verdicts.splice(0, 1);
		}

		return (
			<div>
				{currentVerdict != null && !this.state.verdictLoading ?
					this.renderCurrentVerdict(currentVerdict)
					: null}

				<div className="form-row" style={{ marginBottom: '1rem' }}>
					<h3>{this.props.translate('past-verdicts')}</h3>
				</div>

				{this.state.verdictLoading ?
					<Spinner center />
					: verdicts != null && verdicts.length != 0 ?
						verdicts.map(this.renderVerdict)
						:
						<div style={{ textAlign: 'center', marginBottom: '2rem' }}>
							{this.props.translate('no-previous-verdicts')}
						</div>
				}
			</div>
		);
	}

	renderPublishedInformation = (verdict) => {
		if (new Date(verdict.published) < new Date(verdict.edited)) {
			return (
				<div className="info-draft">
					<span className="draft-badge" style={{}}>
						{this.props.translate('published') + ": " + new DateTime(verdict.published).getTimeStamp()}
					</span>

					<span className="draft-badge" style={{ marginLeft: '5px' }}>
						{this.props.translate('edited') + ": " + new DateTime(verdict.edited).getTimeStamp()}
					</span>
				</div>
			)
		} else {
			return (
				<div className="info-draft">
					<span className="draft-badge" style={{}}>
						{this.props.translate('published') + ": " + new DateTime(verdict.published).getTimeStamp()}
					</span>
				</div>
			)
		}
	}

	renderCourseRow = (course) => {
		const triggerButton = (
			<Button type="secondary" style={{ marginLeft: 0 }}>
				{"⋯"}
			</Button>
		);

		let found = this.state.selectedCourses.findIndex(selectedCourse => {
			return selectedCourse.id == course.id;
		});

		let open = false;
		if (this.state.open.indexOf(course.id) > -1) {
			open = true;
		}

		let verdict = course.verdicts.find(verdict => {
			return verdict.courses.find(co => {
				return co.courseID == course.id;
			});
		});

		let verdictValid = false;
		let feedback = '';

		if (verdict == null) return null;

		const verdictPublished = moment.utc(verdict.published).local();

		if (verdictPublished.isBetween(moment(this.state.start).startOf('day'), moment(this.state.end).endOf('day'))) {
			verdictValid = true;
		}

		if (verdict.feedback != null) {
			feedback = verdict.feedback.replace(/(\r\n|\r|\n)/g, '<br />');
		}


		const user = new User(this.props.currentUser);

		var showAllCourses = user.isMentor();
		if (!showAllCourses && verdict == null) {
			return null;
		}

		let collapsibleTrigger = (
			<div style={{ display: 'flex' }}>
				<ColorLabelContainer style={{ flex: 1 }}>
					<ColorLabel
						content={course.title + " " + (course.year != null ? course.year : '')}
						color={course.colorCode}
					/>
				</ColorLabelContainer>

				{verdict != null && verdictValid ?
					<VerdictWidget verdict={verdict} />
					: course.loading ?
						<Skeleton />
						: null}

				{verdict != null && verdictValid ?
					<span style={{ marginLeft: '.45rem' }}>
						{new DateTime(verdict.published).getLongDate()}
					</span>
					: null}
			</div>
		)

		return (
			<RowItem className="row" key={course.id}>
				<RowCell shrink title=" ">
					<div className="no-print">
						{!this.props.isStudent ?
							<Checkbox value={found > -1} onChange={c => this.onCourseSelect(c, course)} />
							: null}
					</div>
				</RowCell>

				<RowCell title="  ">
					<Collapsible open={open} onChange={(open) => this.toggleOpen(course, open)} trigger={collapsibleTrigger}>
						{verdict != null ?
							<div className={`verdict-feedback-row${!open ? ' no-print' : ''}`}>
								{verdict.createdBy != null ?
									<Person style={{ display: 'inline-flex' }} person={verdict.createdBy}>
										<span
											dangerouslySetInnerHTML={{ __html: feedback }}
										/>
									</Person>
									:
									<span
										dangerouslySetInnerHTML={{ __html: feedback }}
									/>
								}

								<VerdictWidget verdict={verdict} />
							</div>
							: null}
					</Collapsible>
				</RowCell>

				<RowCell shrink title="   ">
					<Button type="secondary" onClick={() => this.onCourseClick(course)}>
						{this.props.translate('show-verdicts')}
					</Button>
				</RowCell>

				{!this.props.isStudent && user.isMentor() ?
					<RowCell shrink title="    ">
						<TooltipMenu trigger={triggerButton} id={course.id}>
							<TooltipMenu.Item onClick={() => this.onCourseClick(course)}>
								{this.props.translate('show-verdicts')}
							</TooltipMenu.Item>

							{!this.props.isStudent ?
								<TooltipMenu.Item onClick={() => this.onRequestVerdict(course)}>
									{this.props.translate('request-verdict')}
								</TooltipMenu.Item>
								: null}
						</TooltipMenu>
					</RowCell>
					: null}
			</RowItem>
		);
	}

	render() {
		if (this.state.loading)
			return <Spinner center />

		const { selectedCourses, verdictModalLoading } = this.state;

		let modalTitle = this.props.translate('verdicts');
		if (this.props.user != null) {
			modalTitle = this.props.translate('Verdicts for') + ' ' + this.props.user.firstName + ' ' + this.props.user.lastName;
		}

		const open = this.state.open.length > 0;
		let user = new User(this.props.currentUser);

		return (
			<div className="mentor-verdicts">
				<Modal
					isOpen={this.state.verdictModal}
					onClose={this.toggleVerdictModal}
					title={modalTitle}
				>
					{verdictModalLoading ? <Spinner center /> : this.renderVerdictsModal()}
				</Modal>


				<DataList
					data={this.props.userCourses}
					renderRow={this.renderCourseRow}
					toggleAllEntries={!this.props.isStudent ? c =>
						this.toggleAllCourses(c, this.props.userCourses)
						: null}
					actions={!this.props.isStudent && user.isMentor() ? () => (
						<div className="df aic jcb">
							<div onClick={this.toggleOpenAll} className={open ? "color--meta toggle-all flip" : "color--meta toggle-all"}>
								<Icon name="ArrowLeft" />
								{open ?
									this.props.translate('Close all')
									: this.props.translate('Show all')}
							</div>

							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div className="no-print" style={{ marginRight: '0.75rem' }}>
									<div onClick={() => window.print()} style={{ cursor: 'pointer' }} className="color--meta">
										<span style={{ marginRight: '0.25rem', position: 'relative', top: 2 }}>
											<Icon name="Printer" />
										</span>

										{this.props.translate("print")}
									</div>
								</div>

								<Button type="primary" onClick={this.requestVerdictForSelectedCourses} disabled={selectedCourses.length == 0}>
									{this.props.translate('request-verdict')}
								</Button>
							</div>
						</div>
					) : null}
				/>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		userVerdicts: state.Verdicts.user,
		userCourses: state.user.userCourses,
		lastVerdicts: state.Verdicts.lastVerdicts,
		verdictScale: state.Verdicts.scale,
		currentUser: state.user.currentUser,
		tenant: state.user.tenant,
	};
}

export default connect(mapStateToProps, {
	getVerdictsForUser,
	getUserCourses,
	getStudentLatestVerdict,
	getStudentVerdicts,
	clearVerdicts,
	resetPlanAssessments,
	addError,
	resetAssignmentAssessments,
	getPlanAssessments,
	getAssignmentAssessments,
	getVerdictScale,
})(MentorVerdicts);