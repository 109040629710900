import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from '@haldor/ui';
import swal from 'sweetalert2';

import {
	getBaseGroups,
	deleteBaseGroup,
	getBaseGroup,
	updateBaseGroup,
	createBaseGroup,
	clearActiveBaseGroup,
	setActiveBaseGroup,
} from 'actions/basegroups';

import BaseGroupForm from 'containers/BaseGroups/Form/BaseGroupForm';

import { Button, Checkbox, Flex, Select, Icon } from '@haldor/ui';
import { Spinner } from 'UI';
import DisplayName from 'components/Presentation/DisplayName';

class AssignmentTaskSelect extends Component {

	/* Class setup */
	static defaultProps = {
		copyMode: false,
		groupEnabled: false,
	};

	constructor(props) {
		super(props);

		let tasks = [];
		let editing = false;
		if (props.input.value != null && props.input.value !== '') {
			tasks = JSON.parse(JSON.stringify(props.input.value));
			tasks.forEach((task, index) => {
				return task.index = index;
			});

			editing = true;
		}

		this.state = {
			tasks,
			activeStudents: [],
			units: [],
			selectedBaseGroup: '',
			loading: false,
		};

		this.editing = editing;
		this.groupAssignment = this.props.groupEnabled;
	}

	/* Lifecycle methods */
	componentDidMount = () => {
		if (this.props.section != null) {
			this.props.getBaseGroups(this.props.section?.id);
		}

		if (this.props.input != null && this.state.tasks.length > 0) {
			this.props.input.onChange(this.state.tasks);
		}
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		if (nextProps.section != null && nextProps.section != this.props.section) {
			this.props.getBaseGroups(nextProps.section?.id);

			if (this.props.copyMode && this.props.section?.id != nextProps.section?.id) {
				this.props.clearActiveBaseGroup();
				let tasks = [];

				this.setState({ tasks: tasks, units: [], selectedBaseGroup: '' }, () => {
					if (this.props.input != null) {
						this.props.input.onChange(tasks);
					}
				});
			}
		}

		if (nextProps.groupEnabled != null && nextProps.groupEnabled != this.groupAssignment) {
			this.groupAssignment = nextProps.groupEnabled;
			this.props.clearActiveBaseGroup();
			this.setState({ tasks: [], units: [], selectedBaseGroup: '' }, () => {
				if (this.props.input != null) {
					this.props.input.onChange(this.state.tasks);
				}
			});
		}
	}

	componentWillUnmount = () => {
		this.props.clearActiveBaseGroup();
	}

	/* Helper methods */
	_loadTasksAsUnits = () => {
		let units = [];
		this.state.tasks.forEach(task => {
			let unit = {
				title: task.groupName,
				memberships: [],
			};

			task.students.forEach(student => {
				unit.memberships.push({
					userId: student.userId,
					user: student,
				});
			});

			units.push(unit);
		});

		if (units.length > 0) {
			this.props.setActiveBaseGroup({
				title: '',
				units: units,
			});
		}
	}

	/* Event methods */
	onBaseGroupSelect = async (event) => {
		if (event.target.value == null || event.target.value == '') {
			this.setState({ selectedBaseGroup: '' });
			return false;
		}

		this.setState({ loading: true, selectedBaseGroup: event.target.value });
		await this.props.getBaseGroup(event.target.value);

		let activeStudents = [];
		this.props.active?.units?.forEach(unit => {
			unit.memberships?.forEach(membership => {
				activeStudents.push(membership?.user);
			});
		});

		this.setState({ loading: false, activeStudents, units: this.props.active?.units }, () => {
			this.onUpdate();
		});
	}

	onBaseGroupSubmit = (event) => {
		const { translate, section } = this.props;
		event.preventDefault();

		return new Promise((resolve) => {
			let values = {
				units: this.state.units,
				sectionId: section.id,
			};

			swal.fire({
				title: translate('name-basegroup'),
				text: translate('name-basegroup-explain'),
				input: 'text',
				showCancelButton: true,
				cancelButtonText: translate('Cancel'),
				confirmButtonText: translate('save'),
			}).then(async (response) => {
				if (response.value != null) {
					values.title = response.value;

					if (values.id == null) {
						await this.props.createBaseGroup(values);
					} else {
						await this.props.updateBaseGroup(values);
					}

					resolve(1);
				}
			});
		});
	}

	onBaseGroupUpdate = (units) => {
		this.setState({ units }, () => {
			this.onUpdate();
		});
	}

	onStudentSelect = (student) => {
		let { activeStudents } = this.state;
		let studentIndex = this.state.activeStudents.findIndex(activeStudent => {
			return activeStudent.userId == student.userId;
		});

		if (studentIndex > -1) {
			activeStudents.splice(studentIndex, 1);
		} else {
			activeStudents.push(student);
		}

		this.setState({ activeStudents }, () => {
			this.onUpdate();
		});
	}

	onUpdate = () => {
		let tasks = [];

		this.state.units.forEach((unit) => {
			let task = {
				groupName: unit.title,
				assignedTo: '',
				students: [],
			};

			unit.memberships?.forEach(member => {
				task.assignedTo = task.assignedTo + member.userId + ';';
				task.students.push(member.user);
			});

			tasks.push(task);
		});

		if (this.props.input != null) {
			this.props.input.onChange(tasks);
		}
	}

	clearSelection = () => {
		this.props.clearActiveBaseGroup();

		this.setState({ units: [], activeStudents: [], selectedBaseGroup: '' });
	}

	toggleTaskDeletion = (task) => {
		let tasks = JSON.parse(JSON.stringify(this.state.tasks));

		tasks = tasks.map(t =>
			t.id === task.id ? { ...t, markedForDeletion: !t.markedForDeletion } : t
		);

		this.setState({ tasks }, () => {
			if (this.props.onChange != null) {
				this.props.onChange(tasks);
			}

			if (this.props.input != null) {
				this.props.input.onChange(tasks);
			}
		});
	}

	editTaskTitle = (task) => {
		const { translate } = this.props;
		let { tasks } = this.state;

		swal.fire({
			title: translate('edit-name'),
			text: translate('name'),
			input: 'text',
			inputValue: task.groupName,
			showCancelButton: true,
			cancelButtonText: translate('Cancel'),
			confirmButtonText: translate('save'),
			preConfirm: (value) => {
				if (value.length > 50) {
					return swal.showValidationMessage(translate('Group name can not be longer than 50 characters'));
				}

				return undefined;
			},
		}).then(response => {
			if (response.value != null) {
				task.groupName = response.value;

				this.setState({ tasks }, () => {
					this.onUpdate();
					if (this.props.input != null) {
						this.props.input.onChange(tasks);
					}
				});
			}
		});
	}

	removeTaskObject = (taskIndex) => {
		let { tasks } = this.state;
		const task = tasks.find(task => task.index == taskIndex);

		if (task != null) {
			if (task.id != -1) {
				task.markedForDeletion = !task.markedForDeletion;
			} else {
				tasks.splice(1, taskIndex);
			}

			task.students.forEach((student) => {
				this.removeStudentFromTask(student.id, taskIndex);
			});

			this.setState({ tasks }, () => {
				if (this.props.input != null) {
					this.props.input.onChange(this.state.tasks);
				}
				if (this.props.onChange != null) {
					this.props.onChange(this.state.tasks);
				}
			});
		}
	}

	removeStudentFromTask = async (studentId, taskIndex) => {
		let { tasks } = this.state;

		let task = tasks.find(task => task.index == taskIndex);

		if (task != null) {
			if (task.assignedTo.indexOf(studentId) > -1) {
				task.assignedTo = task.assignedTo.replace(studentId + ';', '');
				task.assignedTo = task.assignedTo.replace(studentId, '');

				let studentsArr = task.students.filter(x => x.userId != studentId);
				task.students = studentsArr;
			}

			this.setState({ tasks }, () => {
				this.onUpdate();
				if (this.props.input != null) {
					this.props.input.onChange(this.state.tasks);
				}
				if (this.props.onChange != null) {
					this.props.onChange(this.state.tasks);
				}
			});

		}
	}

	addStudentAsTask = (student) => {
		let tasks = JSON.parse(JSON.stringify(this.state.tasks));

		let studentTask = tasks.find(task => {
			return task.assignmentTaskAssignees.some(assignee => assignee.assigneeId === student.userId);
		});

		if (studentTask) {
			tasks = tasks.map(t =>
				t.id === studentTask.id ? { ...t, markedForDeletion: false } : t
			);
		} else {
			tasks.push({
				groupName: student.firstName + ' ' + student.lastName,
				assignedTo: student.userId,
				students: [student],
				id: -1,
				index: this.state.tasks.length + 1,
				assignmentTaskAssignees: [{
					assignee: student,
					assigneeId: student.userId
				}]
			});
		}

		this.setState({ tasks });

		if (this.props.input != null) {
			this.props.input.onChange(tasks);
		}

		if (this.props.onChange != null) {
			this.props.onChange(tasks);
		}
	}

	addStudentOnTask = (student, taskIndex) => {
		let { tasks } = this.state;

		let task = tasks.find(task => task.index == taskIndex);
		if (task != null) {
			if (task.assignedTo != null) {
				if (!task.assignedTo.endsWith(';')) {
					task.assignedTo = task.assignedTo + ';';
				}

				task.assignedTo = task.assignedTo + student.userId + ';';
			} else {
				task.assignedTo = student.userId + ';';
			}

			task.students.push(student);
		}

		this.setState({ tasks }, () => {
			this.onUpdate();

			if (this.props.input.onChange != null) {
				this.props.input.onChange(this.state.tasks);
			}

			if (this.props.onChange != null) {
				this.props.onChange(this.state.tasks);
			}
		});
	}

	addTaskGroup = (e) => {
		e.preventDefault();

		let { tasks } = this.state;

		let largest = 1;
		tasks.forEach(task => {
			const num = parseInt(task.groupName.replace(/^\D+/g, ''));
			if (num > largest) {
				largest = num;
			}
		});

		let task = {
			groupName: this.props.translate('group') + ' ' + (largest + 1),
			assignedTo: '',
			students: [],
			id: -1,
			index: this.state.tasks.length,
		};

		tasks.push(task);

		this.setState({ tasks }, () => {
			this.onUpdate();
			if (this.props.input != null) {
				this.props.input.onChange(tasks);
			}
		});

		return tasks;
	}

	/* Render methods */
	renderEditMode = () => {
		const unassignedStudents = this.props.section.students.filter((student) => {
			let foundInTasks = this.state.tasks.find((task) => {
				if (task.assignedTo == null) {
					return false;
				}

				const foundInStudents = task.students.find(taskStudent => {
					return taskStudent.userId == student.userId;
				});

				return task.assignedTo.indexOf(student.userId) > -1 || foundInStudents != null;
			});

			return foundInTasks == null;
		}).sort((a, b) => (a.lastName || "").localeCompare(b.lastName || ""));

		let options = [];
		this.state.tasks.forEach(task => {
			if (!task.markedForDeletion) {
				options.push({
					value: task.index,
					label: task.groupName,
				});
			}
		});

		return (
			<div className="basegroups display">
				<Button type="secondary" onClick={this.addTaskGroup}>
					<Icon name="Plus" />
					{this.props.translate('new-group')}
				</Button>

				{this.state.tasks.sort((a, b) =>
					isNaN(parseInt(a.groupName)) || isNaN(parseInt(b.groupName)) ?
						a.groupName.localeCompare(b.groupName, undefined, { numeric: true })
						: parseInt(a.groupName) - parseInt(b.groupName)).map((task, index) => {
							if (task.markedForDeletion) {
								return null;
							}
							var assessments = task.assessmentTypeRelationships?.find(atr => atr.assessmentId != null);
							return <div key={task.id + '-' + index} className="unit">
								<span className="title">
									{assessments ?
										<Select
											onChange={(value) => {
												if (value == 'rename') {
													this.editTaskTitle(task);
												}
											}}
											options={[
												{
													value: 'rename',
													label: this.props.translate('edit-name'),
												}
											]}
										>
											{task.groupName}
										</Select>
										:
										<Select
											onChange={(value) => {
												if (value == 'remove') {
													this.removeTaskObject(task.index);
												}

												if (value == 'rename') {
													this.editTaskTitle(task);
												}
											}}
											options={[
												{
													value: 'rename',
													label: this.props.translate('edit-name'),
												},
												{
													value: 'remove',
													label: this.props.translate('Remove'),
												}
											]}
										>
											{task.groupName}
										</Select>}

								</span>

								{task.students
									.sort((a, b) => (a.lastName || "").localeCompare(b.lastName || ""))
									.map((student) => {
										return (
											<div key={student.userId}>
												<DisplayName
													firstName={student.firstName}
													lastName={student.lastName}
													email={student.email}
													data={this.props.section.students}
												/>

												<div className="float--right">
													<Select
														onChange={(value) => {
															this.removeStudentFromTask(student.id, task.index);

															if (value != 'remove') {
																this.addStudentOnTask(student, value)
															}
														}}
														options={[...options, {
															value: 'remove',
															label: this.props.translate('Remove'),
														}]}
													>
														{task.groupName}
													</Select>
												</div>

												<div className="clearfix" />
											</div>
										);
									})}
							</div>
						})}

				{unassignedStudents.length > 0 ?
					<div className="unit">
						<span className="title">
							{this.props.translate('unassigned-students')}
						</span>

						{unassignedStudents
							.map((student) => {
								return (
									<div key={student.userId}>
										<DisplayName
											firstName={student.firstName}
											lastName={student.lastName}
											email={student.email}
											data={this.props.section.students}
										/>

										<div className="float--right">
											<Select
												onChange={(value) => this.addStudentOnTask(student, value)}
												options={options}
											>
												{this.props.translate('select-group')}
											</Select>
										</div>

										<div className="clearfix"></div>
									</div>
								);
							})}
					</div>
					: null}
			</div>
		);
	}

	renderRegularEditMode = () => {
		const unassignedStudents = this.props.section.students.filter(student => {
			let studentTask = this.state.tasks.find(task => {
				return task.assignmentTaskAssignees.some(assignee => assignee.assigneeId === student.userId);
			});
			const deleted = studentTask != null ? studentTask.markedForDeletion : false;
			return studentTask == null || deleted;
		}).sort((a, b) => (a.lastName || "").localeCompare(b.lastName || ""));

		return (
			<div className="basegroups display">
				<div className="unit">
					<span className="title">
						{this.props.translate('assigned-students')}
					</span>

					{this.state.tasks
						.sort((a, b) => (a.students[0].lastName || "").localeCompare(b.students[0].lastName || ""))
						.map((task, index) => {
							if (task.markedForDeletion) {
								return null;
							}
							var assessments = task.assessmentTypeRelationships?.find(atr => atr.assessmentId != null);

							return (
								<div key={'task-' + index} >
									<div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: ".2rem" }}>
										<DisplayName
											firstName={task.students[0].firstName}
											lastName={task.students[0].lastName}
											email={task.students[0].email}
											data={this.props.section.students}
										/>
										<div className="float--right">
											{assessments ?
												<div style={{ cursor: 'not-allowed' }} >
													{this.props.translate('Remove')}
												</div>
												: <div style={{ cursor: 'pointer' }} onClick={(event) => this.toggleTaskDeletion(task)}>
													{this.props.translate('Remove')}
												</div>}
										</div>
									</div>
									<div className="clearfix"></div>
								</div>
							);
						})}
				</div>

				{unassignedStudents.length > 0 ?
					<div className="unit">
						<span className="title">
							{this.props.translate('unassigned-students')}
						</span>
						{unassignedStudents
							.map((student) => {

								return (
									<div key={student.userId}>
										<div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: ".2rem" }}>
											<DisplayName
												firstName={student.firstName}
												lastName={student.lastName}
												email={student.email}
												data={this.props.section.students}
											/>

											<div className="float--right">
												<div style={{ cursor: 'pointer' }} onClick={(event) => this.addStudentAsTask(student)}>
													{this.props.translate('Add')}
												</div>
											</div>
										</div>
										<div className="clearfix"></div>
									</div>
								);
							})}
					</div>
					: null}
			</div>
		);
	}

	render() {
		if (this.props.section == null) {
			return <div>
				{this.props.translate('select-a-group-to-continue')}
			</div>
		}

		if (this.editing && this.groupAssignment && !this.props.copyMode) {
			return this.renderEditMode();
		}

		if (this.editing && !this.groupAssignment) {
			return this.renderRegularEditMode();
		}

		const baseGroupFormActions = [
			{
				value: this.props.translate('save-subgroup'),
				onClick: this.onBaseGroupSubmit,
				type: 'secondary',
			},
		];

		return (
			<div className="basegroups select">
				<div className="form">
					<Flex center>
						<div style={{ flex: 1 }} className="select">
							<select
								style={{ padding: '.85rem 1rem' }}
								value={this.state.selectedBaseGroup}
								onChange={this.onBaseGroupSelect}
							>
								<option value=''>
									{this.props.translate('select-basegroup')}
								</option>

								{this.props.baseGroups != null ?
									this.props.baseGroups.map((baseGroup) => {
										return (
											<option value={baseGroup.id} key={baseGroup.id}>
												{baseGroup.title}
											</option>
										)
									}) : null}
							</select>
						</div>

						{this.props.active != null ?
							<div style={{ flex: 0, marginLeft: '1.25rem' }}>
								<Button
									style={{ whiteSpace: 'nowrap' }}
									type="secondary"
									onClick={e => { e.preventDefault(); this.clearSelection(); }}
								>
									{this.props.translate('reset')}
								</Button>
							</div>
							: null}
					</Flex>
				</div>

				{this.state.loading ?
					<Spinner center />
					:
					<BaseGroupForm
						onUpdate={this.onBaseGroupUpdate}
						actions={this.state.selectedBaseGroup != '' ? null : baseGroupFormActions}
						section={this.props.section}
						baseGroup={this.props.active}
						hideTitle
					/>
				}
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		active: state.BaseGroups.baseGroup,
		baseGroups: state.BaseGroups.baseGroups,
		translate: translate(state.Languages.translations),
	};
}

export default connect(mapStateToProps, {
	getBaseGroups,
	getBaseGroup,
	clearActiveBaseGroup,
	createBaseGroup,
	updateBaseGroup,
	setActiveBaseGroup,
})(AssignmentTaskSelect);
