import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import User from '_class/User';
import sniffer from 'sniffer';
import Moment from 'moment';

import { getActiveSchool } from 'helpers/localstorage';
import { getParameterByName } from 'helpers/url';

import { setPageTitle } from 'actions/header';
import {
	setCurrentSearchTerm,
	searchSchoolUsers,
	searchEducationGroups,
	searchMentorGroups,
	searchAssignment,
	searchPlans,
	searchPosts,
	clearSearch,
} from 'actions/search';

import { Spinner, DataList } from 'UI';
import RowItem, { RowCell } from 'UI/Elements/List/RowItem';
import { Search as SearchInput } from 'UI/Inputs';
import { Tabs, Tab, translate } from '@haldor/ui';

class Search extends Component {

	static contextTypes = {
		router: PropTypes.object,
	};

	constructor(props) {
		super(props);

		this.location = window.location.href;
		this.nextTerm = '';
		this.state = {
			loading: false,
			loadingEducationGroups: false,
			loadingMentorGroups: false,
			loadingStaff: false,
			loadingStudents: false,
			loadingAssignments: false,
			loadingPlans: false,
			loadingGuardians: false,
			loadingPosts: false,
			term: null,
		};
	}

	UNSAFE_componentWillMount = () => {
		this.props.setPageTitle(this.props.translate('search'));
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const searchString = getParameterByName('q');
		if (nextProps.term != searchString) {
			this.nextTerm = searchString;
			this.runSearch(searchString);
		}
	}

	componentDidMount() {
		const searchString = getParameterByName('q');
		if (getParameterByName('q') != this.props.term) {
			this.runSearch(searchString);
		}
	}

	runSearch = (term) => {
		if (term != null && term !== '') {
			const user = new User(this.props.currentUser);

			this.props.clearSearch();
			this.props.setCurrentSearchTerm(term);

			this.setState({
				term,
				loading: true,
				loadingEducationGroups: true,
				loadingMentorGroups: true,
				loadingStaff: true,
				loadingGuardians: true,
				loadingStudents: true,
				loadingAssignments: true,
				loadingPosts: true,
				loadingPlans: true,
			});

			if (!user.isStudent()) {
				this.props.searchEducationGroups(encodeURIComponent(term)).then(() => {
					this.setState({ loadingEducationGroups: false });
				});
			}
			if (!user.isStudent()) {
				this.props.searchMentorGroups(encodeURIComponent(term)).then(() => {
					this.setState({ loadingMentorGroups: false });
				});
			}
			if (!user.isStudent()) {
				if (term.indexOf('/') == -1) {
					this.props.searchSchoolUsers(encodeURIComponent(term), 'staff,student,guardian', '').then(() => {
						this.setState({ loadingStaff: false, loadingStudents: false, loadingGuardians: false });
					});
				} else {
					this.setState({ loadingStaff: false, loadingStudents: false, loadingGuardians: false });
				}
			}
			else {
				this.props.searchSchoolUsers(encodeURIComponent(term), 'staff', '').then(() => {
					this.setState({ loadingStaff: false });
				});
			}
			this.props.searchAssignment(encodeURIComponent(term)).then(() => {
				this.setState({ loadingAssignments: false });
			});

			this.props.searchPlans(encodeURIComponent(term)).then(() => {
				this.setState({ loadingPlans: false });
			});

			this.props.searchPosts(encodeURIComponent(term)).then(() => {
				this.setState({ loadingPosts: false });
			});
		}
	}

	onSearch = (value) => {
		this.runSearch(value.query);
	}

	renderGroupRow = (group) => {
		return <RowItem key={group.id} target={`/groups/${group.id}`}>
			<RowCell title={this.props.translate('name')}>
				{group.title}
			</RowCell>
		</RowItem>
	}

	renderStaff = (user) => {
		const currentUser = new User(this.props.currentUser);

		return <RowItem key={user.userId} target={`/user/${user.userId}`}>
			<RowCell title={this.props.translate('name')}>
				{user.displayName}
			</RowCell>

			<RowCell title={this.props.translate('e-mail')}>
				{user.email}
			</RowCell>

			<RowCell title={this.props.translate('roles')}>
				<ul style={{ listStyleType: "none" }}>
					{user.userRoles
						.filter((role) => {
							if (currentUser.isStudent()) {
								return role.indexOf('ADMIN') == -1;
							}
							return true;
						})
						.map(role => {
							return <li style={{ padding: "2px 0px" }} key={'role-row-' + user.id + "-" + role}>
								{this.props.translate(role)}
							</li>
						})}
				</ul>
			</RowCell>
		</RowItem>
	}

	renderStudent = (user) => {
		return <RowItem key={user.userId} target={`/user/${user.userId}/progress`}>
			<RowCell title={this.props.translate('name')}>
				{user.displayName}
			</RowCell>

			<RowCell title={this.props.translate('e-mail')}>
				{user.email}
			</RowCell>
		</RowItem>
	}

	renderGuardian = (user) => {
		return <RowItem key={user.userId} target={`/guardian/${user.userId}`}>
			<RowCell title={this.props.translate('name')}>
				{user.displayName}
			</RowCell>

			<RowCell title={this.props.translate('students')}>
				<ul style={{ listStyleType: "none" }}>
					{user.students.map(student => { return <li style={{ padding: "2px 0px" }} key={'student-row-' + user.id + "--" + student.userId}> {student.firstName + " " + student.lastName} </li> })}
				</ul>
			</RowCell>
		</RowItem>
	}

	renderAssignment = (assignment) => {
		return <RowItem key={assignment.id} target={`/assignment/${assignment.id}`}>
			<RowCell title={this.props.translate('column-id')} shrink>{assignment.id}</RowCell>
			<RowCell title={this.props.translate('title')}>{assignment.title}</RowCell>
			<RowCell title={this.props.translate('column-group')}>{assignment.section.title}</RowCell>
			<RowCell title={this.props.translate('start')} shrink>{Moment(assignment.startDate).format('YYYY-MM-DD HH:mm')}</RowCell>
			<RowCell title={this.props.translate('end')} shrink>{Moment(assignment.dueDate).format('YYYY-MM-DD HH:mm')}</RowCell>
		</RowItem>
	}

	renderPlan = (plan) => {
		return <RowItem key={plan.id} target={`/plan/${plan.id}`}>
			<RowCell title={this.props.translate('column-id')} shrink>{plan.id}</RowCell>
			<RowCell title={this.props.translate('title')}>{plan.title}</RowCell>
			<RowCell title={this.props.translate('column-group')} shrink>{plan.section.title}</RowCell>
			<RowCell title={this.props.translate('start')} shrink>{Moment(plan.timeStart).format('YYYY-MM-DD HH:mm')}</RowCell>
			<RowCell title={this.props.translate('end')} shrink>{Moment(plan.timeEnd).format('YYYY-MM-DD HH:mm')}</RowCell>
		</RowItem>
	}

	renderPost = (post) => {
		return <RowItem key={post.id} target={`/post/${post.id}`}>
			<RowCell title={this.props.translate('column-id')} shrink>{post.id}</RowCell>
			<RowCell title={this.props.translate('title')}>{post.title}</RowCell>
		</RowItem>
	}

	render() {
		const { translate } = this.props;
		const user = new User(this.props.currentUser);

		return (
			<div className="overview">
				{sniffer.isDevice ?
					<SearchInput
						placeholder={translate('search') + '...'}
						onSubmit={this.onSearch}
					/>
					: null}

				<Tabs>
					<Tab title={this.props.translate("All")} route="all">
						<div style={{ marginTop: '2rem' }}>
							{!user.isStudent() ?
								<DataList
									title={translate('students')}
									data={this.state.loadingStudents ? [] : this.props?.students?.sort((a, b) => (a.lastName || "").localeCompare(b.lastName || ""))}
									renderRow={this.renderStudent}
									emptyData={this.state.loadingStudents ? () => (
										<div>
											<Spinner center />
										</div>
									) : () => (
										<div className="color--meta text--center weight--bold">
											{translate('no-results')}
										</div>
									)}
								/>
								: null}
						</div>

						<div style={{ marginTop: '2rem' }}>
							<DataList
								title={translate('staff')}
								data={this.state.loadingStaff ? [] : this.props.staff?.sort((a, b) => (a.lastName || "").localeCompare(b.lastName || ""))}
								renderRow={this.renderStaff}
								emptyData={this.state.loadingStaff ? () => (

									<div>
										<Spinner center />
									</div>
								) : () => (
									<div className="color--meta text--center weight--bold">
										{translate('no-results')}
									</div>
								)}
							/>
						</div>

						<div style={{ marginTop: '2rem' }}>
							{user.isAdministrator() || user.isMentor() ?
								<div>
									<DataList
										title={translate('Guardian')}
										data={this.state.loadingGuardians ? [] : this.props.guardians?.sort((a, b) => (a.lastName || "").localeCompare(b.lastName || ""))}
										renderRow={this.renderGuardian}
										emptyData={this.state.loadingGuardians ? () => (
											<div>
												<Spinner center />
											</div>
										) : () => (
											<div className="color--meta text--center weight--bold">
												{translate('no-results')}
												<div>
													<Link to="/guardian">{translate('show-all-guardians')}</Link>
												</div>
											</div>
										)}
									/>
									{this.props.guardians.length > 0 ?
										<div style={{ paddingTop: "7px" }}>
											<Link to="/guardian">{translate('show-all-guardians')}</Link>
										</div> : null}
								</div>
								: null}
						</div>

						<div style={{ marginTop: '2rem' }}>
							{!user.isStudent() ?
								<DataList
									title={translate('EDUCATION_GROUPS')}
									data={this.state.loadingEducationGroups ? [] : this.props.educationGroups}
									renderRow={this.renderGroupRow}
									emptyData={this.state.loadingEducationGroups ? () => (
										<div>
											<Spinner center />
										</div>
									) : () => (
										<div className="color--meta text--center weight--bold">
											{translate('no-results')}
										</div>
									)}
								/>
								: null}
						</div>

						<div style={{ marginTop: '2rem' }}>
							{!user.isStudent() ?
								<DataList
									title={translate('mentor-header-overview')}
									data={this.state.loadingMentorGroups ? [] : this.props.mentorGroups}
									renderRow={this.renderGroupRow}
									emptyData={this.state.loadingMentorGroups ? () => (
										<div>
											<Spinner center />
										</div>
									) : () => (
										<div className="color--meta text--center weight--bold">
											{translate('no-results')}
										</div>
									)}
								/>
								: null}
						</div>

						<div style={{ marginTop: '2rem' }}>
							<DataList
								title={translate('tasks-header-overview')}
								data={this.state.loadingAssignments ? [] : this.props.assignments}
								renderRow={this.renderAssignment}
								emptyData={this.state.loadingAssignments ? () => (
									<div>
										<Spinner center />
									</div>
								) : () => (
									<div className="color--meta text--center weight--bold">
										{translate('no-results')}
									</div>
								)}
							/>
						</div>

						<div style={{ marginTop: '2rem' }}>
							<DataList
								title={translate('planning-header-overview')}
								data={this.state.loadingPlans ? [] : this.props.plans}
								renderRow={this.renderPlan}
								emptyData={this.state.loadingPlans ? () => (
									<div>
										<Spinner center />
									</div>
								) : () => (
									<div className="color--meta text--center weight--bold">
										{translate('no-results')}
									</div>
								)}
							/>
						</div>

						<div style={{ marginTop: '2rem' }}>
							<DataList
								title={translate('Posts')}
								data={this.state.loadingPosts ? [] : this.props.posts}
								renderRow={this.renderPost}
								emptyData={this.state.loadingPosts ? () => (
									<div>
										<Spinner center />
									</div>
								) : () => (
									<div className="color--meta text--center weight--bold">
										{translate('no-results')}
									</div>
								)}
							/>
						</div>
					</Tab>

					{!this.state.loadingStudents && this.props.students != null && this.props.students.length > 0 && !user.isStudent() ?
						<Tab title={this.props.translate("students")} route="students">
							<div style={{ marginTop: '2rem' }}>
								<DataList
									title={translate('students')}
									data={this.props.students}
									renderRow={this.renderStudent}
									emptyData={() => (
										<div className="color--meta text--center weight--bold">
											{translate('no-results')}
										</div>
									)}
								/>
							</div>
						</Tab>
						: null}

					{!this.state.loadingStaff && this.props.staff != null && this.props.staff.length > 0 ?
						<Tab title={this.props.translate("staff")} route="staff">

							<div style={{ marginTop: '2rem' }}>
								<DataList
									title={translate('staff')}
									data={this.props.staff}
									renderRow={this.renderStaff}
									emptyData={() => (
										<div className="color--meta text--center weight--bold">
											{translate('no-results')}
										</div>
									)}
								/>
							</div>
						</Tab>
						: null}

					{(user.isAdministrator() || user.isMentor()) && !this.state.loadingGuardians && this.props.guardians != null && this.props.guardians.length > 0 ?
						<Tab title={this.props.translate("Guardian")} route="guardian">
							<div style={{ marginTop: '2rem' }}>
								<DataList
									title={translate('Guardian')}
									data={this.props.guardians}
									renderRow={this.renderGuardian}
									emptyData={() => (
										<div className="color--meta text--center weight--bold">
											{translate('no-results')}
										</div>
									)}
								/>
							</div>
						</Tab>
						: null}

					{!this.state.loadingEducationGroups && this.props.educationGroups != null && this.props.educationGroups.length > 0 && !user.isStudent() ?
						<Tab title={this.props.translate("EDUCATION_GROUPS")} route="educationgroups">
							<div style={{ marginTop: '2rem' }}>
								<DataList
									title={translate('EDUCATION_GROUPS')}
									data={this.props.educationGroups}
									renderRow={this.renderGroupRow}
									emptyData={() => (
										<div className="color--meta text--center weight--bold">
											{translate('no-results')}
										</div>
									)}
								/>
							</div>
						</Tab>
						: null}

					{!this.state.loadingMentorGroups && this.props.mentorGroups != null && this.props.mentorGroups.length > 0 && !user.isStudent() ?
						<Tab title={this.props.translate("mentor-header-overview")} route="mentorgroups">
							<div style={{ marginTop: '2rem' }}>
								<DataList
									title={translate('mentor-header-overview')}
									data={this.props.mentorGroups}
									renderRow={this.renderGroupRow}
									emptyData={() => (
										<div className="color--meta text--center weight--bold">
											{translate('no-results')}
										</div>
									)}
								/>
							</div>
						</Tab>
						: null}

					{!this.state.loadingAssignments && this.props.assignments != null && this.props.assignments.length > 0 ?
						<Tab title={this.props.translate("tasks-header-overview")} route="assignments">
							<div style={{ marginTop: '2rem' }}>
								<DataList
									title={translate('tasks-header-overview')}
									data={this.props.assignments}
									renderRow={this.renderAssignment}
									emptyData={() => (
										<div className="color--meta text--center weight--bold">
											{translate('no-results')}
										</div>
									)}
								/>
							</div>
						</Tab>
						: null}

					{!this.state.loadingPlans && this.props.plans != null && this.props.plans.length > 0 ?
						<Tab title={this.props.translate("planning")} route="plans">
							<div style={{ marginTop: '2rem' }}>
								<DataList
									title={translate('planning-header-overview')}
									data={this.props.plans}
									renderRow={this.renderPlan}
									emptyData={() => (
										<div className="color--meta text--center weight--bold">
											{translate('no-results')}
										</div>
									)}
								/>
							</div>
						</Tab>
						: null}

					{!this.state.loadingPosts && this.props.posts != null && this.props.posts.length > 0 ?
						<Tab title={this.props.translate("Posts")} route="posts">
							<div style={{ marginTop: '2rem' }}>
								<DataList
									title={translate('Posts')}
									data={this.state.loadingPosts ? [] : this.props.posts}
									renderRow={this.renderPost}
									emptyData={this.state.loadingPosts ? () => (
										<div>
											<Spinner center />
										</div>
									) : () => (
										<div className="color--meta text--center weight--bold">
											{translate('no-results')}
										</div>
									)}
								/>
							</div>
						</Tab>
						: null}
				</Tabs>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		term: state.Search.term,
		educationGroups: state.Search.educationGroups,
		mentorGroups: state.Search.mentorGroups,
		staff: state.Search.staff,
		students: state.Search.students,
		assignments: state.Search.assignments,
		plans: state.Search.plans,
		currentUser: state.user.currentUser,
		guardians: state.Search.guardians,
		posts: state.Search.posts,
	};
}

export default connect(mapStateToProps, {
	setCurrentSearchTerm,
	searchSchoolUsers,
	searchEducationGroups,
	searchMentorGroups,
	setPageTitle,
	searchAssignment,
	searchPlans,
	searchPosts,
	clearSearch,
})(Search);