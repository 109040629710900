import React, { Component } from "react";
import { translate, getActiveLanguage } from '@haldor/ui';
import { connect } from "react-redux";
import Moment from 'moment';

import { addError } from 'actions';
import { createBulkAssignments, getTaskDetails } from 'actions/assignments';
import { getPlansBySectionId, getPlanDetails, setActivePlan } from 'actions/plans';
import { getBlocksByReference, updateBlocksOnReference, createAssessmentBlock } from 'actions/blocks';
import { getScheduleForUser } from 'actions/schedule';
import { toggleSelectedSection, clearSelectedSections } from "actions/sections";

import { Form, Field } from 'react-final-form';
import { Expandable, List, Spinner } from 'UI';
import { Editor, TimePicker } from 'UI/Inputs';
import { Checkbox, Button } from '@haldor/ui';
import Upload from 'components/Inputs/upload';
import DatePickerFromTo from '../Partials/DatePickerFromTo';
import CustomSelector from '../Partials/CustomSelector';
import AttachedDocuments from 'components/Presentation/Documents/AttachedDocuments';
import InvalidGroupNotice from '../Partials/InvalidGroupNotice';
import BlockAssessments from 'components/BlockAssessments';
import { ASSESSMENT_TYPES } from "components/BlockAssessments/AssessmentTypes";

import MultipleSectionSelector from '../Partials/MultipleSectionSelector';
import TeacherAdjustments from 'containers/AdditionalAdjustments/Display/TeacherAdjustments';
import Modal from 'containers/Modals/Modal';
import DatePicker from 'react-datepicker';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import sv from 'date-fns/locale/sv';
import no from 'date-fns/locale/nb';
import en from 'date-fns/locale/en-US';
import da from "date-fns/locale/da";
import uk from "date-fns/locale/uk";

class MultipleAssignmentForm extends Component {
	/* Class setup */
	static defaultProps = {
		cloneView: false,
		disablePlanSelector: false,
	};

	constructor(props) {
		super(props);

		registerLocale('en-US', en);
		registerLocale('sv-SE', sv);
		registerLocale('nb-NO', no);
		registerLocale('da-DK', da);
		registerLocale('uk', uk);
		setDefaultLocale('en-US');

		this.sectionsLocked = false;

		let sections = [];

		if (props.section != null) {
			this.sectionsLocked = true;
			sections = [props.section];

			sections.forEach((section) => {
				section.students.forEach((student) => {
					student.checked = true;
					return student;
				});

				return section;
			});
		}

		let dateStart = Moment().second(0);
		let dueDate = null;

		if (props.date != null) {
			dateStart = props.date;
		}

		if (props.initialValues?.startDate != null) {
			dateStart = props.initialValues.startDate;
		}

		if (props.initialValues?.dueDate) {
			dueDate = props.initialValues?.dueDate;
		}

		this.state = {
			dateStart: props.hasDate ? Moment(props.hasDate) : dateStart,
			dateEnd: dueDate,
			tasks: null,
			createEvent: false,
			studentAssessment: false,
			selfAssessment: false,
			groupEnabled: false,
			published: Moment().local(),
			tempPublishDate: Moment().local(),
			status: 'ASSIGNMENT_OPEN',
			allowFetch: true,
			lesson: [],
			attachedFiles: [],
			newDocuments: [],
			sections,
			loading: false,
			updated: false,
			loadingPlan: false,
			isFetchingLesson: false,
			selectedLesson: [],
			toggleLesson: {},
			planId: props.planid != null ? props.planid : 0,
			initialAssessmentBlocks: props.initialValues.assessmentBlocks,
			fileTypes: [
				{ value: 1, text: this.props.translate('instructions') },
				{ value: 2, text: this.props.translate('template-document') },
			],
		};
	}

	/* Redux form functions */
	required = (value) => {
		if (typeof value !== 'undefined' && value !== '') {
			if (value.length > 99) {
				// Field failed validation, return error for this field (String)
				return this.props.translate('field-max-100-chars');
			}

			// Field passes validation, return a undefined error for this field
			// Rule: Not undefined and not an empty string and not over 200 characters
			return undefined;
		}

		// Field failed validation, return error for this field (String)
		return this.props.translate('field-needs-input');
	};

	fixDescription = (html) => {
		html = html.replace(/&lt;/g, '<');
		html = html.replace(/&gt;/g, '>');
		html = html.replace(/\<\/b\>/gi, ' </b>');
		html = html.replace(/\<\/i\>/gi, ' </i>');
		html = html.replace(/\<\/a\>/gi, ' </a>');
		html = html.replace(/\<\/p\>/gi, ' </p>');

		return html;
	};

	submit = (values) => {
		const { dateStart, dateEnd, tasks, groupEnabled, status, published } = this.state;

		return new Promise((resolve) => {
			const isEditing = this.props.editView;
			let assessmentBlocks = [];
			let assessmentType = 'TEACHER';

			if (this.state.selectedLesson.length > 0) {
				let relation = [];
				this.state.selectedLesson.map((items) => {
					let obj = {
						CalendarEventId: items.id,
						ReferenceType: 'ASSIGNMENT',
						ReferenceId: 0,
					};
					relation.push(obj);
				});
				values.relationships = relation;
			} else {
				values.relationships = [];
			}

			if (values.assessmentBlocks != null) {
				assessmentBlocks = [...values.assessmentBlocks];
			}

			values.startDate = dateStart;
			values.dueDate = dateEnd;
			values.groupEnabled = groupEnabled;
			values.attachedFiles = this.state.attachedFiles;
			values.planId = this.state.planId;
			if (values.status === 'ASSIGNMENT_DRAFT') {
				values.published = null;
			} else {
				values.published = published;
			}
			values.status = status;

			if (this.state.selfAssessment) {
				assessmentType = 'SELF';
			}
			if (this.state.studentAssessment) {
				assessmentType = 'BUDDY';
			}

			values.assessmentType = assessmentType;

			if (this.state.newDocuments.length > 0) {
				values.attachedFiles = [...values.attachedFiles, ...this.state.newDocuments];
			}
			if (this.props.cloneView) {
				values.documentTemplates = null;
				values.assignmentDocuments = null;
				values.plans = null;
			}

			values.sections = JSON.parse(JSON.stringify(this.state.sections));

			if (values.sections != null) {
				values.sections.forEach((section) => {
					section.students =
						section.students != null ? section.students.filter((t) => t.checked) : [];
					return section;
				});
			}

			delete values.assessmentBlocks;

			let that = this;
			this.props.createBulkAssignments(values).then(async () => {
				if (this.props.onSubmit) {
					let starttime = Moment(values.dueDate).utc().format();
					this.props.onSubmit(starttime);
				}

				if (values.planId != null && values.planId != 0) {
					// Add this assignment as a block on the selected plan
					await new Promise(async (resolve) => {
						await this.props.getBlocksByReference(values.planId, 'PLAN');

						let blocks = [];

						if (this.props.planBlocks != null && this.props.planBlocks.length > 0) {
							blocks = [...this.props.planBlocks];

							blocks.forEach((block) => {
								if (block.type == 'Haldor.Blocks.Assignment') {
									if (block.resources != null) {
										let resources = [];
										let typeResource = block.resources.filter(resource => {
											return resource['@odata.type'] == 'assessmenttype';
										})[0]?.value;
										block.resources.forEach(resource => {
											if (resource['@odata.type'] != 'assessmenttype') {
												resources.push({
													assessmentType: typeResource == null ? ASSESSMENT_TYPES.TEACHER : typeResource,
													id: resource.id,
													'@odata.type': resource['@odata.type'],
												});
											}
										});

										block.resources = resources;
									}
								}

								block['_Resources'] = null;

								return block;
							});
						}

						blocks.push({
							type: 'Haldor.Blocks.Assignment',
							title: '',
							value: '',
							sortorder: blocks.length,
							id: 0,
							resources: [
								{
									'@odata.type': 'haldor.assignment.block.assignment',
									id: this.props.createdAssignments[0].id,
								},
							],
						});

						await this.props.updateBlocksOnReference(blocks, values.planId, 'PLAN');
						resolve(1);
					});
				}

				let promises = [];
				assessmentBlocks.forEach((block) => {
					if (this.props.cloneView) {
						block.resources.map((resource) => {
							resource.originId = resource.id;
							resource.id = 0;
							return resource;
						});
					}

					this.props.createdAssignments.forEach((assignment) => {
						promises.push(new Promise((resolve) => {
							this.props.createAssessmentBlock(block, assignment.id, 'ASSIGNMENT')
								.then(() => {
									resolve(1);
								});
						}));
					})
				});

				Promise.all(promises).then(() => {
					promises = [];

					/** We need to retrieve the assignments that we created to update the object in existing assignmentList in reducer */
					this.props.createdAssignments.forEach((assignment) => {
						promises.push(this.props.getTaskDetails(assignment.id));
					});

					Promise.all(promises).then(() => {
						this.props.onAbort(true);
						resolve(true);
					});
				});
			});
		});
	};

	componentWillUnmount = () => {
		this.props.clearSelectedSections();
	}

	componentDidMount = () => {
		if (this.props.section != null) {
			this.props.toggleSelectedSection(this.props.section);
			this.props.getPlansBySectionId(this.props.section.id);
		}

		if (this.props.planid != null) {
			this.setState({ loadingPlan: true });
			this.props.getPlanDetails(this.props.planid).then(() => {
				this.setState({
					associatedMatrices: this.props.plan.associatedMatrices,
					loadingPlan: false,
				});
			});
		}

		let attachedFiles = this.state.attachedFiles;
		if (this.props.initialValues != null && this.props.initialValues.assignmentDocuments != null) {
			var files = this.props.initialValues.assignmentDocuments.split(';');

			files.forEach((file) => {
				if (file != '') {
					var fileObj = {
						key: file.substring(file.lastIndexOf('/') + 1),
						value: file,
						fileType: 1,
					};

					attachedFiles.push(fileObj);
				}
			});
		}

		if (this.props.initialValues != null && this.props.initialValues.documentTemplates != null) {
			var files = this.props.initialValues.documentTemplates.split(';');

			files.forEach((file) => {
				if (file != '') {
					var fileObj = {
						key: file.substring(file.lastIndexOf('/') + 1),
						value: file,
						fileType: 2,
					};

					attachedFiles.push(fileObj);
				}
			});
		}

		if (attachedFiles.length > 0) {
			this.setState({ attachedFiles });
		}
	};

	onStudentAssessmentChange = (checked) => {
		this.setState({ studentAssessment: checked });
	};

	handleLessons = (checked, lessonObj) => {
		this.setState((prevState) => ({
			selectedLesson: checked
				? [...prevState.selectedLesson, lessonObj]
				: prevState.selectedLesson.filter((x) => x.id != lessonObj.id),
			toggleLesson: checked
				? { ...prevState.toggleLesson, [lessonObj.id]: true }
				: { ...prevState.toggleLesson, [lessonObj.id]: false },
		}));
	};

	handleStatus = (value) => {
		this.setState({ status: value });
	};

	formatDate = (date, length, lessonDate) => {
		if (length) {
			Moment.utc(date)
				.set({
					second: 0,
					millisecond: 0,
				})
				.local()
				.add(length, 'minutes')
				.format('HH:mm');
		}
	};

	onDateChange = (dates) => {
		this.setState({ dateStart: Moment(dates.start), dateEnd: Moment(dates.end), allowFetch: true });
	};

	onUserListGroupToggle = (checked) => {
		this.setState({ groupEnabled: checked });
	};

	onLessonChange = (value) => {
		this.setState({ selectedLesson: value });
	};

	filterLesson = (data) => {
		const sortByStartTime = (a, b) => Moment(a.startTime).diff(b.startTime);

		if (this.state.selectedLesson.length > 0) {
			let filter = data.filter((item) => {
				return !this.state.selectedLesson.find((f) => {
					return f.id === item.id;
				});
			});

			return filter.concat(this.state.selectedLesson).sort(sortByStartTime);
		}

		return data.sort(sortByStartTime);
	};

	fetchLesson = () => {
		if (!this.state.allowFetch) {
			return;
		}
		//dont fetch if both start and end date is same
		let start = this.state.dateStart
		let end = this.state.dateEnd
		const diff = end.diff(start, 'minutes');
		if (diff === 0) {
			return;
		}

		this.setState({ isFetchingLesson: true });

		if (this.state.dateStart && this.state.dateEnd) {
			this.props.getScheduleForUser(
				this.props.user.id,
				Moment(this.state.dateStart).utc().set({ second: 0, millisecond: 0 }).toISOString(),
				Moment(this.state.dateEnd).utc().set({ second: 0, millisecond: 0 }).toISOString(),
			).then(() => {
				this.setState({
					lesson: this.filterLesson(this.props.usersSchedule[this.props.user.id]),
					isFetchingLesson: false,
					allowFetch: false,
				});
			}).catch((e) => {
				console.log(e);
				this.setState({
					isFetchingLesson: true,
				});
			});
		} else {
			let startTime = Moment().utc().set({ hour: 0, minute: 0, second: 0 }).toISOString();
			let endTime = Moment().utc().set({ hour: 23, minute: 59, second: 59 }).toISOString();
			this.props.getScheduleForUser(this.props.user.id, startTime, endTime)
				.then(() => {
					this.setState({
						lesson: this.filterLesson(this.props.usersSchedule[this.props.user.id]),
						isFetchingLesson: true,
						allowFetch: false,
					});
				})
				.catch((e) => {
					this.setState({
						isFetchingLesson: true,
					});
				});
		}
	};

	selectDocument = (files) => {
		this.setState({ newDocuments: files.filter(file => file.mimeType !== 'unknown/mime-type') });
	};

	removeInstruction = (instruction, remove) => {
		let attachedFiles = this.state.attachedFiles;
		var removeFile = attachedFiles.find((file) => file.value == instruction);

		if (removeFile != null) {
			if (this.props.cloneView) {
				attachedFiles = attachedFiles.filter((t) => t.value != instruction);
			} else {
				if (removeFile.markedForDeletion == true && remove == false) {
					var removeFileIndex = attachedFiles.findIndex((file) => file.value == instruction);
					attachedFiles.splice(removeFileIndex, 1);
				}
			}
		} else {
			if (remove) {
				var assignmentDocuments = this.props.initialValues
					? this.props.initialValues.assignmentDocuments != null
						? this.props.initialValues.assignmentDocuments.split(';')
						: []
					: [];
				let files = [];
				assignmentDocuments.forEach((document) => {
					if (document == instruction && document != '' && document != null) {
						let removeFile = {
							markedForDeletion: remove,
							fileType: 1,
							value: document,
							key: '',
						};

						attachedFiles.push(removeFile);
					}
				});
			} else {
				let file = {
					fileType: 1,
					value: instruction,
					key: instruction.substring(instruction.lastIndexOf('/') + 1),
				};

				attachedFiles.push(file);
			}
		}

		this.setState({ attachedFiles });
	};

	setStudentAssessment = checked => {
		if (checked) {
			this.setState({
				studentAssessment: checked,
				selfAssessment: !checked,
			});
		} else {
			this.setState({ studentAssessment: checked });
		}
	};

	handleDisplayDate = (date) => {
		this.setState({
			tempPublishDate: Moment(date),
		});
	};
	onSubmitDisplayDate = () => {
		this.setState({
			published: this.state.tempPublishDate,
			createEvent: false,
		});
	};
	handleDisplayInSchedule = (event) => {
		event.preventDefault();
		this.setState({
			createEvent: true,
		});
	};
	closeModal = () => {
		this.setState({
			tempPublishDate: this.state.published ?? Moment().local(),
			createEvent: false,
		});
	};

	setSelfAssessment = (checked) => {
		if (checked) {
			this.setState({
				studentAssessment: !checked,
				selfAssessment: checked,
			});
		} else {
			this.setState({ selfAssessment: checked });
		}
	};

	onSectionsChange = (groups) => {
		this.setState({ sections: groups });

		if (groups.length == 1) {
			this.props.getPlansBySectionId(groups[0].id);
		}
		if (this?.props?.displayTitle && groups?.length) {
			let title = '';
			groups.forEach(element => {
				if (element?.students.length > 0) {
					title = element?.title;
				}
			});
			this?.props?.displayTitle(title);
		}
	};

	toggleAllStudents = (checked) => {
		let { sections } = this.state;

		sections.forEach((section) => {
			section.students.forEach((student) => {
				student.checked = checked;
				return student;
			});

			return section;
		});

		this.setState({ sections });
	};

	onAddSectionCourse = (newGroups) => {
		let { sections } = this.state;

		sections.forEach((section) => {
			let newData = newGroups.find((group) => {
				return group.id == section.id;
			});

			if (newData != null) {
				section.courses = newData.courses;
			}

			return section;
		});

		this.setState({ sections });
	};

	onPlanChange = (event, form) => {
		if (event.target.value != '0') {
			this.setState({ loadingPlan: true, planId: event.target.value });
			this.props.getPlanDetails(event.target.value).then(async () => {
				await this.props.getBlocksByReference(event.target.value, 'PLAN');
				if (this.props.planBlocks != null) {
					const assessmentBlocks = this.props.planBlocks.filter((block) =>
						block.type == 'Haldor.Blocks.AssessmentBlock'
					).map((block) => ({
						...block,
						id: 0,
						referenceId: 0,
						referenceType: '',
					}));
					let values = this.state.initialAssessmentBlocks;
					if (values == null) {
						values = [...assessmentBlocks];
					} else {
						values = [...values, ...assessmentBlocks];
					}

					form.change('assessmentBlocks', values);
				}

				this.setState({ loadingPlan: false });
			});
		} else {
			this.props.setActivePlan(null);
			this.setState({ loadingPlan: true, planId: 0 }, () => {
				this.setState({ loadingPlan: false });
			});
		}
	};

	renderInput = ({ input, label, type, placeholder, meta: { touched, error, warning } }) => {
		return (
			<div>
				<input
					placeholder={placeholder || label}
					type={type}
					style={
						touched && error
							? {
								border: '1px solid red',
							}
							: {}
					}
					{...input}
				/>

				{touched &&
					((error && <span style={{ marginTop: '1rem', color: 'red' }}>{error}</span>) ||
						(warning && <span style={{ marginTop: '1rem', color: 'red' }}>{warning}</span>))}
			</div>
		);
	};

	getDateFormat = () => {
		let format = Moment.localeData(getActiveLanguage()).longDateFormat("L");
		let newFormat = format.replace("YYYY", "yyyy");
		let days = newFormat.replace("DD", "dd");
		return days;
	};

	normalizeLocale = (locale) => {
		const [language, region] = locale.split('-');

		if (language.toUpperCase() == "UK"){
			return `uk`;
		}
		return `${language}-${region.toUpperCase()}`;
	};

	/* Render form */
	render() {
		const { initialValues } = this.props;
		if (this.state.loading) {
			return <Spinner center />;
		}
		let invalidDocuments = [...this.state.newDocuments].filter((file) => {
			if (file.fileType != null) {
				return file.fileType == 0;
			}

			return false;
		});

		let documentsPassing = true;
		if (invalidDocuments.length > 0) {
			documentsPassing = false;
		}

		let groups = [];
		let students = [];
		if (this.state.sections.length > 0) {
			this.state.sections.forEach((section) => {
				if (section.students != null) {
					section.students.forEach((student) => students.push(student.id));
				}

				groups.push(section.id);
			});
		}

		if (this.state.dateStart != null) {
			initialValues.startDate = this.state.dateStart;
		}

		if (this.state.dateEnd != null) {
			initialValues.dueDate = this.state.dateEnd;
		}

		let subjects = [];

		return (
			<div className='form-container form-create_task'>
				<InvalidGroupNotice />

				{!this.sectionsLocked ? (
					<Expandable
						contentStyles={{ padding: '1em 0rem' }}
						contentStylesClosed={{ padding: '0' }}
						headerStyles={this.state.error != null ? { border: '1px solid red' } : {}}
						whiteBackground={true}
						title={this.props.translate('choose-groups')}
						open
						ignorePropsUpdate
					>
						<List style={{ border: '0', margin: '0', boxShadow: '0' }}>
							<MultipleSectionSelector
								value={this.props.initialValues?.sectionId}
								onChange={this.onSectionsChange}
								type="assignment"
							/>
						</List>
					</Expandable>
				) : (
					<Expandable
						contentStyles={{ padding: '1em 0rem' }}
						contentStylesClosed={{ padding: '0' }}
						headerStyles={this.state.error != null ? { border: '1px solid red' } : {}}
						whiteBackground={true}
						title={
							<div
								style={{ display: 'inline-block' }}
								onClick={(e) => {
									e.stopPropagation();
									e.preventDefault();
								}}
							>
								<Checkbox
									value={true}
									onChange={(c) => this.toggleAllStudents(c)}
									label={
										this.state?.sections?.length > 0
											? this.state.sections[0].title
											: this.props.translate('select-students')
									}
									disabled={this.props.disabled}
								/>
							</div>
						}
						open
						ignorePropsUpdate
					>
						{this.state.sections.map((section) => {
							return (
								<div style={{ padding: '0 2.5em' }} key={section.id}>
									{section.students
										.sort((a, b) => a.lastName.localeCompare(b.lastName))
										.map((student) => {
											return (
												<div key={student.id} style={{ marginBottom: '.4rem' }}>
													<Checkbox
														value={student.checked}
														label={student.firstName + ' ' + student.lastName}
														onChange={(value) => {
															student.checked = value;
														}}
													/>
												</div>
											);
										})}
								</div>
							);
						})}
					</Expandable>
				)}

				<Form
					onSubmit={this.submit}
					initialValues={this.props.initialValues}
					initialValuesEqual={() => true}
					mutators={{
						setStatusDraft: (args, state, utils) => {
							utils.changeValue(state, 'status', () => 'ASSIGNMENT_DRAFT');
						},
					}}
					render={({ submitting, handleSubmit, form, valid }) => {
						let assessmentBlocks = form.getFieldState('assessmentBlocks')?.value;
						let subjects = [];
						if (assessmentBlocks?.length > 0) {
							assessmentBlocks?.forEach((content) => {
								content?.resources?.forEach(resources => {
									if (resources?.value?.details) {
										if (subjects.indexOf(resources.value?.subjectId) == -1) {
											subjects.push(parseInt(resources.value?.subjectId));
										}
									}
								})
							});
						}
						return (
							<form onSubmit={handleSubmit} className='form form-component'>
								{submitting ? (
									<div className='is_sending'>
										<p>
											<span className='loading-spinner' />
										</p>
									</div>
								) : null}

								<div className='form-row select'>
									<label>{this.props.translate('Status')}</label>
									<select onChange={(e) => this.handleStatus(e.target.value)}>
										{this.props.assignmentStatus
											.filter((x) => x.id !== 'ASSIGNMENT_DRAFT')
											.map((item) => {
												return (
													<option key={item.id} value={item.id}>
														{item.id === 'ASSIGNMENT_CLOSED'
															? this.props.translate('Hidden')
															: item.value}
													</option>
												);
											})}
									</select>
								</div>

								<div className='form-row select'>
									<label>{this.props.translate('type')}*</label>

									<Field
										component='select'
										name='assignmentType'
										validate={this.required}
									>
										<option value=''>
											{this.props.translate('select-an-alternative')}
										</option>

										{this.props.assignmentTypes.map((item) => {
											return (
												<option key={item.id} value={item.id}>
													{item.value}
												</option>
											);
										})}
									</Field>
								</div>

								{this.state.sections.length == 1 && !this.props.disablePlanSelector ? (
									<div className='form-row select'>
										<label>{this.props.translate('planning')}</label>

										<select
											disabled={this.props.planid != null}
											onChange={e => this.onPlanChange(e, form)}
											value={this.state.planId}
										>
											<option value='0'>
												{this.props.translate('pick-a-plan')}
											</option>

											{this.props.sectionPlans.map((sectionPlan) => {
												return (
													<option value={sectionPlan.id}>
														{sectionPlan.title}
													</option>
												);
											})}
										</select>
									</div>
								) : null}
								<div className='form-row input'>
									<label>{this.props.translate('title')}*</label>

									<Field
										component={this.renderInput}
										type='text'
										name='title'
										placeholder={this.props.translate('title')}
										validate={this.required}
									/>
								</div>

								<div className='form-row input'>
									<label>{this.props.translate('description')}</label>

									<Field
										component={Editor}
										name='description'
										placeholder={this.props.translate('describe-assignment')}
										translate={this.props.translate}
									/>
								</div>

								<div className='form-row'>
									<DatePickerFromTo
										timePicker
										type='assignment'
										onChange={this.onDateChange}
										values={
											initialValues ?? { startDate: Moment(this.state.startDate) }
										}
										cloneView={this.props.cloneView}
									/>
								</div>

								{this.props.services.advancedSchedule ?
									<div className='form-row select'>
										<div className='lesson-picker'>
											<label>{this.props.translate('select lesson')}</label>
											<CustomSelector
												label={
													this.props.lesson ? (
														<div className='selectorLabel'>
															<span className='selectorItems'>
																{this.props.lesson?.startTime
																	? Moment(
																		this.props.lesson?.startTime
																	).format('dddd Do MMMM')
																	: this.props.time.format(
																		'dddd Do MMMM'
																	)}{' '}
																{this.props.lesson?.startTime
																	? Moment.utc(
																		this.props.lesson?.startTime
																	)
																		.set({
																			second: 0,
																			millisecond: 0,
																		})
																		.local()
																		.format('LT')
																	: this.props.time.format('LT')}
																-{' '}
																{this.props.lesson?.startTime
																	? Moment.utc(this.props.lesson.startTime)
																		.set({
																			second: 0,
																			millisecond: 0,
																		})
																		.local()
																		.add(
																			this.props.lesson.length,
																			'minutes'
																		)
																		.format('LT')
																	: Moment.utc(this.props?.time)
																		.set({
																			second: 0,
																			millisecond: 0,
																		})
																		.local()
																		.add(this.props?.end, 'minutes')
																		.format('LT')}
															</span>

															<span className='selectorItems'>
																{this.props?.lesson.title}
															</span>
														</div>
													) : this.state.selectedLesson?.length > 1 ? (
														<span>
															{this.state.selectedLesson?.length}{' '}
															{this.props.translate('lessons-selected')}
														</span>
													) : this.state.selectedLesson.length === 1 ? (
														<div className='selectorLabel'>
															<span className='selectorItems'>
																{ }
																{Moment.utc(
																	this.state.selectedLesson[0].startTime
																).local().format('dddd Do MMMM')}{' '}
																{Moment.utc(
																	this.state.selectedLesson[0].startTime
																).set({
																	second: 0,
																	millisecond: 0,
																}).local().format('LT')}{' '}
																-{' '}
																{Moment.utc(
																	this.state.selectedLesson[0].startTime
																).set({
																	second: 0,
																	millisecond: 0,
																}).local()
																	.add(
																		this.state.selectedLesson[0].length,
																		'minutes'
																	)
																	.format('LT')}
															</span>

															<span className='selectorItems'>
																{this.state.selectedLesson[0].title}
															</span>
														</div>
													) : (
														<span>
															{this.props.translate(
																'select lesson'
															)}
														</span>
													)
												}
												lesson={this.props.lesson}
												fetchLesson={this.fetchLesson}
											>
												{!this.state.isFetchingLesson ? (
													this.state.lesson.length === 0 ? (
														<div className='noLesson'>
															<span>
																{this.props.translate('There are no lessons within the assignment time period')}
															</span>
														</div>
													) : (
														this.state.lesson.map((lesson) => {
															return (
																<div key={lesson.id} tabIndex={0}>
																	<Checkbox
																		value={
																			this.state.toggleLesson[
																			lesson.id
																			]
																		}
																		onChange={(checked) =>
																			this.handleLessons(
																				checked,
																				lesson
																			)
																		}
																		label={<div>
																			{Moment(lesson.startTime).format('dddd Do MMMM')}{' '}

																			{Moment.utc(lesson.startTime).local().set({
																				second: 0,
																				millisecond: 0,
																			}).format('LT')}{' '}
																			-{' '}
																			{Moment.utc(lesson.startTime).local().set({
																				second: 0,
																				millisecond: 0,
																			}).add(lesson.length, 'minutes').format('LT')}{' '}
																			{lesson.title}
																		</div>}
																	/>
																</div>
															);
														})
													)
												) : (
													<Spinner center />
												)}
											</CustomSelector>
										</div>
									</div>
									: null}

								<div style={{ clear: 'both' }} />

								{this.props.services.advancedSchedule ?
									<div className='form-row' style={{ marginBottom: '1rem' }}>
										<label>{this.props.translate('publication date')}</label>
										<Button
											type='dropDown'
											onClick={(e) => this.handleDisplayInSchedule(e)}
										>
											{this.state.published.format('dddd Do MMMM')}{' '}
											{this.state.published.format('LT')}
										</Button>
									</div>
									: null}

								<div className='form-divider' />

								<Expandable title={this.props.translate('Assessments')}>
									{!this.state.loadingPlan ?
										<Field
											component={BlockAssessments}
											name="assessmentBlocks"
											multipleAssessmentOptions={true}
										/>
										: null}
								</Expandable>

								{(this.props.editView || this.props.cloneView) &&
									this.props.initialValues.assignmentDocuments != null &&
									this.props.initialValues.assignmentDocuments.length > 0 ? (
									<div
										className='form-row'
										style={{ marginBottom: '1em', width: '100%' }}
									>
										<label>{this.props.translate('instructions')}</label>

										<AttachedDocuments
											documents={this.props.initialValues.assignmentDocuments}
											removeInstruction={this.removeInstruction}
										/>
									</div>
								) : null}

								{this.props.cloneView &&
									initialValues.documentTemplates != null &&
									initialValues.documentTemplates.length > 0 ? (
									<div
										className='form-row'
										style={{ marginBottom: '1em', width: '100%' }}
									>
										<label>{this.props.translate('templates')}</label>

										<AttachedDocuments
											documents={initialValues.documentTemplates}
											removeInstruction={this.removeInstruction}
										/>
									</div>
								) : null}

								<Upload
									lockedStatus={
										this.state.status === 'ASSIGNMENT_LOCKED' ? true : false
									}
									onUpdate={this.selectDocument}
									attachedFiles={this.state.attachedFiles}
									form='assignment'
								/>

								{this.props.services.additionalAdjustments ?
									<Expandable title={this.props.translate("additional-adjustments")}>
										<TeacherAdjustments
											disableCommenting={true}
											subjects={subjects}
											groups={groups}
										/>
									</Expandable>
									: null}

								<div className='form-divider' />

								<div className='form-row spacing submit'>
									<Button
										onClick={(e) => {
											e.preventDefault();
											//form.mutators.setStatusOpen();
											form.submit();
										}}
										disabled={
											submitting ||
											!valid ||
											!documentsPassing ||
											this.state.sections.length == 0 ||
											students.length == 0
										}
									>
										{this.props.translate('publish')}
									</Button>

									<Button
										onClick={(e) => {
											e.preventDefault();
											form.mutators.setStatusDraft();
											form.submit();
										}}
										type='secondary'
										disabled={
											submitting ||
											!valid ||
											!documentsPassing ||
											this.state.sections.length == 0 ||
											students.length == 0
										}
									>
										{this.props.translate('save-draft')}
									</Button>

									<div className='align-right'>
										<Button
											type='secondary'
											onClick={(e) => {
												e.preventDefault();
												this.props.onAbort(false);
											}}
										>
											{this.props.translate('Cancel')}
										</Button>
									</div>
								</div>
							</form>
						);
					}}
				/>
				<Modal
					isOpen={this.state.createEvent}
					onClose={this.closeModal}
					title={this.props.translate('add-date')}
				>
					<div className='in_scheduleFrom'>
						<div className='timeStart_scheduleFrom'>
							<span>{this.props.translate('start')}*</span>
							<DatePicker
								selected={this.state.tempPublishDate.toDate()}
								startDate={this.state.tempPublishDate.toDate()}
								onChange={this.handleDisplayDate}
								dateFormat={this.getDateFormat()}
								popperPlacement='bottom-end'
								positionFixed={true}
								popperModifiers={{
									preventOverflow: {
										enabled: true,
									},
									positionFixed: false,
								}}
								selectsEnd
								showWeekNumbers
								fixedHeight
								previousMonthButtonLabel=''
								nextMonthButtonLabel=''
								locale={this.normalizeLocale(getActiveLanguage())}
							/>
						</div>
						<div className='time_scheduleFrom'>
							<span>{this.props.translate('start-time')}*</span>

							<TimePicker
								placeholder={this.props.translate('pick-start-time')}
								time={this.state.tempPublishDate}
								onChange={this.handleDisplayDate}
							/>
						</div>
						<div className='submitTime' >
							<Button onClick={this.onSubmitDisplayDate}>{this.props.translate('Save')}</Button>
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	let values = { ...ownProps.initialValues };

	if (values != null && ownProps.planid != null) {
		values.planId = ownProps.planid;
	}
	//handle current planningAssessments
	if (ownProps?.planningAssessments?.length > 0) {
		let filteredAssessmentsBlocks = ownProps?.planningAssessments.filter(t => t.type === 'Haldor.Blocks.AssessmentBlock');
		if (filteredAssessmentsBlocks.length > 0) {
			values.assessmentBlocks = filteredAssessmentsBlocks;
		}
	}
	if (values != null && state.Blocks.reference != null) {
		let blocks = JSON.parse(JSON.stringify(state.Blocks.reference));
		values.assessmentBlocks = blocks.filter((block) =>
			block.type == "Haldor.Blocks.AssessmentBlock"
		);
	}
	// handle previous planningAssessments
	if (state.planning.active_plan?.blocks?.length > 0 && ownProps.existingPlanningAssessments) {
		values.assessmentBlocks = state?.planning?.active_plan?.blocks?.filter(t => t.type === 'Haldor.Blocks.AssessmentBlock');
	}

	if (ownProps.lesson != null) {
		const lesson = JSON.parse(JSON.stringify(ownProps.lesson));

		values.startDate = Moment.utc(lesson.startTime).local();
		values.dueDate = Moment(values.startDate).add(ownProps.lesson.length, 'minutes');
	}

	if (ownProps.time != null) {
		values.startDate = Moment(ownProps.time);
	}

	if (ownProps.length != null && values.startDate != null) {
		values.dueDate = Moment(values.startDate).add(ownProps.length, 'minutes');
	}

	return {
		user: state.user.currentUser,
		sections: state.sections.educationGroups,
		assignmentTypes: state.status.status.assignmentTypes,
		assignmentStatus: state.status.status.assignmentStatus,
		translate: translate(state.Languages.translations),
		services: state.Services.availableServices,
		plan: state.planning.active_plan,
		sectionPlans: state.planning.sectionPlans,
		planBlocks: state.Blocks.reference,
		createdAssignments: state.assignments.newAssignments,
		initialValues: values,
		usersSchedule: state.schedule.usersSchedule,
	};
}

export default connect(mapStateToProps, {
	createBulkAssignments,
	addError,
	getScheduleForUser,
	getPlansBySectionId,
	getPlanDetails,
	getTaskDetails,
	setActivePlan,
	getBlocksByReference,
	updateBlocksOnReference,
	createAssessmentBlock,
	toggleSelectedSection,
	clearSelectedSections,
})(MultipleAssignmentForm);
