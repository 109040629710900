import moment from 'moment';
import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import User from '_class/User';
import DateTime from '_class/DateTime';

import { PRESCHOOL } from "constants/schoolTypes";
import { isUserAdministrator, isUserTeacher } from 'helpers/user';

import { addError } from 'actions';
import { setPageTitle, setPageActions } from 'actions/header';
import { getNotesByReference, resetActiveNote, setActiveNote, removeNote } from 'actions/notes';
import { getForm, getFormInstance, getFormTemplate, updateFormInstance } from 'actions/templates';

import {
	createMeetingInstanceRelationship,
	getMeeting,
	getMeetingInstance,
	updateMeetingInstance,
	deleteMeetingInstanceRelationship,
	deleteMeetingInstanceParticipant,
	updateMeetingInstanceDate,
	createMeetingFormInstance,
	deleteMeetingInstance,
} from 'actions/meetings';

import swal from 'sweetalert2';
import PostContent from 'components/Presentation/PostContent';
import NoteForm from 'containers/Forms/Note';

import DisplayFormResults from 'containers/FormTemplate/Display/DisplayFormResults';
import FormInstance from 'containers/FormTemplate/Display/FormInstance';
import Modal from 'containers/Modals/Modal';
import UserProgress from 'containers/Progress/User';

import UpdateMeetingInstance from '../Form/UpdateMeetingInstance';
import Message from 'containers/SinglePages/Partials/Message';
import MeetingUsers from 'components/List/MeetingUsers';
import BookMeeting from '../Form/BookMeeting';

import { Button, Block, Icon, Checkbox, Tabs, Tab, getActiveLanguage } from '@haldor/ui';
import { Spinner } from 'UI';

import './Meeting.scss';

const ics = require('ics');

class MeetingInstance extends Component {

	/* Class setup */
	constructor(props) {
		super(props);

		moment.locale(getActiveLanguage());

		this.state = {
			loading: true,
			loadingFormTemplate: false,
			creatingTeamsMeeting: false,
			saving: false,
			outcome: '',
			followUp: false,
			participants: [],
			noteForm: false,
			formInstance: null,
			answerFormModal: false,
			date: moment(),
			modalFormInstance: false,
			editMeetingInstanceModal: false,
			conversationMember: null,
			showPrivateNotes: false,
			instanceJustBeenCreated: false,
			dateNow: new Date(),
			studentLed: false,
		};
	}

	/* Lifecycle methods */
	componentDidMount = () => {
		const user = new User(this.props.user);

		if (!user.isStudent()) {
			let actions = [];
			this.props.setPageActions(actions);
		}

		this.loadInstance();
	}

	loadInstance() {
		const { getMeetingInstance, getMeeting, getNotesByReference, match } = this.props;
		let promises = [];

		this.setState({ loading: true });

		promises.push(getMeetingInstance(match.params.id, match.params.instanceId));

		if (this.props.services.notes)
			promises.push(getNotesByReference(match.params.instanceId, "MEETING_INSTANCE"));

		if (this.props.meeting == null || (this.props.meeting?.id != match.params.id)) {
			promises.push(getMeeting(match.params.id));
		}

		Promise.all(promises).then(() => {
			this.props.setPageTitle(this.props.meeting.title);

			let outcome = '';
			if (this.props.meetingInstance.outcome != null) {
				outcome = this.props.meetingInstance.outcome;
			}

			this.setState({
				loading: false,
				saving: false,
				outcome,
				date: this.props.meetingInstance.date != null ? moment.utc(this.props.meetingInstance.date).local() : null,
				endDate: this.props.meetingInstance.endDate != null ? moment.utc(this.props.meetingInstance.endDate).local() : null,
				description: this.props.meetingInstance.description,
				followUp: this.props.meetingInstance.followUp,
				participants: this.props.meetingInstance.participants.filter(t => t.present),
				studentLed: this.props.meetingInstance.studentLed
			});
		}).catch((er) => {
			console.log(er);
			this.setState({ notFound: true });
		})
	}

	/* Events */
	onParticipantSelect = async (participant) => {
		let found = this.state.participants.find(part => {
			return part.id == participant.id;
		});

		if (found == null) {
			participant.present = true;
			await this.setState({ participants: [...this.state.participants, participant] });
		} else {
			participant.present = false;
			await this.setState({ participants: [...this.state.participants.filter(t => t.id != participant.id)] });
		}

		this.saveInstance(false);
	}

	onRemoveParticipant = (participant) => {
		const { meeting, meetingInstance } = this.props;

		swal.fire({
			title: this.props.translate('are-you-sure'),
			text: this.props.translate('are-you-sure-you-want-to-delete'),
			showCancelButton: true,
			cancelButtonText: this.props.translate('No'),
			confirmButtonText: this.props.translate('Yes'),
		}).then(async response => {
			if (response.value != null) {
				await this.props.deleteMeetingInstanceParticipant(
					meeting.id,
					meetingInstance.id,
					participant.id
				);

				this.loadInstance();
			}
		});
	}

	onDeleteMeetingInstance = () => {
		const { meeting, meetingInstance } = this.props;

		swal.fire({
			title: this.props.translate('are-you-sure'),
			text: this.props.translate('are-you-sure-you-want-to-delete'),
			showCancelButton: true,
			cancelButtonText: this.props.translate('No'),
			confirmButtonText: this.props.translate('Yes'),
		}).then(async response => {
			if (response.value != null) {
				await this.props.deleteMeetingInstance(meeting.id, meetingInstance.id);
				this.props.history.push(`/meeting/${meeting.id}`);
			}
		});
	}

	saveInstance = async (reload = true) => {
		this.setState({ saving: true });

		let instance = {
			id: this.props.match.params.instanceId,
			meetingId: this.props.match.params.id,
			outcome: this.state.outcome,
			status: 'PUBLISHED',
			date: this.state.date,
			endDate: this.state.endDate,
			description: this.state.description,
			followUp: this.state.followUp,
			participants: this.state.participants,
			studentLed: this.state.studentLed,
			studentId: this.props.meetingInstance.studentId,
		};

		await this.props.updateMeetingInstance(instance);

		if (reload) {
			this.loadInstance();
		} else {
			this.setState({ saving: false });
		}
	}

	onFormInstanceClick = (form) => {
		const { meeting, meetingInstance } = this.props;

		var formTemplate = JSON.parse(JSON.stringify(form.form.reference.formTemplate));
		var formTemplateFields = JSON.parse(JSON.stringify(form.form.reference.formTemplate.fields));
		this.setState({ formTemplate, formTemplateFields, form: form.form.reference, formInstance: form.formInstance });

		if (form.participant.userID == this.props.user.id && meetingInstance.status != 'COMPLETED') {
			this.toggleAnswerForm();
		} else {
			this.toggleFormPreview();
		}
	}

	toggleFormPreview = () => {
		this.setState({ modalFormInstance: !this.state.modalFormInstance });
	}

	toggleAnswerForm = (loadData) => {
		this.setState({ answerFormModal: !this.state.answerFormModal }, () => {
			if (loadData) {
				this.loadInstance();
			}
		});
	}

	updateMeetingInstance = (values) => {
		return new Promise(async resolve => {
			if (values.staff != null) {
				values.staff.forEach((staff) => {
					values.participants.push({
						userId: staff.userId,
						userType: staff.userType
					});
				})
			}
			this.props.updateMeetingInstance(values).then(() => {
				this.toggleEditMeetingInstanceModal();
				this.loadInstance();
			})
		});
	}

	toggleNoteForm = (reload = false, values) => {
		return new Promise(resolve => {
			if (reload) {
				this.setState({ loading: true });
				const { instanceId, id } = this.props.match.params;

				let promises = [this.props.getMeetingInstance(id, instanceId),
				this.props.getNotesByReference(this.props.match.params.instanceId, "MEETING_INSTANCE")];

				Promise.all(promises).then(() => {
					this.setState({ noteForm: !this.state.noteForm, loading: false });
					this.props.resetActiveNote();
					resolve(1);
				});
			} else {
				this.setState({ noteForm: !this.state.noteForm });
				this.props.resetActiveNote();
				resolve(1);
			}
		});
	}

	onNoteClick = (note, isPublic) => {
		this.props.setActiveNote(note);
		this.setState({ noteForm: true });
	}

	onNoteRemove = (note) => {
		swal.fire({
			title: this.props.translate('are-you-sure'),
			text: this.props.translate('note-delete-prompt'),
			showCancelButton: true,
			cancelButtonText: this.props.translate('Cancel'),
			confirmButtonText: this.props.translate('Delete'),
		}).then(async (result) => {
			if (result.value != null) {
				await this.props.removeNote(note.id);
				this.props.getMeetingInstance(this.props.match.params.id, this.props.match.params.instanceId);
			}
		});
	}

	createTeamsMeeting = () => {
		this.setState({ creatingTeamsMeeting: true });

		return new Promise((resolve) => {
			let data = this.props.meetingInstance;
			data.addTeamsMeeting = true;

			this.props.updateMeetingInstance(data).then(async () => {
				await this.props.getMeetingInstance(this.props.match.params.id, this.props.match.params.instanceId);
				this.setState({ creatingTeamsMeeting: false });
				resolve(1);
			});
		});
	}

	unbookTeamsMeeting = () => {
		this.setState({ creatingTeamsMeeting: true });

		return new Promise((resolve) => {
			let data = this.props.meetingInstance;
			data.addTeamsMeeting = false;
			data.teamsMeeting = null;
			data.teamsMeetingData = null;

			this.props.updateMeetingInstance(data).then(async () => {
				await this.props.getMeetingInstance(this.props.match.params.id, this.props.match.params.instanceId);
				this.setState({ creatingTeamsMeeting: false });
				resolve(1);
			});
		});
	}

	toggleEditMeetingInstanceModal = () => {
		this.setState({ editMeetingInstanceModal: !this.state.editMeetingInstanceModal });
	}

	generateICS = () => {
		var date = moment.utc(this.props.meetingInstance.date).local().format('YYYY-M-D-H-m').split("-");
		date = date.map(point => {
			return parseInt(point, 10);
		});

		var endDate = moment.utc(this.props.meetingInstance.endDate).local();
		var duration = moment.duration(endDate.diff(moment.utc(this.props.meetingInstance.date).local()));
		var hours = Math.floor(duration.asHours());
		var minutes = Math.round(duration.asMinutes() - (hours * 60));

		let description = '';
		if (this.props.meetingInstance.description != null) {
			let meetingInstanceDescription = document.createElement('div');
			meetingInstanceDescription.innerHTML = this.props.meetingInstance.description;
			description += meetingInstanceDescription.innerText + "\n\n";
		}

		if (this.props.meeting.description != null) {
			let meetingDescription = document.createElement('div');
			meetingDescription.innerHTML = this.props.meeting.description;
			description += meetingDescription.innerText;
		}

		if (this.props.meetingInstance.teamsMeetingData != null && this.props.meetingInstance.teamsMeetingData.joinWebUrl != null) {
			description += `\n\n------------------\nAnslut till mötet:\n${this.props.meetingInstance.teamsMeetingData.joinWebUrl}`;
		}

		let uid = moment.utc(this.props.meetingInstance.date).local().toISOString();
		uid += '-M' + this.props.meeting.id;
		uid += '-I' + this.props.meetingInstance.id;
		description += '\n\n';

		const event = {
			title: this.props.meeting.title,
			description: description,
			start: date,
			startInputType: "local",
			startOutputType: "local",
			uid: uid + '@app.haldor.se',
			productId: 'haldor/education',
			duration: {
				hours: hours,
				minutes: minutes,
			},
			status: 'CONFIRMED',
			organizer: {
				name: this.props.currentUser.firstName,
				email: this.props.currentUser.email,
			},
		};

		ics.createEvent(event, (error, value) => {
			window.open("data:text/calendar;charset=utf-8," + encodeURI(value));
		});
	}

	/* Render methods */
	renderNote = (reference) => {
		const date = new DateTime(reference.created);

		if (!reference.public && !this.state.showPrivateNotes) {
			return null;
		}

		return (
			<div className="note" key={reference.id}>
				{reference.title != null ?
					<h3>{reference.title}</h3>
					: null}

				<p dangerouslySetInnerHTML={{ __html: reference.text }} />

				<p className="creator">
					{reference.author != null ?
						reference.author.firstName + " " + reference.author.lastName
						: null}

					<span style={{ fontWeight: 400 }}> {date.getFromNow()}</span>

					<span className="bull" style={{ fontWeight: 400 }}>
						{reference.public != null && !reference.public ?
							this.props.translate('private-note')
							: this.props.translate('shared-note')}
					</span>

					{reference.creator == this.props.user.id || reference.id == this.props.createdNote ?
						<span className="bull" style={{ fontWeight: 400, cursor: 'pointer' }} onClick={() => this.onNoteClick(reference)}>
							<Icon name="Pen_Small" /> {this.props.translate('Edit')}
						</span>
						: null}

					{reference.creator == this.props.user.id || reference.id == this.props.createdNote ?
						<span className="bull" style={{ fontWeight: 400, cursor: 'pointer' }} onClick={() => this.onNoteRemove(reference)}>
							<Icon name="Bin" /> {this.props.translate('Remove')}
						</span>
						: null}
				</p>
			</div>
		)
	}

	onConversation = (participant) => {
		this.setState({ conversationMember: participant })
	}

	onConversationClose = () => {
		this.setState({ conversationMember: null });
	}

	isUpdating = () => {
		this.setState({ loading: true });
		let promises = [
			this.props.getMeetingInstance(this.props.match.params.id, this.props.match.params.instanceId),
			this.props.getNotesByReference(this.props.match.params.instanceId, "MEETING_INSTANCE"),
		];

		Promise.all(promises).then(() => {
		});
	}

	isUpdated = () => {
		this.setState({ loading: false });
	}

	unbookMeeting = () => {
		swal.fire({
			title: this.props.translate('are-you-sure'),
			text: this.props.translate('are-you-sure-unbook-meeting'),
			showCancelButton: true,
			cancelButtonText: this.props.translate('No'),
			confirmButtonText: this.props.translate('Yes'),
		}).then(async result => {
			if (result.value != null) {
				this.setState({ loading: true })
				let instance = this.props.meetingInstance;
				instance.date = null;
				instance.endDate = null;
				this.props.updateMeetingInstanceDate(instance).then(() => {
					this.setState({ loading: false });
				});
			}
		});
	}

	completeMeeting = () => {
		swal.fire({
			title: this.props.translate('are-you-sure'),
			text: this.props.translate('are-you-sure-complete-meeting'),
			showCancelButton: true,
			cancelButtonText: this.props.translate('No'),
			confirmButtonText: this.props.translate('Yes'),
		}).then(async result => {
			if (result.value != null) {
				this.setState({ loading: true })
				let instance = this.props.meetingInstance;
				instance.status = "COMPLETED";
				this.props.updateMeetingInstance(instance).then(() => {
					this.props.history.push('/meeting/' + this.props.meeting.id);
				});
			}
		});
	}

	openMeeting = () => {
		swal.fire({
			title: this.props.translate('are-you-sure'),
			text: this.props.translate('are-you-sure-open-meeting'),
			showCancelButton: true,
			cancelButtonText: this.props.translate('No'),
			confirmButtonText: this.props.translate('Yes'),
		}).then(async (result) => {
			if (result.value != null) {
				this.setState({ loading: true })
				let instance = this.props.meetingInstance;
				instance.status = "ACTIVE";
				this.props.updateMeetingInstance(instance).then(() => {
					this.setState({ loading: false })
				});
			}
		});
	}

	onChangeStudentLed = () => {
		let instance = this.props.meetingInstance;
		instance.studentLed = !this.props.meetingInstance.studentLed;
		this.props.updateMeetingInstance(instance);
	}

	renderTeamsHtml = () => {
		var strHtml = this.props.meetingInstance.teamsMeetingData.joinHtml;
		const locale = getActiveLanguage();
		strHtml = strHtml.replace("Learn more about Teams", "");

		if (locale == 'sv-se') {
			strHtml = strHtml.replace("Join Microsoft Teams Meeting", "Anslut till Microsoft Teams-möte");
			strHtml = strHtml.replace("Meeting options", "Mötesalternativ");
			strHtml = strHtml.replace("Learn more about Teams", "Läs mer om Teams");
			strHtml = strHtml.replace("en-US", "sv-SE");
		}

		strHtml = strHtml.replace("width:100%;height: 20px;", "width:100%;height: 1px;");
		strHtml = strHtml.replace("margin-top: 24px;", "margin-top: 5px;");
		strHtml = strHtml.replace("white-space:nowrap;color:gray;opacity:.36;", "");
		strHtml = strHtml.replace("white-space:nowrap;color:gray;opacity:.36;", "");
		strHtml = strHtml.replace("|", "");
		strHtml = strHtml.replace("________________________________________________________________________________", "<hr/>");
		strHtml = strHtml.replace("________________________________________________________________________________", "<hr/>");

		return <div dangerouslySetInnerHTML={{ __html: strHtml }} />
	}

	renderTeamsHtmlFromData = () => {
		const isTeacher = isUserTeacher(this.props.currentUser);
		const isAdmin = isUserAdministrator(this.props.currentUser);

		return (
			<div>
				<Button onClick={this.onJoinTeamsMeeting} type="target">
					{this.props.translate('join-teamsmeeting')}
				</Button>

				{(isTeacher || isAdmin) && this.props.schedule.teamsMeetingData != null ?
					<Button onClick={this.onOptionsTeamsMeeting} type="target">
						{this.props.translate('options-teamsmeeting')}
					</Button>
					: null}
			</div>
		)
	}

	renderTeamsSettings = () => {
		const { meeting } = this.props;

		if (meeting.status == 'COMPLETED') {
			return null;
		}

		const isTeacher = isUserTeacher(this.props.currentUser);
		const isAdmin = isUserAdministrator(this.props.currentUser);

		if (!this.props.services.teamsMeeting) {
			return null;
		}

		return <div>
			{this.props.meetingInstance.teamsMeeting != null ?
				<div style={{ marginTop: '1rem' }}>
					{this.props.meetingInstance.teamsMeetingData.joinHtml != null ?
						this.renderTeamsHtml()
						: this.renderTeamsHtmlFromData()}
				</div>
				: ((isTeacher && this.props.currentUser.id == this.props.meeting.creator) || isAdmin) ?
					<div className="action-section">
						<Button onClick={this.createTeamsMeeting} type="secondary" style={{ marginTop: 5 }} disabled={this.state.creatingTeamsMeeting}>
							{this.state.creatingTeamsMeeting ?
								<Spinner />
								: null}

							{this.props.translate('create-teamsmeeting')}
						</Button>
					</div>
					: null}
		</div>
	}

	getMeetingForms() {
		// Hämtar alla formulär (exkl. Follow up)
		let forms = this.props.meeting.relationships.filter((t) => {
			if (t.referenceType == "FORM" && t.targetedAudience != "FOLLOWUP") {
				return true;
			}
			return false;
		}).map(t => t);

		// Mappar mot insända formulär
		const user = new User(this.props.user);
		const { meetingInstance } = this.props;
		let returnForms = [];

		if (user.isStudent() && !this.props.meetingInstance.studentLed) {
			forms.forEach(form => {
				meetingInstance.participants.forEach(participant => {
					if (participant.userID == user.data.userId && form.targetedAudience == "STUDENT") {
						let instance = null;
						meetingInstance.relationships.forEach(meetingRelationship => {
							if (meetingRelationship.referenceType == 'FORMINSTANCE' && form.referenceID == meetingRelationship.reference.formID && meetingRelationship.reference.assignedTo == user.data.userId) {
								instance = meetingRelationship;
							}
						});
						returnForms.push({
							form: form,
							participant,
							formInstance: instance,
						});
					}
				});
			});
			return returnForms;
		}

		if (user.isStudent() && this.props.meetingInstance.studentLed) {
			forms.forEach(form => {
				meetingInstance.participants.forEach(participant => {
					if (participant.userType == form.targetedAudience) {
						let formInstance = null;
						this.props.meetingInstance.relationships.forEach((relationship) => {
							if (relationship.referenceType == "FORMINSTANCE" && relationship.reference.formID == form.referenceID && relationship.reference.assignedTo == participant.userID) {
								formInstance = relationship;
							}
						})
						returnForms.push({
							form,
							participant,
							formInstance
						})
					}
				});
			});
			return returnForms;
		};

		forms.forEach((form) => {
			meetingInstance.participants.forEach((participant) => {
				if (participant.userType == form.targetedAudience) {
					let formInstance = null;
					this.props.meetingInstance.relationships.forEach((relationship) => {
						if (relationship.referenceType == "FORMINSTANCE" && relationship.reference.formID == form.referenceID && relationship.reference.assignedTo == participant.userID) {
							formInstance = relationship;
						}
					})

					returnForms.push({
						form,
						participant,
						formInstance
					})
				}
			});
		});
		return returnForms;
	}

	getFollowUpForms() {
		let forms = this.props.meeting.relationships.filter((t) => {
			if (t.referenceType == "FORM" && t.targetedAudience == "FOLLOWUP") {
				return true;
			}
			return false;
		}).map(t => t);

		const user = new User(this.props.user);
		let returnForms = [];

		if (user.isStudent() && !this.props.meetingInstance.studentLed) {
			forms.forEach((form) => {
				let formInstance = null;
				this.props.meetingInstance.relationships.forEach((relationship) => {
					if (relationship.referenceType == "FORMINSTANCE") {
						if (relationship.reference.formID == form.referenceID && this.props.meetingInstance.status == "COMPLETED") {
							formInstance = relationship;
							returnForms.push({
								form,
								formInstance
							})
						}
					}
				})
			});
		} else if (user.isStudent() && this.props.meetingInstance.studentLed) {
			forms.forEach((form) => {
				let formInstance = null;
				this.props.meetingInstance.relationships.forEach((relationship) => {
					if (relationship.referenceType == "FORMINSTANCE") {
						if (relationship.reference.formID == form.referenceID) {
							formInstance = relationship;
						}
					}
				})

				returnForms.push({
					form,
					formInstance
				})
			});
		} else if (!user.isStudent()) {
			forms.forEach((form) => {
				let formInstance = null;
				this.props.meetingInstance.relationships.forEach((relationship) => {
					if (relationship.referenceType == "FORMINSTANCE") {
						if (relationship.reference.formID == form.referenceID) {
							formInstance = relationship;
						}
					}
				})

				returnForms.push({
					form,
					formInstance
				})
			});
		}

		return returnForms;
	}

	renderForm = (form, index) => {
		const user = new User(this.props.user);
		if (!user.isStudent() || form.participant.userID == user.data.id) {
			return (
				<div key={index} onClick={() => this.onFormInstanceClick(form)} className="form-item">
					<div className="cell title">
						{form.form.reference.title}
					</div>

					<div className="cell">
						{form.participant.user.firstName} {form.participant.user.lastName}
					</div>

					<div className="cell">
						{form.formInstance != null ? new DateTime(form.formInstance.reference.edited).getLongDateWithTime() : ''}
					</div>

					<div className="cell">
						{form.formInstance?.reference.status == 'SUBMITTED' ?
							this.props.translate('turned-in')
							: this.props.translate('not-turned-in')}
					</div>
				</div>
			)
		} else {
			return null;
		}
	}

	submitFormInstance = (values) => {
		return new Promise((resolve) => {
			if (values.id == null) {
				values.formID = this.state.form.id

				this.props.createMeetingFormInstance(this.props.meeting.id, this.props.meetingInstance.id, values)
					.then(() => {
						resolve(1);
					})
			}
		})
	}

	submitFollowUpForm = (values) => {
		return new Promise((resolve) => {
			if (values.id == null) {
				this.props.createMeetingFormInstance(this.props.meeting.id, this.props.meetingInstance.id, values)
					.then(() => {
						this.props.getMeetingInstance(this.props.meeting.id, this.props.meetingInstance.id);
						resolve(1);
					})
			}
		})
	}

	toggleBooking = (instance) => {
		this.setState({ bookingModal: !this.state.bookingModal, bookingInstance: instance });
	}

	render() {
		if (this.state.loading) {
			return <Spinner center />
		} else if (this.state.loading && !this.state.notFound) {
			return <Spinner center />
		} else if (this.state.notFound) {
			return <div className="single-task"><h3>{this.props.translate('not-found')}</h3></div>
		}

		const user = new User(this.props.user);
		const { meetingInstance, meeting, translate } = this.props;
		const { student } = this.props.meetingInstance;
		var notes = [];
		meetingInstance.relationships.forEach((relationship) => {
			if (relationship.referenceType == "NOTE" && relationship.reference != null) {
				relationship.reference.public = true;
				return notes.push(relationship.reference);
			}
		});

		this.props.notes.forEach((note) => {
			if (notes.findIndex(t => t.id == note.id) == -1) {
				note.public = false;
				notes.push(note);
			}
		});

		notes.sort((a, b) => {
			return new Date(b.created) - new Date(a.created);
		});

		let isPreschool = this.props.currentUser.schools.getActiveSchool(this.props.currentUser.userId).type == PRESCHOOL;

		var forms = this.getMeetingForms();
		var followUpForms = this.getFollowUpForms();
		let hasBooked = meetingInstance.date != null;
		let hasFilledForms = true;
		let nrOfForms = 0;
		let nrOfSubmittedForms = 0;
		let isAuthoritative = this.props.user.authoritative != null && this.props.user.authoritative ? true : false;
		if (forms != null) {
			if (forms.filter((form) => (form.formInstance == null) || (form.formInstance != null && form.formInstance.reference.status == "SAVED")).length > 0) {
				hasFilledForms = false;
			}

			nrOfForms = forms.length;
			nrOfSubmittedForms = forms.filter((form) => form.formInstance != null && form.formInstance.reference.status == "SUBMITTED").length;
		}

		let formLocked = this.props.meetingInstance.status == 'COMPLETED' || this.props.meeting.status == 'COMPLETED';
		
		let startDate = new Date(this.props.meetingInstance.date);
        let dateNow = new Date(this.state.dateNow);

		return (
			<div className="single-task meeting single-instance">
				<Modal isOpen={this.state.modalFormInstance} type="small" overridePrompt={true} onClose={this.toggleFormPreview} title={translate('answers')}>
					<DisplayFormResults
						fields={this.state.formTemplateFields}
						instance={this.state.formInstance?.reference}
					/>
				</Modal>

				<Modal
					isOpen={this.state.noteForm}
					type="small"
					onClose={() => this.toggleNoteForm()}
					title={this.props.activeNote ? translate('edit-meeting-note') : translate('new-meeting-note')}
				>
					<NoteForm
						onAbort={this.toggleNoteForm}
						reference="MEETING_INSTANCE"
						id={meetingInstance.id}
						shareLabel={this.props.translate('private-meeting-note')}
						references={[
							{
								referenceID: meetingInstance.id,
								referenceType: 'MEETING_INSTANCE'
							},
							{
								referenceID: meetingInstance.studentId,
								referenceType: 'STUDENT'
							},
						]}
					/>
				</Modal>

				<Modal type="small" isOpen={this.state.bookingModal} onClose={(instance) => this.toggleBooking(instance)} title={this.props.translate('book-meeting-time')}>
					<BookMeeting
						onClose={(instance) => { this.toggleBooking(instance) }}
						meeting={this.props.meeting}
						instance={this.props.meetingInstance}
					/>
				</Modal>

				<Modal isOpen={this.state.editMeetingInstanceModal} type="small" onClose={this.toggleEditMeetingInstanceModal} title={translate('invite-participants')}>
					<UpdateMeetingInstance onSubmit={this.updateMeetingInstance} meetingInstance={this.props.meetingInstance} />
				</Modal>

				<Modal isOpen={this.state.answerFormModal} onClose={this.toggleAnswerForm} title={translate('forms')}>
					{this.state.formTemplate != null ?
						<FormInstance
							form={this.state.formTemplate.id.toString()}
							fields={this.state.formTemplateFields}
							instance={this.state.formInstance?.reference}
							onAbort={this.toggleAnswerForm}
							onSubmit={this.submitFormInstance}
							user={user}
							followUp={false}
							disabled={meeting.status == 'COMPLETED'}
						/>
						: <Spinner center />}
				</Modal>

				{this.props.services.conversations ?
					<Message
						activeUser={this.state.conversationMember}
						meetingInstance={meetingInstance}
						onClose={this.onConversationClose}
						studentId={this.props.meetingInstance.studentId}
					/>
					: null}

				<div className="single-section form left-side" style={{ paddingTop: 0 }}>
					<Tabs>
						<Tab title={translate('meeting-instance')} route="info">
							{user.isStudent() && (!hasBooked || !hasFilledForms) && meeting.status == 'ACTIVE' && meetingInstance.status != 'COMPLETED' ?
								<Block>
									<span className="title">{translate('To do')}</span>

									<div>
										{!hasBooked && isAuthoritative ?
											<div>
												<div
													onClick={() => this.toggleBooking(meetingInstance)}
													style={{ display: 'inline-block', marginBottom: '.35rem', cursor: 'pointer' }}
												>
													<Checkbox
														value={false}
														label={translate('book-meeting-time')}
														disabled
													/>
												</div>
											</div>
											: null}

										{!hasFilledForms ?
											<a
												href={`#forms`}
												style={{ marginBottom: '.35rem', cursor: 'pointer', color: '#3a3a3a', textDecoration: 'none' }}
											>
												<Checkbox
													value={false}
													label={translate('fill-form')}
													disabled
												/>
											</a>
											: null}
									</div>
								</Block>
								: null}

							<Block>
								{meeting.status == 'COMPLETED' ?
									<div style={{ marginBottom: '.55rem' }} className="color--meta size-14">
										{translate('meeting-closed')}
									</div>
									: null}

								{meetingInstance.studentLed ?
									<div style={{ marginBottom: '.55rem' }} className="color--meta size-14">
										{translate('studentled-meeting')}
									</div>
									: null}

								<span className="title">{translate('Information-to-participants')}</span>

								<div className="size-14">
									<PostContent>{meeting.description}</PostContent>
									<PostContent>{meetingInstance.description}</PostContent>
								</div>

								{!user.isStudent() && meeting.creator == user.data.id && !isPreschool ?
									<div style={{ marginTop: '1rem' }}>
										<span className="title">{translate('studentled')}</span>

										<Checkbox
											value={this.state.studentLed}
											disabled={meeting.status == "COMPLETED" || meetingInstance.status == "COMPLETED"}
											label={translate('studentled-meeting')}
											onChange={() => { this.onChangeStudentLed() }}
										/>
									</div>
									: null}

								<div style={{ marginTop: '1rem' }}>
									<span className="title">
										{user.isStudent() || meeting.creator != user.data.id ?
											translate('participants')
											: translate('attending')}
									</span>

									<MeetingUsers
										meetingInstance={meetingInstance}
										users={meetingInstance.participants}
										onConversation={this.onConversation}
										onParticipantSelect={this.onParticipantSelect}
										onParticipantRemove={this.onRemoveParticipant}
										isStudent={user.isStudent() || meeting.creator != user.data.id}
									/>

									{(meeting.status == 'ACTIVE' || meeting.status == 'DRAFT') && !user.isStudent() && user.data.id == meeting.creator ?
										<Button
											disabled={meetingInstance.status == 'COMPLETED' ? true : false}
											type="secondary"
											style={{ marginTop: '.5rem' }}
											onClick={this.toggleEditMeetingInstanceModal}>
											<Icon name="Plus" /> {this.props.translate('invite-participants')}
										</Button>
										: null}
								</div>

								{meetingInstance.date != null ?
									<div style={{ marginTop: '1rem' }}>
										<span className="title">{translate('time')}</span>
										{new DateTime(meetingInstance.date).getLongDate()}
										{' • '}
										{new DateTime(meetingInstance.date).getTime()}

										{meetingInstance.endDate != null ?
											' - ' + new DateTime(meetingInstance.endDate).getTime()
											: null}
									</div>
									: null}
							</Block>
						</Tab>

						{forms.length > 0 ?
							<Tab title={user.isStudent() ? translate('forms') : <span>{translate('forms')} <div style={{ marginLeft: "0.5rem" }} className="new-badge"><span>{nrOfSubmittedForms.toString()} / {nrOfForms.toString()}</span> </div> </span>} route="forms">
								<Block>
									<span className="title">{translate('forms')}</span>

									<div className="meeting-forms">
										{forms.map(this.renderForm)}
									</div>
								</Block>
							</Tab>
							: null}

						{this.props.services.notes ?
							<Tab title={translate('meeting-notes')} route="meeting-notes">
								<Block>
									<div className="notes">
										<span className="title">
											{translate('meeting-notes')}
										</span>

										{!user.isStudent() ?
											<Checkbox
												value={this.state.showPrivateNotes}
												label={translate('show-private-notes')}
												onChange={(value) => this.setState({ showPrivateNotes: value })}
											/>
											: null}

										<div style={{ marginTop: '1.55rem' }} />

										{notes.map((note) => {
											return this.renderNote(note)
										})}

										{notes.length == 0 ?
											<div style={{ marginBottom: '1.25rem' }}>
												{this.props.translate('no-notes')}
											</div>
											: null}

										{!user.isStudent() ?
											<Button
												disabled={meeting.status == 'COMPLETED'}
												data-tooltip={meeting.status == 'COMPLETED' ? translate('meeting-is-locked-notes') : null}
												type="secondary"
												onClick={() => this.toggleNoteForm()}
											>
												<Icon name="Plus" />

												{translate('new-meeting-note')}
											</Button>
											: null}
									</div>
								</Block>
							</Tab>
							: null}
					</Tabs>
				</div>

				<div className="single-actions right-side" style={{ paddingTop: 0 }}>
					{!user.isStudent() && meeting.creator == user.data.id ?
						<div className="action-section">
							<h3>{this.props.translate('tools')}</h3>

							<div>
								{(this.props.meetingInstance.status != "COMPLETED" && this.props.meeting.status != 'COMPLETED') ?
									<Button onClick={this.completeMeeting}>
										{this.props.translate('close-meeting')}
									</Button>
									:
									(this.props.meeting.status != 'COMPLETED') ?
										<Button onClick={this.openMeeting} type="secondary">
											{this.props.translate('open-meeting-again')}
										</Button>
										: <div className="color--meta" style={{ marginBottom: '.75rem' }}>
											{this.props.translate('Meeting is archived. Unlock meeting to make changes.')}
										</div>
								}
							</div>

							<div>
								{this.props.meetingInstance.status != 'COMPLETED' && this.props.meeting.status != 'COMPLETED' ?
									<Button type="secondary" onClick={this.onDeleteMeetingInstance} style={{ marginTop: 5 }}>
										<Icon name="Bin" /> {this.props.translate('Delete')}
									</Button>
									: null}
							</div>

							{this.props.meeting.status != 'COMPLETED' && this.props.meetingInstance.date != null && this.props.meetingInstance.endDate != null ?
								<div>
									<Button onClick={this.unbookMeeting} type="secondary" style={{ marginTop: 5 }}>
										{this.props.translate('unbook')}
									</Button>
								</div>
								:
								this.props.meetingInstance.teamsMeeting != null ?

									// <div>
									// 	<Button onClick={this.unbookMeeting} type="secondary" style={{ marginTop: 5 }}>
									// 		{this.props.translate('unbook-teams-meeting')}
									// 	</Button>
									// </div>
									null
									: null
							}

							{this.props.meetingInstance.date != null && this.props.meetingInstance.endDate != null ?
								<div>
									<Button onClick={this.generateICS} type="secondary" style={{ marginTop: 5 }}>
										{this.props.translate('calendar-file')}
									</Button>
								</div>
								: null}

							{this.renderTeamsSettings()}
						</div>
						:
						<div className="action-section">
							<h3>{this.props.translate('tools')}</h3>
							{this.renderTeamsSettings()}
						</div>}

					{(user.isStudent() && isAuthoritative && meetingInstance.status != 'COMPLETED' && meeting.status == 'ACTIVE' && startDate > dateNow) ?
						<div className="single-actions" style={{ paddingTop: 0 }}>
							<div>
								<Button type="secondary" onClick={() => { this.toggleBooking(this.props.meetingInstance) }}>
									{this.props.translate('book-meeting-time')}
								</Button>
							</div>

							{this.props.meetingInstance.date != null && this.props.meetingInstance.endDate != null ?
								<div>
									<Button 
									onClick={this.unbookMeeting} 
									type="secondary" 
									style={{ marginTop: 5 }}
									>
										{this.props.translate('unbook')}
									</Button>
								</div>
								: null}
						</div>
						: null}
				</div>

				<div className="clearfix"></div>

				{followUpForms.length > 0 ?
					<Block>
						<span className="title">
							{translate('follow-up')}
						</span>

						<div className="df aic save-error-notice">
							<div className="ico">
								<Icon name="Alert_Red" />
							</div>

							<div>
								{translate('If the form was opened by several people at the same time, there is a risk that information may be overwritten by mistake.')}

								{getActiveLanguage() == 'sv-se' ?
									<div
										dangerouslySetInnerHTML={{
											__html: translate('<a href="%LINK%" target="_blank">Read more in the Knowledge Bank</a>')
										}}
									/>
									: null}
							</div>
						</div>

						{followUpForms.map((form, i) => {
							return (
								<div key={i}>
									<span className="title">{form.form.reference.title}</span>

									<FormInstance
										form={form.form.referenceID}
										fields={form.form.reference.formTemplate.fields}
										formTemplate={form.form.reference.formTemplate}
										instance={form.formInstance?.reference}
										user={user}
										instanceJustBeenCreated={this.state.instanceJustBeenCreated}
										onSubmit={this.submitFollowUpForm}
										references={forms}
										followUp={true}
										studentLed={this.props.meetingInstance.studentLed}
										locked={formLocked}
										shrinkFields
									/>
								</div>
							)
						})}
					</Block>
					: null}

				<div style={{ marginTop: '3rem' }} />

				<Block>
					<span className="title">{this.props.translate('student-card')}</span>

					<UserProgress
						userId={student.id}
						isupdated={this.isUpdated}
						isupdating={this.isUpdating}
					/>
				</Block>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		meeting: state.Meetings.meeting,
		meetingInstance: state.Meetings.meetingInstance,
		notes: state.Notes.notes,
		createdNote: state.Notes.new,
		formTemplate: state.Templates.formTemplate,
		instance: state.Templates.instance,
		user: state.user.currentUser,
		languages: state.Languages.languages,
		services: state.Services.availableServices,
		currentUser: state.user.currentUser,
		activeNote: state.Notes.active,
	};
}

export default withRouter(connect(mapStateToProps, {
	getMeetingInstance,
	createMeetingInstanceRelationship,
	setPageTitle,
	getMeeting,
	updateMeetingInstance,
	getNotesByReference,
	getFormTemplate,
	setActiveNote,
	resetActiveNote,
	deleteMeetingInstanceRelationship,
	getForm,
	getFormInstance,
	updateFormInstance,
	setPageActions,
	removeNote,
	updateMeetingInstanceDate,
	createMeetingFormInstance,
	addError,
	deleteMeetingInstanceParticipant,
	deleteMeetingInstance,
})(MeetingInstance));