import React, { Component } from 'react';
import { connect } from 'react-redux';

import api from 'lib/api';
import getEnvironment from 'lib/env';
import Modal from 'containers/Modals/Modal';

import { Spinner } from 'UI';
import { Button, Icon, translate } from '@haldor/ui';
import { getOnBehalfOfAccessToken } from 'actions/user';

const modalStyles = {
	content: {
		width: '60%',
		margin: '0 auto',
		marginBottom: '40px',
		padding: '0',
		borderRadius: '7px',
	},
};

class OnedrivePicker extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loadingPicker: false,
			loading: false,
			modal: false,
			graphPickerUrl: 'https://graph.microsoft.com/beta/me',
			activeSelection: 'me',
		}
	}

	getAuthenticationToken = async () => {
		return new Promise(async (resolve) => {
			await this.props.getOnBehalfOfAccessToken(this.props.user.userId, "Files.ReadWrite.All");
			if (this.props.access_token == null) {
				await this.props.getOnBehalfOfAccessToken(this.props.user.userId, "Sites.ReadWrite.All");
			}
			resolve(this.props.access_token);
		});
	}
	launchOneDrivePicker = (planId) => {
		const that = this;

		const odOptions = {
			clientId: getEnvironment().clientId,
			action: 'query',
			multiSelect: true,
			openInNewWindow: true,
			advanced: {
				queryParameters: 'select=id,name,size,file,folder,photo,webUrl,webDavUrl,location,@microsoft.graph.downloadUrl',
				redirectUri: window.location.origin + `/plan/callback`
			},
			success(files) {
				let pickedItems = [];

				files.value.forEach((file) => {
					const oneDriveFile = { id: 0, name: file.name, url: file['@microsoft.graph.downloadUrl'] || file.webDavUrl, type: file.file ? file.file.mimeType : 'unknown/mime-type' };
					pickedItems.push(oneDriveFile);
				});

				that.setState({ forceUpdate: true, pickedItems }, () => {
					that.props.onUpdate(pickedItems);
				});
			},
			cancel() { },
			error(e) {
				console.log(e);
			},
		};

		OneDrive.open(odOptions);
	}

	render() {
		return (
			<div className="upload-wrapper" key="upload-wrapper-picker">
				<div id="upload" className="upload-container">
					<Button onClick={(e) => { e.preventDefault(); this.launchOneDrivePicker(); }}>
						{this.props.translate('Add files')}
					</Button>
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		groups: state.sections.educationGroups,
		user: state.user.currentUser,
		access_token: state.user.accessToken
	}
}

export default connect(mapStateToProps, { getOnBehalfOfAccessToken })(OnedrivePicker);