
import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import Moment from 'moment';

import { ROLE_ADMINISTRATOR } from 'constants/roles';

import { deleteAbsence, submitAbsence, updateAbsence } from 'actions/absence';
import { getSchoolAbsence, getSchoolDetails, getSchoolStudents } from 'actions/schools';
import { searchUser } from 'actions/user';
import { setPageTitle } from 'actions/header';

import DatePicker from 'react-datepicker';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import sv from 'date-fns/locale/sv';
import no from 'date-fns/locale/nb';
import en from 'date-fns/locale/en-US';
import da from 'date-fns/locale/da';
import uk from 'date-fns/locale/uk';
import ReportAbsenceForm from 'containers/Forms/Absence/ReportAbsence';
import Modal from 'containers/Modals/Modal';
import Card, { CardsContainer, CardTitle } from 'components/Cards/Card';
import SectionTitle from 'components/Presentation/SectionTitle';
import Redirect from 'components/Redirect/Redirect';

import { Spinner } from 'UI';
import { Button, getActiveLanguage } from '@haldor/ui';

class AbsenceOverview extends Component {

	constructor(props) {
		super(props);

		registerLocale('en-US', en);
		registerLocale('sv-SE', sv);
		registerLocale('nb-NO', no);
		registerLocale('da-DK', da);
		registerLocale('uk', uk);
		setDefaultLocale('en-US');

		this.state = {
			absenceModal: false,
			submitting: false,
			date: Moment(),
			searchDate: Moment(),
			schoolId: null,
			selectedUser: null,
			searchString: '',
			searching: false,
			searchedOnce: false,
			admin: null,
			loading: true,
		};
	}

	onDateChange = (date) => {
		this.setState({ date: Moment(date) });
	}

	onSubmit = () => {
		this.setState({ submitting: true, searchDate: this.state.date });

		this.props.getSchoolAbsence(this.props.activeSchoolId, encodeURIComponent(this.state.date.format()))
			.then(() => {
				this.setState({ submitting: false });
			});
	}

	onSearch = (event) => {
		event.preventDefault();
		const { searchString } = this.state;

		if (searchString.length < 3) {
			return false;
		}

		this.setState({ searching: true });
		this.props.searchUser(searchString).then(() => {
			this.setState({ searching: false, searchedOnce: true });
		});
	}

	UNSAFE_componentWillMount = () => {
		this.props.setPageTitle(this.props.translate('absence-today'));
	}

	componentDidMount() {
		const school = this.props.user.schools.find(school => {
			return school.id == this.props.activeSchoolId;
		});

		if (school != null) {
			let isAdmin = school.userRoles.find(role => {
				return role.toUpperCase() == ROLE_ADMINISTRATOR;
			});

			this.setState({ admin: isAdmin != null });

			if (isAdmin != null) {
				this.props.getSchoolDetails(school.id);
				this.props.getSchoolStudents(school.id);
				this.props.getSchoolAbsence(school.id, encodeURIComponent(this.state.date.format())).then(() => {
					this.setState({ loading: false });
				});
			}
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.activeSchoolId == null && nextProps.activeSchoolId != null) {
			const schoolId = nextProps.activeSchoolId;

			this.setState({ loading: true });
			nextProps.getSchoolDetails(schoolId);
			nextProps.getSchoolStudents(schoolId);
			nextProps.getSchoolAbsence(schoolId, encodeURIComponent(this.state.date.format())).then(() => {
				this.setState({ loading: false });
			});
		}
	}

	onAbsenceSubmit = (values) => {
		return new Promise((resolve, reject) => {
			let data = [values];

			if (values.id != null) {
				this.props.updateAbsence(data).then(() => {
					resolve(1);
					this.onSubmit();
				}).catch(() => {
					reject(0);
				})
			} else {
				this.props.submitAbsence(data).then(() => {
					resolve(1);
					this.onSubmit();
				}).catch(() => {
					reject(0);
				});
			}
		});
	}

	onUserClick = (user, absence) => {
		if (absence != null) {
			this.setState({
				absenceModal: true,
				selectedUser: user,
				selectedAbsence: absence,
			});
		} else {
			this.setState({
				absenceModal: true,
				selectedUser: user,
				selectedAbsence: null,
			});
		}
	}

	closeModal = () => {
		this.setState({
			absenceModal: !this.state.absenceModal,
			selectedUser: null,
			selectedAbsence: null,
		});
	}

	onDelete = (id) => {
		this.props.deleteAbsence(id).then(() => {
			this.closeModal();
		})
	}

	getDateFormat = () => {
		let format = Moment.localeData(getActiveLanguage()).longDateFormat("L");
		let newFormat = format.replace("YYYY", "yyyy");
		let days = newFormat.replace("DD", "dd");
		return days;
	};

	normalizeLocale = (locale) => {
		const [language, region] = locale.split('-');

		if (language.toUpperCase() == "UK"){
			return `uk`;
		}
		return `${language}-${region.toUpperCase()}`;
	};

	render() {
		const { translate, students } = this.props;
		const { selectedUser, selectedAbsence } = this.state;

		if (this.props.user == null || this.state.admin == null) {
			return <Spinner center />
		}

		if (this.state.admin != null && !this.state.admin) {
			return <Redirect to="/" />
		}

		let modalTitle = translate('register-absence');
		if (selectedUser != null) {
			modalTitle = translate('manage-absence-for') + ' ' + selectedUser.firstName + ' ' + selectedUser.lastName;
		}

		return (
			<div className="overview">
				<Modal isOpen={this.state.absenceModal} onClose={this.closeModal} title={modalTitle}>
					<ReportAbsenceForm
						onModalClose={this.closeModal}
						onSubmit={this.onAbsenceSubmit}
						user={selectedUser}
						absence={selectedAbsence}
						onDelete={this.onDelete}
					/>
				</Modal>

				<div className="single-section form" style={{ paddingTop: 0 }}>
					<div className="form-row">
						<div className="form-row-half">
							<h3>{translate('view-absence-for')}</h3>

							<DatePicker
								selected={this.state.date.toDate()}
								dateFormat={this.getDateFormat()}
								onChange={this.onDateChange}
								popperModifiers={{
									preventOverflow: {
										enabled: true,
									},
									positionFixed: false,
								}}
								popperPlacement="bottom-start"
								showWeekNumbers
								fixedHeight
								previousMonthButtonLabel=""
								nextMonthButtonLabel=""
								locale={this.normalizeLocale(getActiveLanguage())}
								{...this.state.date}
							/>

							<div style={{ paddingTop: '1rem' }}>
								<Button onClick={this.onSubmit} style={{ minWidth: '160px' }}>
									{this.state.submitting ?
										<Spinner center />
										: null}
									{translate('update')}
								</Button>
							</div>
						</div>

						<div className="clearfix"></div>

						<div style={{ marginTop: '2rem' }} />

						{this.state.loading ?
							<Spinner center />
							: this.props.reportedAbsence != null ?
								<CardsContainer>
									{this.props.reportedAbsence.length > 0 ? this.props.reportedAbsence.map(absence => {
										return <Card
											type="group"
											id={absence.user.userId}
											key={absence.id + absence.reported}
											onClick={e => this.onUserClick(absence.user, absence)}
										>
											<span className="color--meta size-12">
												{absence.user.email}
											</span>

											<CardTitle>{absence.user.firstName} {absence.user.lastName}</CardTitle>

											<div className="card-meta">
												{this.props.translate(absence.type)}
											</div>

											<br />

											<div className="card-meta">
												{absence.period ? Moment.utc(absence.startTime).local().format('Do MMMM YYYY') : Moment.utc(absence.startTime).local().format('Do MMMM YYYY - HH:mm')}
											</div>

											<br />

											<div className="card-meta">
												{absence.period ? Moment.utc(absence.endTime).local().format('Do MMMM YYYY') : Moment.utc(absence.endTime).local().format('Do MMMM YYYY - HH:mm')}
											</div>

										</Card>
									}) : <div className="color--meta text--center weight--bold x">
										{this.props.translate('no-results')}
									</div>}
								</CardsContainer>
								: null}
					</div>

					<div style={{ marginTop: '3rem' }} />

					<SectionTitle>
						<span>{this.props.translate('report-absence-for-student')}</span>
					</SectionTitle>

					<div className="form-row spacing">
						<div className="form-row-half">
							<form onSubmit={this.onSearch}>
								<input
									type="text"
									value={this.state.searchString}
									onChange={e => this.setState({ searchString: e.target.value })}
									placeholder={translate('search') + '...'}
								/>

								<div style={{ paddingTop: '1rem' }}>
									<Button style={{ minWidth: '160px' }} disabled={this.state.searchString.length < 3}>
										{translate('search')}
									</Button>
								</div>

								<div className="clearfix"></div>
							</form>

							<div className="clearfix"></div>
						</div>
					</div>

					<div className="clearfix"></div>

					<div style={{ marginTop: '2rem' }}>
						<CardsContainer>
							{this.state.searching ?
								<Spinner center />
								: this.props.searchResults != null && this.props.searchResults.length > 0 ?
									this.props.searchResults.map((student) => (
										<Card
											type="group"
											id={student.userId}
											key={`student-${student.userId}`}
											onClick={e => this.onUserClick(student)}
										>
											<span className="color--meta size-12">
												{student.email}
											</span>

											<CardTitle>
												{student.firstName} {student.lastName}
											</CardTitle>
										</Card>
									)) :
									this.state.searchedOnce ?
										<div className="color--meta text--center weight--bold x">
											{this.props.translate('no-results')}
										</div>
										: null}
						</CardsContainer>
					</div>
				</div>

				<div className="clearfix"></div>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		activeSchoolId: state.user.activeSchool,
		translate: translate(state.Languages.translations),
		school: state.School.active,
		user: state.user.currentUser,
		students: state.School.students,
		searchResults: state.user.searchResults,
		reportedAbsence: state.School.absence,
	};
}

export default connect(mapStateToProps, {
	getSchoolStudents,
	getSchoolAbsence,
	getSchoolDetails,
	submitAbsence,
	updateAbsence,
	searchUser,
	deleteAbsence,
	setPageTitle,
})(AbsenceOverview);
