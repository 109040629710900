import {
	getMeeting,
	updateMeeting,
	sendMeetingInstanceTimeBookingReminder,
} from 'actions/meetings';

import {
	getMeetingTimeBlocks,
	deleteMeetingTimeBlock
} from 'actions/timeblocks';

import { addError } from 'actions/index';
import { Link } from 'react-router-dom';
import Modal from 'containers/Modals/Modal';
import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Spinner, Collapsible, Person } from 'UI';
import BookMeeting from '../Form/BookMeeting';
import { TooltipMenu, Icon, Button, Flex, getActiveLanguage } from '@haldor/ui';
import CreateTimeBlocks from 'containers/TimeBlocks/CreateTimeBlocks';
import Select from 'components/Inputs/select';
import moment from 'moment';
import swal from 'sweetalert2';
import DateTime from '_class/DateTime';
import Onboarding from 'containers/Onboarding/Onboarding';
import { updateMeetingTimeBlockSlot } from 'actions/timeblocks';
import './Meeting.scss'

import { Plus, Minus } from 'UI/Icons'

class MeetingOccassions extends Component {
	constructor(props) {
		super(props);

		moment.locale(getActiveLanguage());

		let onboarding = false;
		if (this.getParameterByName('onboarding') != null) {
			onboarding = (this.getParameterByName('onboarding') == 'true');

			let firstOnboarding = localStorage.getItem('meeting-onboarding');

			if (firstOnboarding != null) {
				onboarding = false;
			} else {
				localStorage.setItem("meeting-onboarding", true);
			}
		}

		this.state = {
			loading: true,
			timeBlocksModal: false,
			lengthValue: 0,
			restLengthValue: 0,
			bookingInstance: null,
			bookingModal: false,
			onboarding: onboarding,
			steps: [{
				target: '.occassions-datePickers',
				content: this.props.translate('Here you can set the meeting duration and pause between meetings.'),
				disableBeacon: true
			}, {
				target: '#unbookedInstances',
				content: this.props.translate('Here you can see which students do not have a booked meeting time.'),
				disableBeacon: true
			}, {
				target: '.meeting-add-date-button',
				content: this.props.translate('Here you can add a new date or send a reminder to those who have not booked an appointment.'),
				disableBeacon: true
			},]
		};
	}

	/* Lifecycle methods */
	componentDidMount = () => {
		var tasks = [];
		tasks.push(this.props.getMeetingTimeBlocks(this.props.meeting.id))

		Promise.all(tasks).then(() => {
			let steps = this.state.steps;
			
			this.setState({
				steps,
				loading: false,
				lengthValue: this.props.meeting.length ? this.props.meeting.length : '',
				restLengthValue: this.props.meeting.restLength != null ? this.props.meeting.restLength : '',
			});
		})
	}

	getParameterByName = (name, url) => {
		if (!url) url = window.location.href;
		name = name.replace(/[\[\]]/g, '\\$&');
		var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';

		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	}

	disableTimeChange = (timeblock) => {
		const { meeting } = this.props;
		if (meeting == null) {
			return true;
		}

		let foundInstanceWithDate = this.props.meeting.instances.find(instance => {
			if (timeblock != null && instance.date != null) {
				let instanceDate = moment.utc(instance.date);
				return instanceDate.local().isBetween(moment.utc(timeblock.startTime).subtract(5, 'seconds').local(), moment.utc(timeblock.endTime).add(5, 'seconds').local())
			}

			return instance.date != null;
		})

		if (foundInstanceWithDate != null) {
			return true;
		}

		return false;
	}

	removeTimeBlock = async (timeblock) => {
		await swal.fire({
			title: this.props.translate('are-you-sure'),
			text: this.props.translate('are-you-sure-you-want-to-delete'),
			showCancelButton: true,
			focusConfirm: false,
			cancelButtonText: this.props.translate('No'),
			confirmButtonText: this.props.translate('Delete'),
		}).then(async (response) => {
			if (response.value != null) {
				await this.props.deleteMeetingTimeBlock(this.props.meeting.id, timeblock.id);
				await this.props.getMeetingTimeBlocks(this.props.meeting.id);		
			}
		});
	}

	sendReminderToAllUnbookInstances = () => {
		const { meeting } = this.props;

		if (meeting.instances != null) {
			let promises = [];
			meeting.instances.forEach(instance => {
				if (instance.date == null) {
					promises.push(this.sendReminder(instance, false));
				}
			});

			Promise.all(promises).then(() => {
				this.props.addError(this.props.translate('reminders-sent'), 'info');
			});
		}
	}

	sendReminder = (instance, notify = true) => {
		return new Promise((resolve) => {
			this.props.sendMeetingInstanceTimeBookingReminder(this.props.meeting.id, instance.id).then(() => {
				if (notify)
					this.props.addError(this.props.translate('reminders-sent'), 'info');

				resolve(1);
			})
		});
	}

	toggleTimeBlocksModal = async (timeblock) => {
		if (this.props.meeting.length == null || this.props.meeting.length === "") {
			swal.fire({
				title: this.props.translate('notice') + '!',
				text: this.props.translate('meeting-length-rest-validate-message'),
				showCancelButton: false,
				confirmButtonText: this.props.translate('okey')
			});

			return;
		}

		if (this.props.meeting.restLength == null || this.props.meeting.restLength === "") {
			swal.fire({
				title: this.props.translate('notice') + '!',
				text: this.props.translate('meeting-length-rest-validate-message'),
				showCancelButton: false,
				confirmButtonText: this.props.translate('okey')
			});

			return;
		}

		if (this.state.timeBlocksModal)
			await this.props.getMeetingTimeBlocks(this.props.meeting.id);

		this.setState({ timeBlocksModal: !this.state.timeBlocksModal, editTimeblock: timeblock });
	}

	getLengthDurations = () => {
		let times = [];
		let increment = 5;
		for (var i = 0; i < 24; i++) {
			if (i == 0) {
				times.push({ text: increment + ' ' + this.props.translate('minutes'), value: i + increment });
			} else {
				times.push({ text: ((i + 1) * increment) + ' ' + this.props.translate('minutes'), value: ((i + 1) * increment) });
			}
		}
		return times;
	}

	getRestLength = () => {
		let times = [];
		let increment = 5;
		for (var i = 0; i < 13; i++) {
			if (i == 0) {
				times.push({ text: i + ' ' + this.props.translate('minutes'), value: i });
			} else {
				times.push({ text: (i * increment) + ' ' + this.props.translate('minutes'), value: (i * increment) });
			}
		}
		return times;
	}

	setLengthDuration = async (event) => {
		let meeting = this.props.meeting;
		meeting.length = event.target.value;
		await this.props.updateMeeting(meeting);
		await this.props.getMeetingTimeBlocks(this.props.meeting.id);

		this.props.addError(this.props.translate('changes-saved'), 'info');
	}

	setSlotBookable = async (meeting, timeBlockSlot, bookable) => {
		timeBlockSlot.bookable = bookable;
		await this.props.updateMeetingTimeBlockSlot(meeting.id, timeBlockSlot);
	}

	setRestDuration = async (event) => {
		let meeting = this.props.meeting;
		meeting.restLength = event.target.value;
		await this.props.updateMeeting(meeting);
		await this.props.getMeetingTimeBlocks(this.props.meeting.id);

		this.props.addError(this.props.translate('changes-saved'), 'info');
	}

	toggleBooking = (instance, reload = false) => {
		const { meeting } = this.props;
		this.setState({
			bookingModal: !this.state.bookingModal,
			bookingInstance: instance
		});

		if (reload) {
			this.props.getMeeting(meeting.id).then(() => {
				this.setState({
					lengthValue: this.props.meeting.length ? this.props.meeting.length : '',
					restLengthValue: this.props.meeting.restLength != null ? this.props.meeting.restLength : '',
					loading: false
				});
			}).catch((err) => {
				console.log(err);
				this.setState({ notFound: true });
			});
		}
	}

	renderMeetingProgress = (meeting) => {
		var width = 0;
		if (meeting.totalMeetings != 0 && meeting.meetingsCompleted != 0) {
			width = (meeting.meetingsCompleted / meeting.totalMeetings) * 100;
		}

		return (
			meeting.totalMeetings != null && meeting.meetingsCompleted != null ?
				<div>
					<div style={{ display: 'flex', width: '95%' }}>
						<div style={{ flex: 1 }}>{this.props.translate('completed-meeting')}</div>
						<div style={{ flex: 1, textAlign: 'right' }}>
							{meeting.meetingsCompleted} / {meeting.totalMeetings}
						</div>
					</div>

					<div style={{ backgroundColor: '#e2e2e2', width: '95%' }}>
						<div
							style={{ backgroundColor: '#40d07c', width: `${width}%`, height: '8px' }}
						/>
					</div>
				</div>
				: null
		)
	}

	renderDatePickers = () => {
		if (this.disableTimeChange()) {
			return <div className='meeting-info'>
				<Icon name="Alert" /> {this.props.translate('You can not delete or edit time slots where there are booked meetings.')}
			</div>
		}

		return (
			<div>
				<div className='meeting-info'>
					<p><Icon name="Alert" /> {this.props.translate('meeting-occassions-text')}.</p>
					<p>{this.props.translate('You can only edit time slots as long as no meetings have been booked.')}</p>
				</div>
				<div style={{ display: 'flex', justifyContent: 'center' }} className="occassions-datePickers">

					<div style={{ flex: '1', margin: '1rem 0' }}>
						<span style={{ display: 'block', marginBottom: '.3rem' }}>
							{this.props.translate('meeting-length')}
						</span>

						<Select
							selectedValue={this.state.lengthValue.toString()}
							options={this.getLengthDurations()}
							placeholderValue={''}
							placeholder={this.props.translate('select-meeting-length')}
							onChange={(event) => { this.setLengthDuration(event) }}
						/>
					</div>

					<div style={{ flex: '1', margin: '1rem 0', marginLeft: '1rem' }}>
						<span style={{ display: 'block', marginBottom: '.3rem' }}>
							{this.props.translate('meeting-rest-length')}
						</span>

						<Select
							selectedValue={this.state.restLengthValue.toString()}
							options={this.getRestLength()}
							placeholderValue={''}
							placeholder={this.props.translate('select-meeting-rest-length')}
							onChange={(event) => { this.setRestDuration(event) }}
						/>
					</div>
				</div>
			</div>
		)
	}

	renderUnbookedInstances = () => {
		var instances = this.props.meeting.instances.filter(t => t.date == null);

		if (instances.length == 0) {
			return null;
		}

		const trigger = (open) => (
			<div className="meeting-time-blocks--content">
				<div className="meeting-time-blocks--title">
					{this.props.translate('not-booked')} ({instances.length})
				</div>

				<div className="open-indicator">
					{open ?
						<Minus />
						: <Plus />}
				</div>
			</div>
		);

		return (
			<div className="meeting--slots time-blocks" id="unbookedInstances">
				<Collapsible trigger={trigger} open={this.state.onboarding ? false : false}>
					{instances.map((instance) => {
						const dropdownTrigger = (
							<div className="dropdown--trigger">
								<Icon name="Bullets" />
							</div>
						);

						return (
							<div key={instance.id} style={{ paddingBottom: '1.9rem' }}>
								<Flex center>
									<div style={{ flex: 1 }}>
										<Link to={`/meeting/${this.props.meeting.id}/instance/${instance.id}`}>
											<Person person={instance.student} />
										</Link>
									</div>

									<div style={{ flex: 1 }} >
										<Flex center>
											<div className="text--right" style={{ flex: 1 }}>

											</div>

											<TooltipMenu trigger={dropdownTrigger}>
												<TooltipMenu.Item onClick={() => { this.sendReminder(instance) }}>
													{this.props.translate('send-reminder')}
												</TooltipMenu.Item>

												<TooltipMenu.Item onClick={() => { this.toggleBooking(instance) }}>
													{this.props.translate('book-meeting-time')}
												</TooltipMenu.Item>
											</TooltipMenu>
										</Flex>
									</div>
								</Flex>
							</div>
						)
					})}
				</Collapsible>
			</div>
		)
	}

	renderTimeSlot = (slot, instance, index) => {
		const { meeting, translate } = this.props;

		const dropdownTrigger = (
			<div className="dropdown--trigger">
				<Icon name="Bullets" />
			</div>
		);

		return (
			<div className={`slot no-checkbox ${!slot.bookable ? ' disabled' : ''}`} key={'slot-' + index}>
				{instance != null ?
					<Link to={`/meeting/${meeting.id}/instance/${instance.id}`}>
						{moment.utc(slot.startTime).local().format('HH:mm')} {instance.student.firstName + ' ' + instance.student.lastName}
					</Link>
					: slot.bookable ?
						<span>
							{moment.utc(slot.startTime).local().format('HH:mm')}
						</span>
						:
						<span>
							<p style={{ textDecorationLine: 'line-through' }}>{moment.utc(slot.startTime).local().format('HH:mm')}</p> {translate('Non-bookable')}
						</span>
				}

				<TooltipMenu trigger={dropdownTrigger}>
					{instance != null ?
						<>
							<TooltipMenu.Item onClick={() => { this.toggleBooking(instance) }}>
								{this.props.translate('Change meeting time')}
							</TooltipMenu.Item>
						</>
						: null}

					{instance == null ?
						<>
							<TooltipMenu.Item onClick={() => this.setSlotBookable(meeting, slot, !slot.bookable)}>
								{slot.bookable ? this.props.translate('Change to non-bookable') : this.props.translate('Change to bookable')}
							</TooltipMenu.Item>
						</>
						: null}
				</TooltipMenu>
			</div>
		);
	}

	renderTimeBlock = (timeblock) => {

		var instances = this.props.meeting.instances.filter(t =>
			t.date != null && moment.utc(t.date).local().isBetween(moment.utc(timeblock.startTime).subtract(5, 'seconds').local(), moment.utc(timeblock.endTime).add(5, 'seconds').local())
		);

		const dropdownTrigger = (
			<div onClick={e => e.stopPropagation()} className="dropdown--trigger">
				<Icon name="Bullets" />
			</div>
		);

		const trigger = (open) => (
			<div>
				<div style={{ display: 'flex' }}>
					<div style={{ flex: '1' }}>
						{new DateTime(timeblock.startTime).getDateString().capitalize() + ' ' + (instances.length > 0 ? `(${instances.length})` : '')}
					</div>

					<div style={{ marginRight: '.35rem' }}>
						{new DateTime(timeblock.startTime).getTime()}
						{' - '}
						{new DateTime(timeblock.endTime).getTime()}
					</div>

					<div>
						{!this.disableTimeChange(timeblock) ?
							<TooltipMenu trigger={dropdownTrigger}>
								<TooltipMenu.Item onClick={() => { this.toggleTimeBlocksModal(timeblock) }}>
									{this.props.translate('Edit')}
								</TooltipMenu.Item>

								<TooltipMenu.Item onClick={() => { this.removeTimeBlock(timeblock) }}>
									{this.props.translate('Delete')}
								</TooltipMenu.Item>
							</TooltipMenu>
							: null}
					</div>

					<div className="open-indicator">
						{open ?
							<Minus />
							: <Plus />}
					</div>
				</div>
			</div>
		);
		var slots = timeblock?.timeBlockSlots.filter(t =>
			moment(t.startTime) < moment(timeblock.endTime) &&
			moment(t.endTime) > moment(timeblock.startTime)
		);

		return (
			<div className="meeting--slots time-blocks bookedInstances" key={timeblock.id}>
				<Collapsible ignorePropsUpdate trigger={trigger} >
					{slots.length == 0 ?
						<div style={{ paddingBottom: '1.9rem' }}>
							{this.props.translate('no-meeting-occasions')}
						</div>
						: null}

					<div className="slots-container" style={{ paddingBottom: '1.9rem' }}>
						{slots.map((slot, index) => {
							let foundInstance = instances.find(instance => {
								return moment.utc(instance.date).local() < moment.utc(slot.endTime).local() &&
									moment.utc(instance.endDate).local() > moment.utc(slot.startTime).local()
							});

							return this.renderTimeSlot(slot, foundInstance, index);
						})}
					</div>
				</Collapsible>
			</div>
		)
	}

	onboardingExit = () => {
		this.setState({ onboarding: !this.state.onboarding })
	}

	render() {
		if (this.state.loading) {
			return <Spinner center />
		}
		const { meeting } = this.props;
		return (
			<div className="single-task meeting">
				{this.state.onboarding ?
					<Onboarding
						onExit={this.onboardingExit}
						steps={this.state.steps}
						onboarding={this.state.onboarding}
					/>
					: null}

				<Modal isOpen={this.state.timeBlocksModal} type="small" onClose={() => this.toggleTimeBlocksModal()} title={this.props.translate('add-date')}>
					<CreateTimeBlocks
						onClose={this.toggleTimeBlocksModal}
						reference={meeting}
						timeblock={this.state.editTimeblock}
					/>
				</Modal>

				<Modal isOpen={this.state.bookingModal} onClose={(instance, reload) => this.toggleBooking(instance, reload)} type="small" title={this.props.translate('book-meeting-time')}>
					<BookMeeting
						onClose={(instance, reload) => { this.toggleBooking(instance, reload) }}
						meeting={this.props.meeting}
						instance={this.state.bookingInstance}
					/>
				</Modal>

				<div>
					<div>
						<div style={{ display: 'flex' }}>
							<div style={{ flex: 1 }}>
								<span className="title">
									{this.props.translate('meeting-occasions')}
								</span>
							</div>

							<div className="onboarding-help-container" onClick={() => { this.setState({ onboarding: !this.state.onboarding }) }}>
								<Icon name="Help" />
							</div>
						</div>
					</div>

					{this.renderDatePickers()}

					<div className="meeting-time-blocks">
						{this.props.meetingTimeBlocks.map(this.renderTimeBlock)}

						{this.renderUnbookedInstances()}
					</div>

					<div style={{ marginTop: '2rem' }} className="meeting-add-date-button">
						<Button style={{ marginRight: '.75rem' }} onClick={() => this.toggleTimeBlocksModal()} type="secondary">
							<Icon name="Plus" /> {this.props.translate('add-date')}
						</Button>

						<Button onClick={this.sendReminderToAllUnbookInstances} type="secondary">
							<Icon name="Bell" bw /> {this.props.translate('remind-unbooked')}
						</Button>

						{this.props.next != null ?
							<div className="float--right">
								<Button onClick={this.props.next}>
									{this.props.translate('next')}
								</Button>
							</div>
							: null}
					</div>
				</div>

				<div className="clearfix" />
			</div>
		);
	}
}

function mapStateToProps(state) {
	let meeting = state.Meetings.meeting;
	if (meeting == null && state.Meetings.wizardMeeting != null) {
		meeting = state.Meetings.wizardMeeting;
	}

	return {
		translate: translate(state.Languages.translations),
		meeting: meeting,
		user: state.user.currentUser,
		meetingTimeBlocks: state.TimeBlocks.meetingTimeBlocks,
		meetingTimeBlockSlots: state.TimeBlocks.meetingTimeBlockSlots,
		languages: state.Languages.languages,
	};
}

export default connect(mapStateToProps, {
	getMeeting,
	updateMeeting,
	addError,
	getMeetingTimeBlocks,
	deleteMeetingTimeBlock,
	sendMeetingInstanceTimeBookingReminder,
	updateMeetingTimeBlockSlot
})(MeetingOccassions);
