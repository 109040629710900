import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import swal from 'sweetalert2';
import DateTime from '_class/DateTime';

import { setPageTitle } from 'actions/header';
import { addError } from 'actions/index';
import { deletePlan, getPlanDetails, setActivePlan, getPlanSection } from 'actions/plans';
import { fetch_preschoolgoals } from 'actions/preschoolform';
import { getReaderParameters } from 'actions/user';
import { getBlocksByReference, clearBlocksOnReference } from 'actions/blocks';

import { getRootUrl } from 'helpers/url';
import { isUserStudent } from 'helpers/user';
import { getImmersiveReaderDataFromBlocks } from 'helpers/content';
import { trackImmersiveReaderError } from 'helpers/insights';

import UserPlanning from 'components/List/UserPlanning';
import DateSpan from 'components/Presentation/Date/DateSpan';
import PostContent from 'components/Presentation/PostContent';
import PreschoolPlanningForm from 'containers/Forms/PlanningForm/PreschoolPlanningForm';
import Modal from 'containers/Modals/Modal';
import PlanPosts from '../Functionality/PlanPosts';
import TeacherAdjustments from "containers/AdditionalAdjustments/Display/TeacherAdjustments";
import Bookmark from 'containers/SinglePages/Partials/Bookmark';
import CloneButton from "containers/SinglePages/Partials/CloneButton";
import BlockConsumer from 'components/BlockEditor/Consumer';

import { List, Spinner, Collapsible, Expandable } from 'UI';
import { Immersive_Reader } from 'UI/Icons';
import { Button, Block, Icon } from '@haldor/ui';

const ImmersiveReader = require('@microsoft/immersive-reader-sdk');

class PreschoolPlan extends Component {
	constructor(props) {
		super(props);

		this.state = {
			modalIsOpen: false,
			loading: false,
			loadingBlocks: true,
		};

		this.closeModal.bind(this);
	}

	componentDidMount = () => {
		const { planId } = this.props.match.params;
		if (planId > 0) {
			this.reload();
		}

		if (this.props.goals.length < 1) {
			this.props.fetch_preschoolgoals();
		}
	}

	componentWillUnmount = () => {
		ImmersiveReader.close();
		this.props.clearBlocksOnReference();
	}

	reload = () => {
		this.setState({ loading: true, loadingBlocks: true });

		this.props.getPlanDetails(this.props.match.params.planId)
			.then(() => {
				this.setState({ loading: false });

				if (this.props.plan == null || this.props.plan === "") {
					return false;
				}

				this.props.setPageTitle(this.props.plan.title);
				this.props.getPlanSection(this.props.plan.sectionId);

				this.props.getBlocksByReference(this.props.match.params.planId, 'PLAN')
					.then(() => {
						this.setState({ loadingBlocks: false });
					});
			});
	}

	toggleDeletion = () => {
		swal.fire({
			title: this.props.translate('Delete'),
			text: this.props.translate('delete-plan-prompt'),
			showCancelButton: true,
			cancelButtonText: this.props.translate('Cancel'),
			confirmButtonText: this.props.translate('Delete'),
		}).then(result => {
			if (result.value != null) {
				this.props.deletePlan(this.props.match.params.planId)
					.then(() => {
						this.props.history.push(getRootUrl());
					});
			}
		});
	}

	runImmersiveReader = async () => {
		const { plan, currentUser } = this.props;
		const localLanguage = localStorage.getItem('language');

		console.log('run Microsoft Immersive Reader', localLanguage, currentUser.id);

		await this.props.getReaderParameters();
		const { reader } = this.props;

		const data = getImmersiveReaderDataFromBlocks(plan.title, this.props.blocks);

		ImmersiveReader.launchAsync(reader.token, reader.subdomain, data, {
			uiLang: localLanguage, // Set immersive reader UI language to selected lang in Haldor Edu
		}).catch((error) => {
			console.error('error', error);
			trackImmersiveReaderError(error, currentUser.id);
		});
	}

	readPlanInformation = async () => {
		const { plan, currentUser, translate } = this.props;
		const localLanguage = localStorage.getItem("language");

		await this.props.getReaderParameters();
		const { reader } = this.props;

		if (reader == null) {
			return;
		}

		const start = new DateTime(plan.timeStart).getShortDate();
		const end = new DateTime(plan.timeEnd).getShortDate();

		let status = translate('Open');
		if (plan.status == 'PLANNING_CLOSED') {
			status = translate('closed');
		}

		if (plan.status == 'PLANNING_DRAFT') {
			status = translate('Draft');
		}

		let data = {
			title: plan.title,
			chunks: [
				{
					content: `<h3>
						${translate('Plan information')}
					</h3>`,
					mimeType: "text/html",
				},
				{
					content: `
						${translate('Plan is active between')}:
						${start} - ${end} and the current status is: ${status}
						</br>
					`,
					mimeType: "text/html",
				},
				{
					content: `
						${translate('It belongs to the group named')}:
						${plan.section.title}
						</br>
					`,
					mimeType: "text/html",
				},
			],
		};

		ImmersiveReader.launchAsync(reader.token, reader.subdomain, data, {
			uiLang: localLanguage, // Set immersive reader UI language to selected lang in Haldor Edu
		}).catch((error) => {
			console.error("error", error);
			trackImmersiveReaderError(error, currentUser.id);
		});
	}

	// #region  Modal Methods
	closeModal = (reRender, skipPrompt) => {
		if (skipPrompt) {
			this.setState({ modalIsOpen: false });

			if (reRender) {
				this.reload();
			}
		} else {
			if (reRender) {
				this.props.getPlanDetails(this.props.match.params.planId);
			}

			this.setState({ modalIsOpen: false });
		}
	}

	openModal = () => {
		this.setState({ modalIsOpen: true });
	}

	// #endregion

	renderPlanStatus = () => {
		if (this.props.plan == null) {
			return null;
		}

		if (this.props.plan.locked) {
			return (<span className="single-dot dot-red">
				{this.props.translate('plan-is-processing')}
			</span>);
		}

		let status = this.props.plan.status;
		let assessed = false;
		const isStudent = isUserStudent(this.props.currentUser);

		if (this.props.assessments != null && isStudent) {
			if (this.props.assessments.length > 0) {
				assessed = true;
			}
		}

		if (assessed == false) {
			if (status === 'PLANNING_OPEN') {
				return (<span className="single-dot dot-green">{this.props.translate('Open')}</span>);
			} else if (status === 'PLANNING_CLOSED') {
				return (<span className="single-dot dot-red">{this.props.translate('closed')}</span>);
			} else if (status === 'PLANNING_DRAFT') {
				return (<span className="single-dot dot-gray">{this.props.translate('Draft')}</span>);
			}
		}

		return (
			<span className="single-dot dot-green">
				{this.props.translate('assessed')}
			</span>
		);
	}

	printGoals = () => {
		const { plan, goals } = this.props;

		if (!plan || goals.length < 1) {
			return null;
		}

		if (plan.associatedGoals && plan.associatedGoals.length > 0) {
			return (
				<div>
					{plan.associatedGoals.map(goal => {
						let foundGoal = goals.find(function (g) {
							return g.id == goal.goalId;
						});

						if (foundGoal) {
							return <div key={`goal-${foundGoal.id}`}
								style={{ backgroundColor: foundGoal.colorCode }}
								data-tooltip={foundGoal.text}
								className="goal-preview">
								{foundGoal.title}
							</div>
						}

						return null;
					})}
				</div>
			);
		}
	}


	renderModals = () => {
		return (
			<div>
				<Modal isOpen={this.state.modalIsOpen} onClose={this.closeModal} title={this.props.translate('edit-plan')}>
					<PreschoolPlanningForm
						editView={true}
						onAbort={this.closeModal.bind(this)}
					/>
				</Modal>
			</div>
		)
	}

	renderPrintButton = () => {
		let url = `/print/plan/${this.props.match.params.planId}`;
		if (getRootUrl() != '/') {
			url = `/print/planning-tab/${this.props.match.params.planId}`;
		}

		return (
			<div style={{ marginTop: 5 }}>
				<Link style={{ display: 'inline' }} to={url}>
					<Button type="secondary">
						<Icon name="Printer" />
						{this.props.translate("printable-view")}
					</Button>
				</Link>
			</div>
		);
	}

	renderFields = () => {
		const { plan, translate } = this.props;

		if (plan == null) {
			return null;
		}

		if (plan.fields != null && plan.fields.length > 0) {
			return <div>
				{plan.fields.map((field, index) => {
					// This is to be replaced with UI components later
					if (field.type == 'MULTILINETEXT') {
						return <div key={`field-${index}`} style={{ marginTop: '1rem' }}>
							<span className="title">
								{translate(field.title)}
							</span>

							<PostContent>{field.content}</PostContent>
						</div>
					}

					return null;
				})}
			</div>
		}

		return null;
	}

	planContent = () => {
		const { plan } = this.props;
		const isStudent = isUserStudent(this.props.currentUser);
		let teacherOwnsPlan = false;

		if (this.props.activeSection != null) {
			teacherOwnsPlan = this.props.activeSection.owners?.find((owner) => {
				return owner.userId == this.props.currentUser.userId;
			}) != null;
		}

		return (
			<div className="single-plan single-task" key={plan.id}>
				{isStudent || !teacherOwnsPlan ? null : this.renderModals()}

				<div className="single-actions right-side">
					<div className="action-section">
						<h3>{this.props.translate('tools')}</h3>

						{isStudent || !teacherOwnsPlan || this.state.loadingBlocks ? null :
							<div>
								<Button type="secondary" onClick={this.openModal}>
									{this.props.translate('Edit')}
								</Button>
							</div>
						}

						{isStudent || !teacherOwnsPlan || plan.isDeletable == false ?
							null :
							<div>
								<Button type="secondary" style={{ marginTop: 5 }} onClick={this.toggleDeletion}>
									{this.props.translate('Delete')}
								</Button>
							</div>
						}

						{this.renderPrintButton()}

						{!isStudent ?
							<div style={{ marginTop: 5 }}>
								<CloneButton onClose={this.reload} object={plan} type="preschoolplanning">
									{this.props.translate('column-copy')}
								</CloneButton>
							</div>
							: null}
						<div style={{ marginTop: 5 }}></div>
					</div>
				</div>

				<div className="single-section form left-side">
					<Block>
						<Expandable title={this.props.translate('Planning information')} open>
							<div>
								{this.props.services.immersiveReaderPlans ?
									<Link to="#" onClick={this.readPlanInformation}>
										<div className="reader trigger">
											<Immersive_Reader />
											{this.props.translate("immersive-reader")}
										</div>
									</Link>
									: null}

								<div style={{ float: 'right' }}>
									<Bookmark
										referenceType="PLAN"
										referenceId={plan.id}
									/>
								</div>

								<div className="clearfix" />
							</div>


							<div style={{ marginTop: "1rem" }}>
								<DateSpan
									start={plan.timeStart}
									end={plan.timeEnd}
								/>

								{this.props.plan.status == 'PLANNING_DRAFT' ?
									<div className="card-meta" style={{ marginLeft: 5 }}>
										{this.props.translate('Draft')}
									</div>
									: null}
							</div>

							<div style={{ marginTop: "1rem" }}>
								<span className="title">
									{this.props.translate("section")}
								</span>

								{plan.section.title}
							</div>

							<div style={{ marginTop: "1rem" }}>
								<span className="title">
									{this.props.translate("Status")}
								</span>

								{this.renderPlanStatus()}
								<div className="clearfix" />
							</div>

							<span className="title" style={{ marginTop: '1rem' }}>
								{this.props.translate('goals')}
							</span>

							{this.printGoals()}

							{this.renderFields()}

							{this.props.services.additionalAdjustments && !isStudent && this.props.activeSection && this.props.activeSection.owners.filter(ss => ss.id == this.props.currentUser.id).length > 0 ? (
								<div style={{ marginTop: "1rem" }}>
									<Collapsible trigger={this.props.translate("additional-adjustments")}>
										<TeacherAdjustments groupId={plan.sectionId} />
									</Collapsible>
								</div>
							) : null}
						</Expandable>
					</Block>
				</div>

				<div style={{ clear: 'both', marginTop: '2rem' }} />

				{this.props.blocks != null && !this.state.loadingBlocks ?
					<Block>
						<Expandable title={this.props.translate('Content')} open>
							{this.props.services.immersiveReaderPlans ?
								<Link to="#" onClick={this.runImmersiveReader}>
									<div className="reader trigger">
										<Immersive_Reader />
										{this.props.translate('immersive-reader')}
									</div>
								</Link>
								: null}

							<BlockConsumer
								fields={this.props.blocks}
							/>
						</Expandable>
					</Block>
					:
					<Block>
						<Expandable title={this.props.translate("Content")} open>
							<Spinner center />
						</Expandable>
					</Block>
				}

				<div style={{ marginBottom: '3em' }} className="single-section-divider" />

				{isStudent == false && teacherOwnsPlan && this.props.plan.students != null && this.props.plan.students.length > 0 ?
					<List title={this.props.translate('students')}>
						<UserPlanning
							items={this.props.plan.students}
							planId={this.props.match.params.planId}
							reload={this.reload}
							allowStatus={true}
							printable={true}
						/>
					</List>
					: null}

				<div>
					<PlanPosts
						sectionId={plan.section.id}
						planId={plan.id}
						section={this.props.activeSection}
					/>
				</div>

				<div style={{ clear: 'both' }} />
			</div>
		);
	}

	render() {
		if (this.state.loading) {
			return (
				<div>
					<Spinner center />
				</div>
			)
		}

		// Check if plan is null
		if (this.props.plan == null) {
			return <div className="single-task">
				<div className="single-section left-side">
					<Block>
						<div style={{ padding: '1rem 0' }} className="text--center color--meta weight--bold">
							{this.props.translate('plan-has-been-deleted')}
						</div>
					</Block>
				</div>

				<div className="single-actions right-side"></div>
			</div>
		}

		if (Object.keys(this.props.plan).length > 0) {
			return <div>
				{this.planContent()}
			</div>
		} else {
			return (
				<div>
					<Spinner center />
				</div>
			);
		}
	}
}

function mapStateToProps(state) {
	return {
		plan: state.planning.active_plan,
		currentUser: state.user.currentUser,
		sections: state.sections.educationGroups,
		activeSection: state.planning.section,
		assessments: state.assessments.assessments,
		translate: translate(state.Languages.translations),
		goals: state.PreschoolGoals.preschoolGoals,
		reader: state.user.reader,
		services: state.Services.availableServices,
		blocks: state.Blocks.reference,
	};
}

export default withRouter(connect(mapStateToProps, {
	getPlanDetails,
	addError,
	deletePlan,
	getPlanSection,
	fetch_preschoolgoals,
	setActivePlan,
	setPageTitle,
	getReaderParameters,
	getBlocksByReference,
	clearBlocksOnReference,
})(PreschoolPlan));