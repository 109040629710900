import React, { Component } from 'react';
import Moment from 'moment';
import { connect } from 'react-redux';

import { getSectionSubjects, clearSectionSubjects } from 'actions/subjects';
import { getUserAssessmentDates } from 'helpers/user';

import DateTime from '_class/DateTime';
import DatePickers from './Partials/DatePickers';
import UserSection from 'components/List/UserSection';
import UserSectionPreschool from 'components/List/UserSectionPreschool';

import { List, Spinner } from 'UI';
import { Button, getActiveLanguage, translate } from '@haldor/ui';
import User from '_class/User';

import './TeacherSection.scss';

class TeacherSectionProgress extends Component {

	static defaultProps = {
		schoolType: 'COMPULSORY_SCHOOL'
	};

	constructor(props) {
		super(props);

		Moment.locale(getActiveLanguage());

		this.state = {
			loading: false,
			end: getUserAssessmentDates().end,
			start: getUserAssessmentDates().start,
			currentEnd: getUserAssessmentDates().end,
			currentStart: getUserAssessmentDates().start,
		};
	}

	componentDidMount = () => {
		this.props.clearSectionSubjects();
		this.props.getSectionSubjects(this.props.group.id);
	}
	componentWillUnmount() {
		this.props.clearSectionSubjects();
	}

	onDateChange = ({ start, end }) => {
		if (start == "Invalid date") {
			start = Moment().subtract(1, "years");
		}
		if (end == "Invalid date") {
			end = Moment().add(1, "years");
		}

		localStorage.setItem('assessment-start-date', new DateTime(start).getDateStamp());
		localStorage.setItem('assessment-end-date', new DateTime(end).getDateStamp());

		this.setState({ start: new DateTime(start).getDateStamp(), end: new DateTime(end).getDateStamp() });
	}

	updateDates = async () => {
		const { start, end } = this.state;
		this.setState({ currentStart: start, currentEnd: end });
	}

	renderDateFilterText = () => {
		if (Moment().isSame(Moment(this.state.currentEnd), 'day')) {
			return null;
		}

		return (
			<div style={{ color: 'red', marginBottom: '1rem', fontWeight: 'bold' }}>
				{this.props.translate('assessments-between')} {Moment(this.state.currentStart).format('YYYY-MM-DD')} {this.props.translate('to').toLowerCase()} {Moment(this.state.currentEnd).format('YYYY-MM-DD')}
			</div>
		);
	}

	render() {
		const { group } = this.props;
		const { loading } = this.state;

		const user = new User(this.props.currentUser);
		let isOwner = this.props.group.owners.find(owner => {
			return owner.userId == this.props.currentUser.id;
		});

		if (((user.isTeacher || user.isMentor) && isOwner) || user.isAdministrator()) {
			return (
				<div>
					{this.props.schoolType != 'PRESCHOOL' ?
						<div className="single-section" style={{ marginBottom: '2em' }}>
							<div className="form">
								<div className="form-row">
									<div className="form-row-half static datepickers">
										<DatePickers
											onChange={this.onDateChange}
											startDate={this.state.start}
											endDate={this.state.end}
										/>
									</div>
								</div>
							</div>

							<div className="clearfix" />

							<div style={{ marginTop: '1em' }}>
								<Button type="secondary" onClick={this.updateDates}>
									{this.props.translate('update')}
								</Button>
							</div>
						</div>
						: null}

					{this.renderDateFilterText()}

					{loading ? <Spinner center /> : null}

					{!loading && group != null ?
						this.props.schoolType == 'PRESCHOOL' ?
							<List title={this.props.translate('students')}>
								<UserSectionPreschool
									section={group}
									start={this.state.currentStart}
									end={this.state.currentEnd}
								/>
							</List>
							:
							<UserSection
								section={group}
								start={this.state.currentStart}
								end={this.state.currentEnd}
								subjects={this.props.subjects}
							/>
						: null}
				</div>
			);
		} else {
			return <div></div>;
		}
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		group: state.sections.activeSection,
		currentUser: state.user.currentUser,
		subjects: state.Subjects.section,
	};
}

export default connect(mapStateToProps, {
	getSectionSubjects,
	clearSectionSubjects,
})(TeacherSectionProgress);
